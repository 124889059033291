import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-group-box',
  templateUrl: './group-box.component.html',
  styleUrls: ['./group-box.component.scss']
})
export class GroupBoxComponent implements OnInit {

  @Input() titulo: string;
  @Input() borda: string;
  @Input() backgroundColor: string;
  @Input() tituloX: null | "esquerda" | "direita" | "centro" = "esquerda";
  @Input() tituloY: null | "abaixo" | "acima";

  constructor() { }

  ngOnInit() {
  }

  definirPosicaoTitulo(): string []{
    let classes: string [] = [];
    if (this.tituloY){
      classes.push(this.tituloY);
    }
    if (this.tituloX){
      classes.push(this.tituloX);
    }
    return classes;
  }

  estilizarFieldSet(){
    let estilos = {};
    estilos = this.ajustarParaTituloAbaixo(estilos);
    estilos = this.definirBorda(estilos);
    estilos = this.definirBackground(estilos);
    return estilos;
  }

  ajustarParaTituloAbaixo(estilos: Object = {}):Object{
    if (this.tituloY == "abaixo"){
      estilos["transform"] = "rotate(180deg)"
      return estilos;
    }
    return estilos;
  }

  definirBorda(estilos: Object):Object{
    if (this.borda){
      estilos["border"] = this.borda;
      return estilos;
    }
    return estilos;
  }

  definirBackground(estilos: Object):Object{
    if (this.backgroundColor){
      estilos["background"] = this.backgroundColor;
      return estilos;
    }
    return estilos;
  }

}
