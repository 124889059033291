import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prvMascaraCnpj]'
})
export class MascaraCnpjDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event) {
    
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
    
    var newVal = this.el.nativeElement.value; 

    if (newVal.length <= 2) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1');
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1.$2');
    } else if (newVal.length <= 8){
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
    }
    else if (newVal.length <= 12){
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3/$4');
    }else{
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(.*)/, '$1.$2.$3/$4-$5')
    }
    this.el.nativeElement.value = newVal;
  }

}
