import { Component, OnInit } from '@angular/core';
import { FichaProdutorQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from '../../../modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-produtor',
  templateUrl: './janela-produtor.component.html',
  styleUrls: ['./janela-produtor.component.scss']
})
export class JanelaProdutorComponent implements OnInit {

  idProdutor: any;
  fichaProdutor: FichaProdutorQRY[];

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idProdutor = params.get("idProdutor");
      }
    );
    this.carregarProdutor();
  }

  carregarProdutor() {
    this.gerenciarFichasService.consultarDadosFichaProdutor(this.idProdutor).subscribe(
      (resposta) => {
        this.fichaProdutor = (resposta as RespostaServidorVO<FichaProdutorQRY[]>).sucesso;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }
}
