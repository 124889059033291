import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GerenciarProdutoresService } from '../../../modulos/comercial/servicos/gerenciar-produtores.service';
import { ActivatedRoute } from '@angular/router';
import { PlanoRemueracaoCompletoQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { JanelaFlutuanteComponent } from 'src/app/frameworks/templates-tela/janela-flutuante/janela-flutuante.component';

@Component({
  selector: 'prv-janela-plano-remuneracao',
  templateUrl: './janela-plano-remuneracao.component.html',
  styleUrls: ['./janela-plano-remuneracao.component.scss']
})
export class JanelaPlanoRemuneracaoComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteComponent;

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idPlanoRemuneracao:any;
  dataOperacao:any;
  idTipoProdutor:any;
  idTipoRemuneracao:any;
  podeDestacarLinha:boolean=false;

  configuracaoPlanoRemuneracao:PlanoRemueracaoCompletoQRY[]=[];

  constructor(
    private activedRoute:ActivatedRoute,
    private gererenciarProdutoresService:GerenciarProdutoresService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idPlanoRemuneracao = params.get("idPlanoRemuneracao");
        this.carregarPlanoRemuneracaoCompleto();
      }
    );
    this.activedRoute.queryParamMap.subscribe(
      queryParams => {
        this.podeDestacarLinha = true;
        this.dataOperacao = queryParams.get("dataOperacao");
        this.idTipoProdutor = queryParams.get("idTipoProdutor");
        this.idTipoRemuneracao = queryParams.get("idTipoRemuneracao");
      }
    );
  }

  carregarPlanoRemuneracaoCompleto() {
    console.log(this.idPlanoRemuneracao);
    this.gererenciarProdutoresService.consultarPlanoRemuneracaoCompleto(this.idPlanoRemuneracao).subscribe(
      (resposta) => {
        this.configuracaoPlanoRemuneracao = (resposta as RespostaServidorVO<PlanoRemueracaoCompletoQRY[]>).sucesso;
      },
      (falha) => {
          console.log(JSON.stringify(falha));
      }
    );
  }

  destacarLinha(configuracao:PlanoRemueracaoCompletoQRY) {
    if (this.podeDestacarLinha) {
      let configuracaoAtual = (
        (this.dataOperacao>=configuracao.dataInicioVigencia && this.dataOperacao<=configuracao.dataFimVigencia)
        && 
        (this.idTipoProdutor==configuracao.idTipoProdutor)
        && 
        (this.idTipoRemuneracao==configuracao.idTipoRemuneracao)
      );
      let estilo={};
      if (configuracaoAtual) {
        estilo = {
          "background-color":"#003399",
          "color": "white"
        };
      }
      return estilo;
    }
  }

}
