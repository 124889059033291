import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { GerenciadorTokenService } from './gerenciador-token.service';
import { AutenticacaoService } from './autenticacao.service';
import { SegurancaService } from './seguranca.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InteceptadorIncluirTokenService implements HttpInterceptor {

  constructor(private  gerenciadorToken:GerenciadorTokenService,
    private autenticacaoService: AutenticacaoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): import("rxjs").Observable<HttpEvent<any>> {
    
    if (req.url==environment.AUTH_ENDPOINT) {  
      return next.handle(req);
    }

    if (this.gerenciadorToken.possuiToken()) {
      /* if (!req.headers.has("Content-Type")){
        console.log("NÃO TEM CONTENT-TYPE");
        req = req.clone({
          setHeaders: {
            "Authorization": `Bearer ${this.gerenciadorToken.lerToken()}`,
            "Content-Type":"application/json"
          }
        });
      }else{
        console.log("TEM CONTENT-TYPE"); */
        req = req.clone({
          setHeaders: {
            "Authorization": `Bearer ${this.gerenciadorToken.lerToken()}`
          }
        });
      /* } */
      return next.handle(req);    
    }else{
      this.autenticacaoService.redirecionarParaLogin();
    }
  }

}
