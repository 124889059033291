import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ParametrosUrlService } from '../parametros-url.service';
import { PesquisaService } from '../pesquisa.service';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-salva-pesquisa',
  templateUrl: './salva-pesquisa.component.html',
  styleUrls: ['./salva-pesquisa.component.scss']
})
export class SalvaPesquisaComponent implements OnInit {

  @Input() pesquisa: any;
  @Input() opcaoSobrescrever: boolean;
  @Input() exibeDialogo: boolean = true;
  @Output() exibeDialogoChange = new EventEmitter<boolean>();

  exibeMensagemFormularioInvalido: boolean = false;

  aguardandoOperacao:boolean = false;
  mensagemErro: string;
  mensagemSucesso: string;

  salvarPesquisaForm: FormGroup;

  sobrescreverControl = new FormControl(false);

  constructor(private formBuilder: FormBuilder,
    private parametrosUrlService: ParametrosUrlService,
    private pesquisaService: PesquisaService,
    private gerenciadorErroRequisicaoService: GerenciadorErroRequisicaoService) { }

  ngOnInit() {
    if (this.pesquisa){
      this.salvarPesquisaForm = this.formBuilder.group({
        nomePesquisa: [this.pesquisa.nome, Validators.required],
        descricaoPesquisa: [this.pesquisa.descricao, Validators.required]
      })
    }else{
      this.salvarPesquisaForm = this.formBuilder.group({
        nomePesquisa: ["", Validators.required],
        descricaoPesquisa: ["", Validators.required]
      })
    }
    this.sobrescreverControl.valueChanges.subscribe({
      next: valor => {
        if (valor){
          this.desabilitarFormulario();
        }else{
          this.habilitarFormulario();
        }
      }
    })
  }

  salvarPesquisa() {
    if (!this.salvarPesquisaForm.valid){
      this.exibeMensagemFormularioInvalido=true;
    }else{
      this.exibeMensagemFormularioInvalido=false;
      this.exibeDialogoChange.emit(false);
    }
    let pesquisa = this.salvarPesquisaForm.getRawValue();
    pesquisa.parametros = this.parametrosUrlService.parametros;
    this.aguardandoOperacao=true;
    this.pesquisaService.salvarPesquisa(pesquisa).subscribe({
      next: resultado => {
        this.aguardandoOperacao=false;
        this.fecharDialogo();
      },
      error: erro => {
        console.log(erro);
        this.gerenciadorErroRequisicaoService.tratarMensagemErroRequisicao(erro, "Erro ao salvar pesquisa.");
        this.aguardandoOperacao=false;
        this.fecharDialogo();
      }
    })
  }

  fecharDialogo(){
    this.exibeDialogo=false;
    this.exibeDialogoChange.emit(false);
  }

  habilitarFormulario(){
    this.salvarPesquisaForm.enable();
  }

  desabilitarFormulario(){
    this.salvarPesquisaForm.disable();
  }

  sobrescreverAtivo(){
    console.log(this.sobrescreverControl.value);
    return this.sobrescreverControl.value;
  }

}
