import { Component, OnInit, ViewChild } from '@angular/core';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';
import { DetalheCoberturaCancelamentoQRY, RespostaServidorVO, FichaCancelamentoQRY } from 'src/app/global/tiposPrevimil';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from 'src/app/modulos/comercial/servicos/gerenciar-fichas.service';
import { DataHoraService } from 'src/app/frameworks/utilitarios/servicos/data-hora.service';

@Component({
  selector: 'prv-janela-cancelamento',
  templateUrl: './janela-cancelamento.component.html',
  styleUrls: ['./janela-cancelamento.component.scss']
})
export class JanelaCancelamentoComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idDocumento: any;
  fichaCabecalho: any;
  fichaCoberturas: DetalheCoberturaCancelamentoQRY[];
  mesCancelamento;

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService,
    private dataHoraService: DataHoraService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idDocumento = params.get("idDocumento");
      }
    );
    this.carregarCancelamento();
    this.carregarCoberturas();
  }

  carregarCancelamento() {
    this.gerenciarFichasService.consultarDadosFichaCancelamento(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaCabecalho = (resposta as RespostaServidorVO<FichaCancelamentoQRY[]>).sucesso[0];
        this.mesCancelamento = this.dataHoraService.anoMesParaMesAnoFormatado(this.fichaCabecalho.mesCancelamento);
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );

  }

  carregarCoberturas() {
    this.gerenciarFichasService.consultarCoberturasFichaCancelamento(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaCoberturas = (resposta as RespostaServidorVO<DetalheCoberturaCancelamentoQRY[]>).sucesso;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

}
