import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from './frameworks/seguranca/servicos/autenticacao.service';
import { UsuarioConectado } from './frameworks/seguranca/tipos/tipos.seguranca';
import { Observable } from 'rxjs';
import { WebSocketConnector } from './global/WebSocketConnector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'prv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'frontendweb';

  usuarioConectadoInfo$: Observable<UsuarioConectado>;
  conectando: boolean;

  constructor(private autenticacao:AutenticacaoService,
    private webSocketConnector: WebSocketConnector){
    
  }

  ngOnInit(): void {
    console.log("-----------CONSTRUTOR-----------")
    this.usuarioConectadoInfo$ = this.autenticacao.usuarioConectadoOBS.asObservable();
    this.usuarioConectadoInfo$.subscribe(usuarioConectado => {
      console.log("-----------CONEXAO-WEB-SOCKET-----------")
      if (usuarioConectado && !this.conectando){
        this.conectando = true;
        this.webSocketConnector.obterTicketEConectar(environment.API_ENDPOINT + "/websocket",
        (frame) => {
          this.conectando = false;
        })
      }
    });
  }

}
