import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { SelecaoIndividual } from '../selecao-individual';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';
import { Filtro, InicializadorFiltro, Predicado } from 'src/app/global/tipos/tiposGenericos';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prv-selecao-individual',
  templateUrl: './selecao-individual.component.html',
  styleUrls: ['./selecao-individual.component.scss']
})
export class SelecaoIndividualComponent implements OnInit, SelecaoIndividual {

  @ViewChild('condicaoInput', { static: false }) condicaoInput: ElementRef;
  @ViewChild('valorComboInput', { static: false }) valorComboInput: ElementRef;
  @ViewChild('valorRadioInput', { static: false }) valorRadioInput: ElementRef;

  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  @Input() tipoSelecao:string="radio";
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();

  valorSelecionado:any=null;
  condicaoSelecionada:any=null;
  params: any = null;

  filtroControl: FormControl = new FormControl();
  condicaoControl: FormControl = new FormControl();

  constructor(private parametrosUrl: ParametrosUrlService) { }

  ngOnInit() {
    this.params = this.parametrosUrl.getPesquisa();
    this.preencherValorInicial();
    /* if (this.tipoSelecao=="radio"){
      if(this.params){
        if (this.params.filtros){
          if(this.filtro.campo in this.params.filtros){
            this.setCondicao(this.params.filtros[this.filtro.campo].condicao);
            this.setValor(this.params.filtros[this.filtro.campo].valor);
          }
        }else{
          if(this.filtro.campo in this.params){
            this.setValor(this.params[this.filtro.campo]);
          }
        }
      }
    } */
  }

  estilizarFiltroOuParametro(){
    if (this.filtro.parametro){
      return {"grid-template-columns": "100px auto"}
    }
    return {"grid-template-columns": "200px 150px auto 40px"}
  }

  preencherValorPadrao(){
    if (this.valorInicial){
      this.filtroControl = new FormControl(this.valorInicial.valor);
      if (this.valorInicial.condicao){
        this.condicaoControl = new FormControl(this.valorInicial.condicao);
      }
    }else{
      this.condicaoControl = new FormControl(this.filtro.condicoes[0].valor);
    }
  }

  preencherValorUrl(){
    if (this.params.filtros){
      if(this.filtro.campo in this.params.filtros){
        this.filtroControl.setValue(this.params.filtros[this.filtro.campo].valor, {emitModelToViewChange:true});
        this.condicaoControl.setValue(this.params.filtros[this.filtro.campo].condicao, {emitModelToViewChange:true});
      }else{
        this.condicaoControl.setValue(this.filtro.condicoes[0].valor, {emitModelToViewChange:true});
      }
    }else{
      if(this.filtro.campo in this.params){
        this.filtroControl.setValue(this.params[this.filtro.campo], {emitModelToViewChange:true});
      }
    }
  }

  iniciarComboComValorUrl(valor){
    if (this.params && this.filtro.campo in this.params.filtros && this.filtroControl.pristine){
      if (valor == this.params.filtros[this.filtro.campo].valor){
        this.filtroControl.setValue(valor, {emitModelToViewChange:true});
        return "selected";
      }
    } else {
      if (this.valorInicial && valor == this.valorInicial.valor && this.filtroControl.pristine) {
        this.filtroControl.setValue(valor, {emitModelToViewChange:true});
        return "selected";
      }
    }
  }

  preencherValorInicial(){
    if(this.params){
      this.preencherValorUrl();
    }else{
      this.preencherValorPadrao();
    }
  }

  getValor():any {
    return this.filtroControl.value==-1?null:this.filtroControl.value;
  }

  setValor(valorSelecionado:any) {
    this.filtroControl.setValue(valorSelecionado, {emitModelToViewChange:true});
  }

  setCondicao(condicaoSelecionada:any) {
    this.condicaoControl.setValue(condicaoSelecionada, {emitModelToViewChange:true});
  }

  getCondicao(){
    return this.condicaoControl.value;
  }

  obterHash(tituloFiltro:string,tituloOpcao:string) {
    return tituloFiltro+tituloOpcao;
  }

  obterCondicaoValor(): Predicado {
    console.log(this.filtro.campo);

    let tipoDado:string = null;
    if (('tipo' in this.filtro) && (this.filtro['tipo']!=null)) {
      tipoDado = this.filtro['tipo'];
    }

    let filtro: Predicado = {
      tipoDado:tipoDado,
      condicao:this.getCondicao(),
      valor: this.getValor()
    };
    
    /*
    let filtro: HashMapParametros = {};
    filtro[this.filtro.campo] = {
      condicao:this.condicaoInput.nativeElement.value,
      valor:this.getValor()
    }
    */
    return filtro;
  }

  nomeCampo():string {
    return this.filtro.campo;
  }

  condicaoInformada():boolean {
    return (this.getValor()!=null && this.getValor()!="*");
  }

  limpar() {
    this.setValor(null);    
  }

  removerFiltro(){
    this.filtroAvancadoRemovido.emit(this.filtro);
  }

}
