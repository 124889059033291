import { Component, OnInit, ViewChild } from '@angular/core';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';
import { ActivatedRoute } from '@angular/router';
import { GerenciamentoAnuenciaEletronicaService } from 'src/app/modulos/anuencia-eletronica/servicos/gerenciamento-anuencia-eletronica.service';
import { AnuenciaDTO, AnuenciaDocumentoDTO, AnuenciaComprovanteDTO, AnuenciaDocumentoPessoaDTO } from 'src/app/global/tiposPrevimil';
import { AnuenciaService } from 'src/app/modulos/anuencia-eletronica/servicos/anuencia.service';
import { GerenciadorErroRequisicaoService } from 'src/app/frameworks/utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-janela-anuencia-e-envelope',
  templateUrl: './janela-anuencia-e-envelope.component.html',
  styleUrls: ['./janela-anuencia-e-envelope.component.scss']
})
export class JanelaAnuenciaEEnvelopeComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idAnuencia: any;
  fichaCabecalho: AnuenciaDTO;

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciamentoAnuenciaEletronicaService: GerenciamentoAnuenciaEletronicaService,
    public anuenciaService : AnuenciaService,
    private gerenciadorErroRequisicao: GerenciadorErroRequisicaoService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idAnuencia = params.get("idAnuencia");
      }
    );
    this.carregarAnuencia();
  }

  carregarAnuencia() {
    this.gerenciamentoAnuenciaEletronicaService.obterAnuencia(this.idAnuencia).subscribe(
      (resposta) => {
        this.fichaCabecalho = resposta as AnuenciaDTO;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  pegarIconePapel(idPapel: number, situacao: string): string[]{
    let classes: string[];
    switch (idPapel){
      case 10:
        classes = ["fas", "fa-user-shield", "participante"];
        break;
      case 20:
        classes = ["fas", "fa-user-md", "subscritor"];
        break;
      case 30:
        classes = ["fas", "fa-user-tie", "produtor"];
        break;
      case 40:
        classes = ["fas", "fa-user", "atendente"];
        break;
      case 50:
        classes = ["fas", "fa-user-tag", "testemunha"];
        break;
        case 60:
          classes = ["fas", "fa-user-md", "arrecadacao"];
          break;
        case 70:
          classes = ["fas", "fa-user-md", "subscritor"];
          break;
        default:
          classes = ["fas", "fa-user", "pessoa_indefinida"];
    }
    if (situacao=="F"){
      classes.push("pessoa_finalizada");
    }
    return classes;
  }

  verificarDocumentoPendente(documento: AnuenciaDocumentoDTO){
    let estilo = {color: "green"};
    documento.assinantes.forEach(assinante => {
      if (assinante.situacao=="P"){
        estilo = {color: "#333"};
        return;
      }
    });
    return estilo;
  }

  verificarComprovantePendente(comprovante: AnuenciaComprovanteDTO){
    let estilo = {color: "#333"};
    comprovante.responsaveis.forEach(responsavel => {
      if (responsavel.caminhoProvedor){
        estilo = {color: "green"};
        return;
      }
    });
    return estilo;
  }

  pegarCorPapel(idPapel: number, situacao: string): string[]{
    let classes: string[];
    switch (idPapel){
      case 10:
        classes = ["participante"];
        break;
      case 20:
        classes = ["subscritor"];
        break;
      case 30:
        classes = ["produtor"];
        break;
      case 40:
        classes = ["atendente"];
        break;
      case 50:
        classes = ["testemunha"];
        break;
        case 60:
          classes = ["arrecadacao"];
          break;
        case 70:
          classes = ["subscritor"];
          break;
        default:
          classes = ["pessoa_indefinida"];
    }
    if (situacao=="F"){
      classes.push("pessoa_finalizada");
    }
    return classes;
  }

  definirGridAssinantes(){
    let gridTemplateColumns: string = "";
    let gridTemplateAreas: string = "";
    for (let i=0; i<this.fichaCabecalho.pessoas.length; i++){
      gridTemplateColumns += "24px ";
      gridTemplateAreas += "ordem"+(i+1) + " ";
    }
    let resultado = {
      "grid-template-columns": gridTemplateColumns.trim(),
      "grid-template-areas": "'"+gridTemplateAreas+"'"
    }
    return resultado;
  }

  pegarOrdemAssinantes(ordem: number): object{
    return {
      'grid-area': 'ordem'+ordem
    }
  }

  carregarComprovante(comprovante: AnuenciaComprovanteDTO){
    let pendente = this.comprovantePendente(comprovante)
    if (!this.comprovantePendente(comprovante)){
      this.gerenciamentoAnuenciaEletronicaService.obterComprovante(comprovante.responsaveis[0].id).subscribe(
        resposta => {
          if (resposta.status == 200){
            const blob = new Blob([resposta.body], {type: resposta.headers.get("Content-Type")} );
  
            let url = window.URL.createObjectURL(blob);
  
            window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
          }
        },
        falha => {;
          console.log(falha);
        }
      );
    }
  }

  comprovantePendente(comprovante: AnuenciaComprovanteDTO){
    let pendente = true;
    comprovante.responsaveis.forEach(responsavel => {
      if (responsavel.caminhoProvedor){
        pendente = false;
      }
    });
    return pendente;
  }

  verificarVisualizacaoDocumentoHabilitada(documento: AnuenciaDocumentoDTO){
    return documento.tipoOrigem=="S" || this.verificarAssinaturasConcluidas(documento.assinantes);
  }

  verificarAssinaturasConcluidas(assinantes: AnuenciaDocumentoPessoaDTO[]): number{
    let assinaturas = 0;
    assinantes.forEach(assinante => {
      if (assinante.situacao=="F"){
        assinaturas += 1;
      }
    })
    return assinaturas;
  }

  visualizarDocumento(documento: AnuenciaDocumentoDTO, idCadastro: number){

    if (!this.verificarVisualizacaoDocumentoHabilitada(documento)){
      return;
    }

    if (documento.nomeArquivo){
      this.visualizarDocumentoGeradoAssinado(
        documento.id!=null?documento.id:idCadastro);
    }else{
      this.visualizarDocumentoOperacao(documento.idDocumentoAnuencia,
        documento.idParametro!=null?documento.idParametro:idCadastro);
    }
  }

  visualizarDocumentoOperacao(idTipoDocumento: number, idParametro: number){

    this.gerenciamentoAnuenciaEletronicaService.visualizarDocumentoOperacao(idTipoDocumento,idParametro).subscribe(
        resposta => {
          const fileUrl = URL.createObjectURL(resposta.body);
          window.open(fileUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        },
        erro => {
          this.gerenciadorErroRequisicao.tratarMensagemErroRequisicao(erro, "Ocorreu um erro na exibição do documento.")
          console.log("ERRO");
          console.log(JSON.stringify(erro));
        }
      );
  }

  visualizarDocumentoGeradoAssinado(idDocumento: number){

    this.gerenciamentoAnuenciaEletronicaService.visualizarDocumentoGeradoAssinado(idDocumento).subscribe(
        resposta => {
          const fileUrl = URL.createObjectURL(resposta.body);
          window.open(fileUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        },
        erro => {
          this.gerenciadorErroRequisicao.tratarMensagemErroRequisicao(erro, "Ocorreu um erro na exibição do documento.")
          console.log("ERRO");
          console.log(JSON.stringify(erro));
        }
      );
  }

}
