/*

Para não perder os dados de uma busca quando trocamos de abas é preciso usar o provider 
RouteReuseStrategy que permite controlar o comportamento das rotas do angular e do lifecycle dos componentes.

Toda vez que há uma troca de rota verificamos se devemos armazenar o estado do componente que estamos deixando
para uso posterior, sem construir o componente do zero.

Ver descrição abaixo:

https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f

Inserir em app.module.ts:

...
providers: [{
  provide: RouteReuseStrategy,
  useClass: CacheRouteReuseStrategy
}],
...

*/

import { RouteReuseStrategy, ActivatedRoute, Router, Params } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
    
    storedRouteHandles = new Map<string, DetachedRouteHandle>();
    allowRetriveCache = {
        'consultaInadimplencia': true,
        'remessasRetornos': true,
        'consultaCorretoresSusep': true,
        'envioCorretoresPrevimil': true,
        'correspondencia/rastreamento': true,
        'correspondencia/planilha': true
    };
    public static queryParams = {
        'consultaInadimplencia': {},
        'remessasRetornos': {},
        'consultaCorretoresSusep': {},
        'envioCorretoresPrevimil': {},
        'correspondencia/rastreamento': {},
        'correspondencia/planilha': {}
    }

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): boolean {

        this.serasaShouldReuseRoute(before, curr);
        this.susepShouldReuseRoute(before, curr);
        this.correspondenciaShouldReuseRoute(before, curr);

        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (!history.state || !history.state.retrieve){
            /* this.storedRouteHandles.clear(); */
            return false;
        }
        if (this.allowRetriveCache[path]) {
            return this.storedRouteHandles.has(this.getPath(route));
        }
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (this.allowRetriveCache.hasOwnProperty(path)) {
            return true;
        }
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        CacheRouteReuseStrategy.queryParams[this.getPath(route)] = route.queryParams;
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }
    
    private getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig !== null && route.routeConfig.path !== null) {
            return route.routeConfig.path;
        }
        return '';
    }

    serasaShouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): void {
        if (this.getPath(before) === 'remessasRetornos' && this.getPath(curr) === 'remessasRetornos') {    
            this.allowRetriveCache['consultaInadimplencia'] = true;
            this.allowRetriveCache['remessasRetornos'] = true;
        } else {
            this.allowRetriveCache['consultaInadimplencia'] = false;
        }

        if (this.getPath(before) === 'consultaInadimplencia' && this.getPath(curr) === 'consultaInadimplencia') {    
            this.allowRetriveCache['remessasRetornos'] = true;
            this.allowRetriveCache['consultaInadimplencia'] = true;
        } else {
            this.allowRetriveCache['remessasRetornos'] = false;
        }

        if (this.getPath(before) === 'remessasRetornos' && this.getPath(curr) === 'consultaInadimplencia') {    
            this.allowRetriveCache['consultaInadimplencia'] = true;
        } else {
            this.allowRetriveCache['consultaInadimplencia'] = false;
        }

        if (this.getPath(before) === 'consultaInadimplencia' && this.getPath(curr) === 'remessasRetornos') {    
            this.allowRetriveCache['remessasRetornos'] = true;
        } else {
            this.allowRetriveCache['remessasRetornos'] = false;
        }

        if ((this.getPath(before) != 'consultaInadimplencia' && this.getPath(before) != 'remessasRetornos') && this.getPath(curr) === 'remessasRetornos'){
            this.storedRouteHandles.clear();
        }

        if ((this.getPath(before) != 'consultaInadimplencia' && this.getPath(before) != 'remessasRetornos') && this.getPath(curr) === 'consultaInadimplencia'){
            this.storedRouteHandles.clear();
        }
    }

    susepShouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): void {
        if (this.getPath(before) === 'envioCorretoresPrevimil' && this.getPath(curr) === 'consultaCorretoresSusep') {    
            this.allowRetriveCache['consultaCorretoresSusep'] = true;
        } else {
            this.allowRetriveCache['consultaCorretoresSusep'] = false;
        }

        if (this.getPath(before) === 'consultaCorretoresSusep' && this.getPath(curr) === 'envioCorretoresPrevimil') {    
            this.allowRetriveCache['envioCorretoresPrevimil'] = true;
        } else {
            this.allowRetriveCache['envioCorretoresPrevimil'] = false;
        }

        if ((this.getPath(before) != 'envioCorretoresPrevimil' && this.getPath(before) != 'consultaCorretoresSusep') && this.getPath(curr) === 'consultaCorretoresSusep'){
            this.storedRouteHandles = new Map<string, DetachedRouteHandle>();
        }

        if ((this.getPath(before) != 'envioCorretoresPrevimil' && this.getPath(before) != 'consultaCorretoresSusep') && this.getPath(curr) === 'envioCorretoresPrevimil'){
            this.storedRouteHandles = new Map<string, DetachedRouteHandle>();
        }
    }

    correspondenciaShouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): void {
        if (this.getPath(before) === 'correspondencia/rastreamento' && this.getPath(curr) === 'correspondencia/rastreamento') {    
            this.allowRetriveCache['correspondencia/rastreamento'] = true;
            this.allowRetriveCache['correspondencia/planilha'] = true;
        } else {
            this.allowRetriveCache['correspondencia/planilha'] = false;
        }

        if (this.getPath(before) === 'correspondencia/planilha' && this.getPath(curr) === 'correspondencia/planilha') {    
            this.allowRetriveCache['correspondencia/rastreamento'] = true;
            this.allowRetriveCache['correspondencia/planilha'] = true;
        } else {
            this.allowRetriveCache['correspondencia/rastreamento'] = false;
        }

        if (this.getPath(before) === 'correspondencia/planilha' && this.getPath(curr) === 'correspondencia/rastreamento') {    
            this.allowRetriveCache['correspondencia/rastreamento'] = true;
        } else {
            this.allowRetriveCache['correspondencia/rastreamento'] = false;
        }

        if (this.getPath(before) === 'correspondencia/rastreamento' && this.getPath(curr) === 'correspondencia/planilha') {    
            this.allowRetriveCache['correspondencia/planilha'] = true;
        } else {
            this.allowRetriveCache['correspondencia/planilha'] = false;
        }

        if ((this.getPath(before) != 'correspondencia/rastreamento' && this.getPath(before) != 'correspondencia/planilha') && this.getPath(curr) === 'correspondencia/planilha'){
            this.storedRouteHandles.clear();
        }

        if ((this.getPath(before) != 'correspondencia/rastreamento' && this.getPath(before) != 'correspondencia/planilha') && this.getPath(curr) === 'rastreanento'){
            this.storedRouteHandles.clear();
        }
    }
}