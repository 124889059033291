import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prv-resultado-pesquisa',
  templateUrl: './resultado-pesquisa.component.html',
  styleUrls: ['./resultado-pesquisa.component.scss']
})
export class ResultadoPesquisaComponent implements OnInit {

  @Input() expansivel:boolean=false;
  @Input() barraLateralEsquerdaTamanhoPx:number = 0;
  @Input() barraLateralDireitaTamanhoPx:number = 0;
  @Input() exibirCorpo:boolean=false;
  @Input() exibirRodape:boolean=false;
  @Output() ajustarVisualizaoDetalhe = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {

  }

  exibirEscondeCorpo() {
    this.exibirCorpo=!this.exibirCorpo;
    this.ajustarVisualizaoDetalhe.emit(this.exibirCorpo);
  }

  definirTamanhoBarraLateralEsquerda(){
    let estilo={};
      estilo = {
        "flex-basis": this.barraLateralEsquerdaTamanhoPx + "px"
      };
    return estilo;
  }

  definirTamanhoBarraLateralDireita(){
    let estilo={};
      estilo = {
        "flex-basis": this.barraLateralDireitaTamanhoPx + "px"
      };
    return estilo;
  }

  definirGridCorpoResultado(){
    let estilo={};
      if (this.expansivel){
        estilo = {
          "display": "grid",
          "grid-template-columns": "35px calc(100% - 35px)"
        };
      }else{
        estilo = {
          "display": "grid",
          "grid-template-columns": "100%"
        };
      }
    return estilo;
  }

}
