import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { QuadroPadraoComponent } from './componentes/layout/quadro-padrao/quadro-padrao.component';
import { AguardandoComponent } from './componentes/outros/aguardando/aguardando.component';
import { SelecaoIndividualComponent } from './componentes/entrada/selecao-individual/selecao-individual.component';
import { SelecaoColetivaComponent } from './componentes/entrada/selecao-coletiva/selecao-coletiva.component';
import { EdicaoSimplesComponent } from './componentes/entrada/edicao-simples/edicao-simples.component';
import { EdicaoIntervaloComponent } from './componentes/entrada/edicao-intervalo/edicao-intervalo.component';
import { QuadrinhoComponent } from './componentes/layout/quadrinho/quadrinho.component';
import { QuadroAgregacaoPesquisaComponent } from './componentes/layout/quadro-agregacao-pesquisa/quadro-agregacao-pesquisa.component';
import { RotuloValorComponent } from './componentes/layout/rotulo-valor/rotulo-valor.component';
import { QuadroOperacaoIndividualComponent } from './componentes/layout/quadro-operacao-individual/quadro-operacao-individual.component';
import { GrupoCamposComponent } from './componentes/layout/grupo-campos/grupo-campos.component';
import { RespostaServidorComponent } from './componentes/outros/resposta-servidor/resposta-servidor.component';
import { QuadroParametroResultadoComponent } from './componentes/layout/quadro-parametro-resultado/quadro-parametro-resultado.component';
import { ResultadoPesquisaComponent } from './componentes/layout/resultado-pesquisa/resultado-pesquisa.component';
import { ExcluirItemComponent } from './componentes/acoes/excluir-item/excluir-item.component';
import { AutorizarItemComponent } from './componentes/acoes/autorizar-item/autorizar-item.component';
import { ComplementarItemComponent } from './componentes/acoes/complementar-item/complementar-item.component';
import { UtilitariosModule } from '../utilitarios/utilitarios.module';
import { ClipboardModule } from 'ngx-clipboard';
import { BotaoCompartilharComponent } from './componentes/outros/botao-compartilhar/botao-compartilhar.component';
import { BotaoSalvarImagemComponent } from './componentes/outros/botao-salvar-imagem/botao-salvar-imagem.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuadroGraficoTabelaComponent } from './componentes/layout/quadro-grafico-tabela/quadro-grafico-tabela.component';
import { QuadroSalvarComponent } from './componentes/layout/quadro-salvar/quadro-salvar.component';
import { LoaderComponent } from './componentes/outros/loader/loader.component';
import { GroupBoxComponent } from './componentes/layout/group-box/group-box.component';
import { QuadroResultadoComponent } from './componentes/layout/quadro-resultado/quadro-resultado.component';
import { ToastrComponent } from './componentes/layout/toastr/toastr.component';
import { ApresentacaoAbasComponent } from './componentes/layout/apresentacao-abas/apresentacao-abas.component';
import { DropdownCheckboxComponent } from './componentes/layout/dropdown-checkbox/dropdown-checkbox.component';
import { SelecaoOrdenacaoComponent } from './componentes/entrada/selecao-ordenacao/selecao-ordenacao.component';
import { MensagemErroComponent } from './componentes/layout/mensagem-erro/mensagem-erro.component';
import { BarraProgressoComponent } from './componentes/layout/barra-progresso/barra-progresso.component';
import { ApresentacaoAbasRotasComponent } from './componentes/layout/apresentacao-abas-rotas/apresentacao-abas-rotas.component';

@NgModule({
  declarations: [
    QuadroPadraoComponent,
    AguardandoComponent,
    SelecaoIndividualComponent,
    SelecaoColetivaComponent,
    EdicaoSimplesComponent,
    EdicaoIntervaloComponent,
    QuadrinhoComponent,
    QuadroAgregacaoPesquisaComponent,
    RotuloValorComponent,
    QuadroOperacaoIndividualComponent,
    GrupoCamposComponent,
    RespostaServidorComponent,
    QuadroParametroResultadoComponent,
    ResultadoPesquisaComponent,
    ExcluirItemComponent,
    AutorizarItemComponent,
    ComplementarItemComponent,
    BotaoCompartilharComponent,
    BotaoSalvarImagemComponent,
    QuadroGraficoTabelaComponent,
    QuadroSalvarComponent,
    LoaderComponent,
    GroupBoxComponent,
    QuadroResultadoComponent,
    ToastrComponent,
    ApresentacaoAbasComponent,
    DropdownCheckboxComponent,
    SelecaoOrdenacaoComponent,
    MensagemErroComponent,
    BarraProgressoComponent,
    ApresentacaoAbasRotasComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UtilitariosModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ApresentacaoAbasComponent,
    DropdownCheckboxComponent,
    QuadroPadraoComponent,
    QuadrinhoComponent,
    QuadroAgregacaoPesquisaComponent,
    QuadroGraficoTabelaComponent,
    AguardandoComponent,
    LoaderComponent,
    RotuloValorComponent,
    QuadroOperacaoIndividualComponent,
    GrupoCamposComponent,
    GroupBoxComponent,
    RespostaServidorComponent,
    BotaoCompartilharComponent,
    BotaoSalvarImagemComponent,
    QuadroSalvarComponent,
    QuadroResultadoComponent,
    ToastrComponent,
    MensagemErroComponent,
    BarraProgressoComponent,

    SelecaoIndividualComponent,
    SelecaoColetivaComponent,
    EdicaoSimplesComponent,
    EdicaoIntervaloComponent,
    SelecaoOrdenacaoComponent,
    QuadroParametroResultadoComponent,
    ResultadoPesquisaComponent,
    ExcluirItemComponent,
    AutorizarItemComponent,
    ComplementarItemComponent,
    ApresentacaoAbasRotasComponent
  ]
})
export class ElementosVisuaisGlobaisModule { }
