import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FichaProcessoQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { GerenciarFichasService } from 'src/app/modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-processo',
  templateUrl: './janela-processo.component.html',
  styleUrls: ['./janela-processo.component.scss']
})
export class JanelaProcessoComponent implements OnInit {
  public idProcesso: any;
  public processo; any;

  constructor(private gerenciarFichaService: GerenciarFichasService,
              private activatedRouter: ActivatedRoute
    ) { }

  ngOnInit() {
    this.activatedRouter.paramMap.subscribe(
      params => {
        this.idProcesso = params.get("idProcesso");

        console.log(params.get("idProcesso"));
      }
    );
    this.getDados();
  }
  getDados() {
    this.gerenciarFichaService.consultarDadosFichaProcesso(this.idProcesso).subscribe(
      (resposta) => {
        this.processo = (resposta as RespostaServidorVO<FichaProcessoQRY[]>).sucesso[0];
        console.log(this.processo);

      },
      (falha) => {
        console.log("error");

        console.log(JSON.stringify(falha));
      }
    );
  }

}
