import { Pipe, PipeTransform } from '@angular/core';
import { DataHoraService } from '../servicos/data-hora.service';

@Pipe({
  name: 'formatarData'
})
export class FormatarDataPipe implements PipeTransform {

  constructor(private dataHoraService: DataHoraService){};

  transform(anomesdia: number, args?: string): string {
    return this.dataHoraService.formatarData(anomesdia);
  }

}
