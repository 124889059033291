import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { SelecaoIndividualComponent } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/selecao-individual/selecao-individual.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RespostaServidorVO, GrupoAverbadoraAtivaQRY } from 'src/app/global/tiposPrevimil';
import { Filtro, InicializadorFiltro } from 'src/app/global/tipos/tiposGenericos';
import { entradaDadosSimples } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/entrada';

@Component({
  selector: 'prv-filtro-averbadora',
  templateUrl: './filtro-averbadora.component.html',
  styleUrls: ['./filtro-averbadora.component.scss']
})
export class FiltroAverbadoraComponent implements OnInit, entradaDadosSimples {
  
  @ViewChild('selecaoInput', { static: false }) selecaoInput: SelecaoIndividualComponent;

  @Input() tipoSelecao:string="combo";
  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  /* @Input() tituloLocal:string="Averbadora"; */
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();

  filtroAverbadora:Filtro;

  constructor(private httpClient: HttpClient) { }

  public listarAverbadoras() {
    let parametros = {
      "nomePesquisa":"PADRAO",
      "filtros": {}
    }
    let cabecalhos = new HttpHeaders({
        "Content-Type":"application/json"
    });     
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/privado/dominios/GrupoAverbadora/pesquisar",parametros
      ,{});
  }  

  ngOnInit() {
    this.filtroAverbadora = this.filtro;
    this.listarAverbadoras().subscribe(
      resposta => {
        this.filtroAverbadora.opcoes = new Array({titulo:"Todas as Averbadoras", valor:-1});
        (resposta as RespostaServidorVO<GrupoAverbadoraAtivaQRY[]>).sucesso.forEach(
          averbadoraAtiva => {
            this.filtroAverbadora.opcoes.push({titulo:averbadoraAtiva.descricao, valor:averbadoraAtiva.codigo});
          }
        );
        this.setValor(this.filtroAverbadora.opcoes[0].valor);
      },
      falha => {
        console.log(falha);
        //this.aguardandoResultadoPesquisa = false;
      }      
    );
  }

  getValor() {
    return this.selecaoInput.getValor();
  }
  setValor(valorSelecionado: any) {
    console.log(valorSelecionado);
    this.selecaoInput.setValor(valorSelecionado);
  }
  setCondicao(valor: any) {
    this.selecaoInput.setCondicao(valor);
  }
  obterHash(tituloFiltro: string, tituloOpcao: string) {
    return this.selecaoInput.obterHash(tituloFiltro, tituloOpcao);
  }
  obterCondicaoValor() {
    return this.selecaoInput.obterCondicaoValor();
  }
  nomeCampo(): string {
    return this.selecaoInput.nomeCampo();
  }
  condicaoInformada(): boolean {
    return this.selecaoInput.condicaoInformada();
  }
  limpar() {
    this.setValor(-1);
  }
  removerFiltro(event){
    this.filtroAvancadoRemovido.emit(event);
  }
}
