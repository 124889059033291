import { Component, OnInit, Renderer2 } from '@angular/core';
import { ParametrosUrlService } from '../../frameworks/pesquisa/parametros-url.service';
import { ComandosListaComponent } from '../../frameworks/utilitarios/componentes/comandos-lista/comandos-lista.component';

@Component({
  selector: 'prv-comandos-ficha',
  templateUrl: './comandos-ficha.component.html',
  styleUrls: ['./comandos-ficha.component.scss']
})
export class ComandosFichaComponent extends ComandosListaComponent implements OnInit {

  constructor(protected parametrosUrl: ParametrosUrlService,
    protected renderer: Renderer2) {
      super(parametrosUrl, renderer);
    }

  ngOnInit() {
  }

}
