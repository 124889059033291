import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-grupo-corretores-remessas',
  templateUrl: './grupo-corretores-remessas.component.html',
  styleUrls: ['./grupo-corretores-remessas.component.scss']
})
export class GrupoCorretoresRemessasComponent implements OnInit {

  @Input() corretores: any [] = [];

  constructor() { }

  ngOnInit() {
  }

}
