import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { OpcoesCombo } from 'src/app/global/tipos/tiposGenericos';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';

@Component({
  selector: 'prv-selecao-ordenacao',
  templateUrl: './selecao-ordenacao.component.html',
  styleUrls: ['./selecao-ordenacao.component.scss']
})
export class SelecaoOrdenacaoComponent implements OnInit, AfterViewInit {
  
  @ViewChild('ordenacaoInput', { static: false }) ordenacaoInput: ElementRef;
  @ViewChild('flgCrescenteInput', { static: false }) flgCrescenteInput: ElementRef;

  @Input() listaOrdenacao: OpcoesCombo[];
  @Input() valorPadraoOrdem: string;
  @Input() flgCrescente: boolean = true;

  ordenacao: string;

  constructor(private parametrosUrl: ParametrosUrlService) { }

  ngOnInit() {
    this.carregarValorInicialOrdem();
  }

  ngAfterViewInit() {
    this.carregarValorInicialOrdemInput();
  }  

  /* alterarSentido(){
    this.flgCrescente=!this.flgCrescente;
  } */

  getOrdenacao(){
    return this.ordenacao;
  }

  getFlgCrescente(){
    return this.flgCrescente;
  }

  montarOrdenacao(){
    return {
      campoOrdenacao: this.ordenacao,
      flgCrescente: this.flgCrescente
    }
  }

  carregarValorInicialOrdem(){
    let params = this.parametrosUrl.getPesquisa();
    if(params){
      this.ordenacao = params.ordenacao.campoOrdenacao;
      this.flgCrescente = params.ordenacao.flgCrescente;
    }else{
      this.ordenacao = this.valorPadraoOrdem;
    }
  }

  carregarValorInicialOrdemInput(){
    if(this.ordenacao){
      this.ordenacaoInput.nativeElement.value = this.ordenacao;
    }else{
      this.ordenacaoInput.nativeElement.value = this.valorPadraoOrdem;
    }
    this.flgCrescenteInput.nativeElement.value = this.flgCrescente;
  }

}
