import { Component, OnInit, Input } from '@angular/core';
import { OpcaoMenu, AcaoPadraoQRY } from 'src/app/global/tiposPrevimil';
import { SegurancaService } from '../../seguranca/servicos/seguranca.service';

@Component({
  selector: 'prv-opcoes-submenu',
  templateUrl: './opcoes-submenu.component.html',
  styleUrls: ['./opcoes-submenu.component.scss']
})
export class OpcoesSubmenuComponent implements OnInit {

  @Input() menus: OpcaoMenu;
  @Input() nivel: number = 1;

  exibeDialogoEnvio: boolean = false;
  parametrosJanela:any;

  constructor(private segurancaService: SegurancaService) { }

  ngOnInit() {}

  possuiSubmenu(menu: OpcaoMenu){
    return menu.submenu.length>0;
  }

  chamarTelaParametros(opcaoMenu: OpcaoMenu) {
    this.segurancaService.obterOpcaoParametro(opcaoMenu.idMenu).subscribe(
      resposta => {
        let acaoPadrao = (resposta as AcaoPadraoQRY);        
        this.parametrosJanela = JSON.parse(acaoPadrao.parametros);
        this.exibeDialogoEnvio = true;
        console.log(acaoPadrao);
      },
      falha => {
        console.log(falha);
        this.exibeDialogoEnvio = false;
      }
    );
  }

}