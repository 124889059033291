import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarCnpj'
})
export class FormatarCnpjPipe implements PipeTransform {

  transform(cnpj: string, args?: any): any {
    
    if (cnpj==null) return null;
    let cnpjEntrada = cnpj.toString();
    if (cnpjEntrada.length<14){
      cnpjEntrada = "*" + cnpjEntrada + "*";
    }
    let inicioBase = cnpjEntrada.substr(0,2);
    let meioBase = cnpjEntrada.substr(2,3);
    let fimBase = cnpjEntrada.substr(5,3);
    let filiais = cnpjEntrada.substr(8,4);
    let digito = cnpjEntrada.substr(12,2);
    let cnpjSaida = `${inicioBase}.${meioBase}.${fimBase}/${filiais}-${digito}`;
    return cnpjSaida;
  }

}
