import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoberturaQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { GerenciarFichasService } from '../../../modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-cobertura',
  templateUrl: './janela-cobertura.component.html',
  styleUrls: ['./janela-cobertura.component.scss']
})
export class JanelaCoberturaComponent implements OnInit {

  idCobertura: any;
  cobertura: CoberturaQRY

  constructor(
    private activatedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(
      params => {
        this.idCobertura = params.get("idCobertura");    
        console.log(params.get("idCobertura"));
        
      }
    );
    this.carregarCobertura();
  }

  carregarCobertura() {
    this.gerenciarFichasService.consultarDadosFichaCobertura(this.idCobertura).subscribe(
      (resposta) => {
        this.cobertura = (resposta as RespostaServidorVO<CoberturaQRY[]>).sucesso[0];
        
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );

  }


}
