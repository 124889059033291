import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarTelefone'
})
export class FormatarTelefonePipe implements PipeTransform {

  transform(rawNum: string, args?: any): any {
    let ddd = (args)?`(${args}) `:"";

    if (rawNum) {
      let divider = (rawNum.length>=9)?5:4
      const midSectionStr = rawNum.slice(0,divider);
      const lastSectionStr = rawNum.slice(divider);

      return `${ddd}${midSectionStr}-${lastSectionStr}`;    
    }
  }

}
