import { Injectable } from '@angular/core';
import { Gerenciador } from 'src/app/frameworks/templates-tela/padroes-telas';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AnuenciaExistenteQRY, AnuenciaComprovanteDTO, AnuenciaDocumentoDTO } from 'src/app/global/tiposPrevimil';
import { WebSocketConnector } from 'src/app/global/WebSocketConnector';

@Injectable({
  providedIn: 'root'
})
export class GerenciamentoAnuenciaEletronicaService implements Gerenciador{

  constructor(private http: HttpClient,
    private webSocketConnector: WebSocketConnector) { }

  inscreverSocketAnuencia(idAnuencia: number, acao: Function){
    return this.webSocketConnector.inscrever("/topic/" + idAnuencia + "/anuencia", acao);
  }

  pesquisaPadrao(filtros: any) {
    return this.consultarAnuenciasCriadas(filtros);
  }

  consultarAnuenciasCriadas(parametros: object) {
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuencias/pesquisar", parametros);
  }

  consultarAnuenciasCriadasPorAverbadora(parametros: object) {
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuencias/grupo/averbadora", parametros);
  }

  consultarAnuenciasCriadasPorTipo(parametros: object) {
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuencias/grupo/tipo", parametros);
  }

  consultarAnuenciasCriadasPorCpf(parametros: object) {
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuencias/grupo/cpf", parametros);
  }

  consultarAnuenciasCriadasPorSituacao(parametros: object) {
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuencias/grupo/situacao", parametros);
  }

  pegarModeloAnuencia(anuenciaPesquisada: AnuenciaExistenteQRY){
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/simulacao/", anuenciaPesquisada);
  }

  /* criarAnuencia(idProposta: number, idModelo: number, tipoOperacao: string, relacaoDocumentosAdicionais:string){
    let params = {
      "idModelo": idModelo.toString(),
      "relacaoDocumentosAdicionais":relacaoDocumentosAdicionais
    }
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/" + tipoOperacao + "/" + idProposta, {}, {params});
  } */

  criarAnuencia(anuenciaPesquisada: AnuenciaExistenteQRY){
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/", anuenciaPesquisada);
  }

  excluirAnuencia(idAnuencia: number){
    return this.http.delete(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/" + idAnuencia);
  }

  excluirAnuenciaPorPesquisa(anuenciaPesquisada: AnuenciaExistenteQRY){
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/retificacao", anuenciaPesquisada);
  }

  reprovarAnuencia(idAnuencia: number){
    return this.http.patch(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/situacao/" + idAnuencia, {});
  }

  obterAnuencia(idAnuencia: number){
    return this.http.get(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/" + idAnuencia);
  }

  visualizarDocumentoOperacao(idTipoDocumento:number, idParametro: number){
    return this.http.get(environment.API_ENDPOINT+"/rest/privado/documentos/"+idTipoDocumento+"/"+idParametro, {responseType: 'blob', observe:"response"})
  }

  visualizarDocumentoGeradoAssinado(idDocumento: number){
    return this.http.get(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/documentos/" + idDocumento, {responseType: 'blob', observe:"response"})
  }

  adicionarDocumento(idAnuencia: number, idDocumentoAnuencia: number){
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/" + idAnuencia + "/documentos/" + idDocumentoAnuencia, {});
  }

  removerDocumento(idAnuenciaDocumento: number){
    return this.http.delete(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/documentos/" + idAnuenciaDocumento, {});
  }

  dispararEnvelope(idAnuencia: number){
    console.log("----------------------------------------------");
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/"+idAnuencia+"/envelope",{});
  }

  sincronizarEnvelope(idAnuencia: number){
    return this.http.put(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/"+idAnuencia+"/status",{});
  }

  histoticoEnvelope(idAnuencia: number, idEnvelope: number){
    return this.http.get(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/"+idAnuencia+"/envelope/"+idEnvelope+"/histotico");
  }

  reenviarEnvelope(idAnuencia: number){
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/"+idAnuencia+"/envelope/reenviar",{});
  }

  substituirEnvelope(idAnuencia: number){
    return this.http.post(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/"+idAnuencia+"/envelope/substituir", {});
  }

  cancelarEnvelope(idAnuencia: number, descricaoCancelamento: string){
    return this.http.put(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/"+idAnuencia+"/envelope/cancelar", descricaoCancelamento);
  }

  obterComprovante(idComprovante: number){
    return this.http.get(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuenciacomprovante/" + idComprovante, {responseType: 'blob', observe:"response"});
  }

  uploadComprovante(idComprovante: number, formData: FormData){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/anuenciacomprovante/'+idComprovante, formData);
  }

  excluirComprovante(idComprovante: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/anuenciacomprovante/'+idComprovante);
  }

  listarComprovantesParaInclusao(idAnuencia: number){
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/'+idAnuencia+'/comprovantes/inclusao');
  }

  incluirComprovante(idAnuencia: number, idComprovante: number, idPapel: number, momento: string, obrigatoriedade: boolean){
    let params = {
      "idPapel": idPapel.toString(),
      "momento": momento,
      "obrigatoriedade": obrigatoriedade?"S":"N"
    }
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/'+idAnuencia+'/comprovantes/'+idComprovante, {}, {params});
  }

  removerComprovante(idComprovante: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/comprovantes/'+idComprovante, {});
  }

  listarTipoAnuencia() {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/tipo');
  }

  listarTipoAnuenciaPorId(idTipoAnuencia: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/tipo/'+idTipoAnuencia);
  }

  registrarTipoAnuencia(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/tipo',parametros);
  }

  excluirTipoAnuencia(idTipoAnuencia: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/tipo/'+idTipoAnuencia);
  }

  editarTipoAnuencia(idAnuencia: number, parametros) {
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/tipo/'+idAnuencia,parametros);
  }

  listarPapeis() {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/papel');
  }

  listarPapelPorId(idPapel: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/papel/'+idPapel);
  }

  registrarPapel(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/papel',parametros);
  }

  excluirPapel(idPapel: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/papel/'+idPapel);
  }

  editarPapel(idPapel: number, parametros) {
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/papel/'+idPapel,parametros);
  }

  listarDocumentos() {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento');
  }

  listarDocumentoPorId(idDocumento: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento/'+idDocumento);
  }

  registrarDocumento(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento',parametros);
  }

  excluirDocumento(idDocumento: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento/'+idDocumento);
  }

  editarDocumento(idDocumento: number, parametros) {
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento/'+idDocumento,parametros);
  }

  listarComprovantesAnuencia() {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/comprovante');
  }

  listarComprovanteAnuenciaPorId(idComprovante: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/comprovante/'+idComprovante);
  }

  registrarComprovanteAnuencia(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/comprovante',parametros);
  }

  excluirComprovanteAnuencia(idComprovante: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/comprovante/'+idComprovante);
  }

  editarComprovanteAnuencia(idComprovante: number, parametros) {
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/comprovante/'+idComprovante,parametros);
  }

  excluirDocumentoPapelAnuenciaVinculado(idDocumentoPapelAnuencia: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento/documentopapelanuencia/'+idDocumentoPapelAnuencia);
  }

  listarModelosAnuencia() {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/crud');
  }

  listarModeloAnuenciaPorId(idComprovante: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/crud/'+idComprovante);
  }

  registrarModeloAnuencia(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/crud',parametros);
  }

  editarModeloAnuencia(idModelo: number ,parametros){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/crud/' + idModelo,parametros);
  }

  listarModeloAnuenciaPapelPorIdModelo(idModelo: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/papel/crud/'+idModelo);
  }

  registrarModeloAnuenciaPapel(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/papel/crud',parametros);
  }

  editarModeloAnuenciaPapel(idModeloPapel: number ,parametros){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/papel/crud/' + idModeloPapel,parametros);
  }

  excluirModeloAnuenciaPapel(idModeloPapel: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/papel/crud/' + idModeloPapel);
  }

  listarModeloAnuenciaDocumentoPorId(idModelo: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/crud/'+idModelo);
  }

  listarModeloAnuenciaDocumentoPorIdModelo(idModelo: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/crud/modelo/'+idModelo);
  }

  registrarModeloAnuenciaDocumento(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/crud',parametros);
  }

  editarModeloAnuenciaDocumento(idModeloDocumento: number ,parametros){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/crud/' + idModeloDocumento,parametros);
  }

  excluirModeloAnuenciaDocumento(idModeloDocumento: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/crud/' + idModeloDocumento);
  }

  listarModeloAnuenciaDocumentoPapelPorIdModeloDocumento(idModeloDocumento: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/papel/crud/'+idModeloDocumento);
  }

  excluirModeloAnuenciaDocumentoPapel(idDocumentoPapel: number) {
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/papel/crud/'+idDocumentoPapel);
  }

  registrarModeloAnuenciaDocumentoPapel(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/papel/crud',parametros);
  }

  editarModeloAnuenciaDocumentoPapel(idModeloDocumentoPapel: number ,parametros){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/documento/papel/crud/' + idModeloDocumentoPapel,parametros);
  }

  listarModeloAnuenciaComprovantePorId(idComprovante: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/crud/'+idComprovante);
  }

  listarModeloAnuenciaComprovantePorModelo(idModelo: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/crud/modelo/'+idModelo);
  }

  listarModeloAnuenciaComprovantePorDocumento(idModeloDocumento: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/crud/documentoraiz/'+idModeloDocumento);
  }

  registrarModeloAnuenciaComprovante(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/crud',parametros);
  }

  editarModeloAnuenciaComprovante(idModeloComprovante: number ,parametros){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/crud/' + idModeloComprovante,parametros);
  }

  excluirModeloAnuenciaComprovante(idModeloComprovante: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/crud/' + idModeloComprovante);
  }

  listarModeloAnuenciaComprovantePapelPorComprovante(idModeloComprovante: number) {
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/papel/crud/'+idModeloComprovante);
  }

  registrarModeloAnuenciaComprovantePapel(parametros){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/papel/crud',parametros);
  }

  editarModeloAnuenciaComprovantePapel(idModeloComprovantePapel: number ,parametros){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/papel/crud/' + idModeloComprovantePapel,parametros);
  }

  excluirModeloAnuenciaComprovantePapel(idModeloComprovantePapel: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/modelo/comprovante/papel/crud/' + idModeloComprovantePapel);
  }

  obterDocumento(idDocumento: number){
    return this.http.get(environment.API_ENDPOINT+"/rest/privado/anuenciaeletronica/anuenciadocumento/" + idDocumento, {responseType: 'blob', observe:"response"});
  }

  uploadDocumento(idDocumento: number, formData: FormData){
    return this.http.post(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/anuenciadocumento/'+idDocumento, formData);
  }

  excluirDocumentoCarregado(idDocumento: number){
    return this.http.delete(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/anuenciadocumento/'+idDocumento);
  }
  
  enviarAnaliseDocumentos(documento: AnuenciaDocumentoDTO){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/anuenciadocumento/analise',documento);
  }

  enviarAnaliseComprovantes(comprovante: AnuenciaComprovanteDTO){
    return this.http.put(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/anuenciacomprovante/analise',comprovante);
  }

  obterDocumentosPossiveis(){
    return this.http.get(environment.API_ENDPOINT+'/rest/privado/anuenciaeletronica/documento/documentospossiveis');
  }
}
