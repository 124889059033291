import { Pipe, PipeTransform } from '@angular/core';
import { DataHoraService } from '../servicos/data-hora.service';

@Pipe({
  name: 'formatarDataHora'
})
export class FormatarDataHoraPipe implements PipeTransform {

  constructor(private dataHoraService: DataHoraService){};

  transform(dataHora: string, args?: any): any {
    return this.dataHoraService.formatarDiaMesAnoHora(dataHora);
  }

}
