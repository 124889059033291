import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuadroDialogoPadraoComponent } from './quadro-dialogo-padrao/quadro-dialogo-padrao.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuadroDialogoBaseComponent } from './quadro-dialogo-base/quadro-dialogo-base.component';
import { UtilitariosModule } from '../utilitarios/utilitarios.module';
import { QuadroDialogoComponent } from './quadro-dialogo/quadro-dialogo.component';
import { UploadArquivosComponent } from './upload-arquivos/upload-arquivos.component';

@NgModule({
  declarations: [
    QuadroDialogoPadraoComponent,
    QuadroDialogoBaseComponent,
    QuadroDialogoComponent,
    UploadArquivosComponent
  ],
  exports: [
    QuadroDialogoPadraoComponent,
    QuadroDialogoBaseComponent,
    QuadroDialogoComponent,
    UploadArquivosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    UtilitariosModule
  ]
})
export class DialogosModule { }
