import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OpcoesCombo, FiltroPesquisa, HashMapParametros } from 'src/app/global/tipos/tiposGenericos';
import { ComponentePesquisa } from '../../templates-tela/componente-pesquisa';

@Component({
  selector: 'prv-pesquisa-nicho',
  templateUrl: './pesquisa-nicho.component.html',
  styleUrls: ['./pesquisa-nicho.component.scss']
})
export class PesquisaNichoComponent implements OnInit, ComponentePesquisa {
  
  @Input() opcoes: OpcoesCombo [] = [];
  @Input() pesquisa: any;

  @Output() pesquisaChange = new EventEmitter<any>();
  @Output() pesquisar = new EventEmitter<any>();

  exibeDialogoSalvarPesquisa: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  limparFiltros() {
    this.pesquisa=null;
    this.pesquisaChange.emit(this.pesquisa);
  }
  montarPesquisa(): FiltroPesquisa | HashMapParametros {
    throw new Error("Method not implemented.");
  }

}
