import { Component, OnInit, Input } from '@angular/core';
import { TelaBasicaComponent } from '../tela-basica/tela-basica.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-tela-principal',
  templateUrl: './tela-principal.component.html',
  styleUrls: ['./tela-principal.component.scss']
})
export class TelaPrincipalComponent extends TelaBasicaComponent implements OnInit {

  @Input() exibeBotaoVoltar: boolean = true;
  @Input() exibeBotaoHome: boolean;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    protected gerenciadorErroRequisicao: GerenciadorErroRequisicaoService) {
    super(gerenciadorErroRequisicao);
  }

  ngOnInit() {
  }

  public voltarNavegacao() {
    console.log(this.activatedRoute)
    if (this.exibeBotaoHome) {
      this.router.navigate([""], { relativeTo: this.activatedRoute.parent });
    } else {
      this.router.navigate(["../.."], { relativeTo: this.activatedRoute });
    }
  }

}
