import { Injectable } from '@angular/core';
import { SegurancaService } from './seguranca.service';
import * as jtw_decode from 'jwt-decode';
import * as tiposeguranca from '../tipos/tipos.seguranca';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GerenciadorTokenService } from './gerenciador-token.service';
import { map, catchError } from 'rxjs/operators';
import { WebSocketConnector } from 'src/app/global/WebSocketConnector';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  public usuarioConectadoOBS = new BehaviorSubject<tiposeguranca.UsuarioConectado>(null);


  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private seguranca:SegurancaService,
    private gerenciadorTokenService:GerenciadorTokenService,
    private webSocketConnector: WebSocketConnector) {
      this.publicarDadosUsuario();
    }

  public publicarDadosUsuario() {
    if (this.gerenciadorTokenService.possuiToken()) {

      let esteToken = this.gerenciadorTokenService.lerToken();
      let dados = jtw_decode(esteToken);

      console.log(dados);

      let usuarioConectado={} as tiposeguranca.UsuarioConectado;

      usuarioConectado.idUsuario = dados.previmil_info.idUsuario;
      usuarioConectado.nomeUsuario = dados.previmil_info.nomeAcesso;
      usuarioConectado.email = dados.previmil_info.email;
      usuarioConectado.nomeCompleto = dados.previmil_info.nomeCompleto;
      usuarioConectado.lotacao = dados.previmil_info.lotacao;
      //usuarioConectado.issuer = dados.iss
      usuarioConectado.authorities = dados.authorities;

      this.usuarioConectadoOBS.next(usuarioConectado);
    }
  }

  public recolherDadosUsuario() {
      let usuarioConectado={} as tiposeguranca.UsuarioConectado;

      usuarioConectado.idUsuario = "";
      usuarioConectado.nomeUsuario = "";
      usuarioConectado.email = "";
      usuarioConectado.nomeCompleto = "";
      usuarioConectado.lotacao = "";
      //usuarioConectado.issuer = "";
      usuarioConectado.authorities = [""];

      this.usuarioConectadoOBS.next(usuarioConectado);
  }


  public entrarSistema(nomeUsuario:string, senha:string, returnUrl:string): Observable<any> {

    return this.seguranca.autenticar(nomeUsuario,senha).pipe(map(
      sucesso => {
        let tokenRecebido:tiposeguranca.TokenPadrao = sucesso as tiposeguranca.TokenPadrao;
        this.gerenciadorTokenService.salvarToken(tokenRecebido.access_token);
        this.publicarDadosUsuario();
        this.webSocketConnector.obterTicketEConectar(environment.API_ENDPOINT + "/websocket", (frame) => {});
        this.router.navigateByUrl(returnUrl);
      }
    ));

  }

  public redirecionarParaLogin() {
    this.gerenciadorTokenService.excluirToken();
    this.gerenciadorTokenService.excluirTodosTokens();
    this.recolherDadosUsuario();
    this.router.navigate(['/autenticacao'], {queryParams: { returnUrl: this.activatedRoute.snapshot['_routerState'].url }});
  }

  public sairSistema() {
    this.gerenciadorTokenService.excluirToken();
    this.gerenciadorTokenService.excluirTodosTokens();
    this.recolherDadosUsuario();
    this.webSocketConnector.disconnect();
    this.router.navigate(['/autenticacao']);
    /* window.location.replace('/'); */
  }

}
