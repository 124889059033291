import { Injectable } from '@angular/core';
import { SEGURANCA } from 'src/app/global/navegacao';
import { PREVIMIL_ICONE } from 'src/app/global/tipos/constantes';
import { IDescricaoModulo } from 'src/app/global/tipos/tiposGenericos';

@Injectable({
  providedIn: 'root'
})
export class DescricaoModuloSegurancaService implements IDescricaoModulo {

  constructor() { }

  getIdModulo(): string {
    return SEGURANCA.idModulo;
  }

  getNomeModulo(): string {
    return "SEGURANCA";
  }
  getIconeGrande(): string {
    return PREVIMIL_ICONE.SEGURANCA.GRANDE+" icone_cabecalho";
  }

}
