import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarCpf'
})
export class FormatarCpfPipe implements PipeTransform {

  transform(cpf: string, args?: any): any {
    
    if (cpf==null) return null;
    let cpfEntrada = cpf.toString();
    if (cpfEntrada.length<11){
      cpfEntrada = "*" + cpfEntrada + "*";
    }
    let inicio = cpfEntrada.substr(0,3);
    let meio = cpfEntrada.substr(3,3);
    let fim = cpfEntrada.substr(6,3);
    let digito = cpfEntrada.substr(9,2);
    let cpfSaida = `${inicio}.${meio}.${fim}-${digito}`;
    return cpfSaida;
  }

}
