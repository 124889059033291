import { Component, OnInit } from '@angular/core';
import { DescricaoModuloSegurancaService } from '../../servicos/descricao-modulo-seguranca.service';
import { SEGURANCA } from 'src/app/global/navegacao';
import { RespostaServidorVO, AcessoQRY } from 'src/app/global/tiposPrevimil';
import { SegurancaService } from '../../servicos/seguranca.service';

@Component({
  selector: 'prv-tela-principal-seguranca',
  templateUrl: './tela-principal-seguranca.component.html',
  styleUrls: ['./tela-principal-seguranca.component.scss']
})
export class TelaPrincipalSegurancaComponent implements OnInit {

  /* funcionalidadesUsuario = SEGURANCA.rotas */
  funcionalidadesUsuario:AcessoQRY[] = null;

  constructor(
    public descricaoModulo:DescricaoModuloSegurancaService,
    private segurancaService:SegurancaService) { }

  ngOnInit() {
    console.log("Carregar menu: WEB_PORTAL_SEGURANCA");
      this.segurancaService.obterAcessosUsuario(this.descricaoModulo.getIdModulo()).subscribe(
          resposta => {
            this.funcionalidadesUsuario = (resposta as RespostaServidorVO<AcessoQRY[]>).sucesso;
            console.log("Carregar menu: WEB_PORTAL_SEGURANCA - SUCESSO");
          },
          falha => {
            console.log(falha);
          }
      );
  }

}
