import { Injectable } from '@angular/core';
import { AnuenciaDocumentoDTO, AnuenciaComprovanteDTO, AnuenciaPessoaDTO, AnuenciaDocumentoPessoaDTO, ModeloPapelDTO, ModeloDocumentoDTO, ModeloComprovanteDTO } from 'src/app/global/tiposPrevimil';
import { GerenciamentoAnuenciaEletronicaService } from './gerenciamento-anuencia-eletronica.service';
import { GerenciadorErroRequisicaoService } from 'src/app/frameworks/utilitarios/gerenciadorErroRequisicaoService';

@Injectable({
  providedIn: 'root'
})
export class AnuenciaService {

  constructor(private gerenciamentoAnuenciaEletronica: GerenciamentoAnuenciaEletronicaService,
    private gerenciadorErroRequisicao: GerenciadorErroRequisicaoService) { }

  ordernarPessoas(pessoas: AnuenciaPessoaDTO[]): AnuenciaPessoaDTO[]{
    return pessoas.sort((a, b) => {
      return a.numeroOrdem - b.numeroOrdem;
    })
  }

  ordenarDocumentos(documentos: AnuenciaDocumentoDTO[]): AnuenciaDocumentoDTO[]{
    return documentos.sort((a, b) => {
      if (a.idDocumentoAnuencia==300){
        return 1
      }
      if (b.idDocumentoAnuencia==300){
        return -1;
      }
      return a.idDocumentoAnuencia - b.idDocumentoAnuencia;
    })
  }

  ordenarComprovantes(comprovantes: AnuenciaComprovanteDTO[]): AnuenciaComprovanteDTO[]{
    return comprovantes.sort((a, b) => {
      if (!a.responsaveis[0]){
        return a.nomeComprovanteAnuencia.localeCompare(b.nomeComprovanteAnuencia);
      }
      let auxA: number;
        let auxB: number;
        switch (a.responsaveis[0].momento){
          case "I": auxA = 1;
          break;
          case "M": auxA = 5;
          break;
          case "F": auxA = 9;
          break;
        }
        switch (b.responsaveis[0].momento){
          case "I": auxB = 1;
          break;
          case "M": auxB = 5;
          break;
          case "F": auxB = 9;
          break;
        }
      if (auxA==auxB){
        if (a.responsaveis[0].numeroOrdem == b.responsaveis[0].numeroOrdem){
          return a.nomeComprovanteAnuencia.localeCompare(b.nomeComprovanteAnuencia);
        }else{
          return a.responsaveis[0].numeroOrdem - b.responsaveis[0].numeroOrdem;
        }
      }else{
        return auxA - auxB;
      }
    })
  }

  definirStatusAnuencia(situacaoAnuencia: number){
    switch (situacaoAnuencia){
      case 1:
        return "Pendente";
      case 4:
        return "Preparando para Envio";
      case 5:
        return "Aguardando Assinaturas";
      case 7:
        return "Cancelada";
      case 8:
        return "Reprovada";
      case 9:
        return "Aprovada";
      case null:
        return "Pendente";
      default:
        return "Pendente";
    }
  }

  definirGridAssinantes(pessoas: AnuenciaPessoaDTO[]){
    let gridTemplateColumns: string = "";
    let gridTemplateAreas: string = "";
    for (let i=0; i<pessoas.length; i++){
      gridTemplateColumns += "25px ";
      gridTemplateAreas += "ordem"+(i+1) + " ";
    }
    let resultado = {
      "grid-template-columns": gridTemplateColumns.trim(),
      "grid-template-areas": "'"+gridTemplateAreas+"'"
    }
    return resultado;
  }

  pegarOrdemAssinantes(ordem: number): object{
    return {
      'grid-area': 'ordem'+ordem
    }
  }

  pegarIconePapel(idPapel: number, situacao: string, momento: string): string[]{
    let classes: string[];
    switch (idPapel){
      case 10:
        classes = ["fas", "fa-user-shield", "participante"];
        break;
      case 20:
        classes = ["fas", "fa-user-edit", "producao"];
        break;
      case 30:
        classes = ["fas", "fa-user-cog", "corretor"];
        break;
      case 40:
        classes = ["fas", "fa-user", "atendente"];
        break;
      case 50:
        classes = ["fas", "fa-user-tie", "gerente"];
        break;
      case 60:
        classes = ["fas", "fa-user-tag", "arrecadacao"];
        break;
      case 70:
        classes = ["fas", "fa-user-md", "analiserisco"];
        break;
      case 80:
        classes = ["fas", "fa-user", "preproducao"];
        break;
    }
    if (situacao=="F"){
      classes.push("pessoa_finalizada");
    }
    switch (momento){
      case "I":
        classes.push("pessoa_inicio");
        break;
      case "M":
        classes.push("pessoa_meio");
        break;
      case "F":
        classes.push("pessoa_fim");
        break;
    }
    return classes;
  }

  pegarIconeEvento(tipoMensagem: string){
    switch (tipoMensagem){
      case "S": return "fas fa-check-circle evento_sucesso"
      case "F": return "fas fa-times-circle evento_falha"
      case "A": return "fas fa-exclamation-triangle evento_alerta"
      case "I": return "fas fa-info-circle evento_informacao"
    }
  }

  definirCorStatus(situacaoAnuencia: number){
    switch (situacaoAnuencia){
      case 1: return "#b4960d";
      case 4: return "#4695ca";
      case 5: return "#4695ca";
      case 7: return "red";
      /* case 7: return "gray"; */
      case 8: return "#a30808";
      case 9: return "#1e7207";
      default: return "#b4960d";
    }
  }

  verificarComprovantePendente(comprovante: AnuenciaComprovanteDTO){
    let estilo = {color: "#333"};
    comprovante.responsaveis.forEach(responsavel => {
      if (responsavel.caminhoProvedor){
        estilo = {color: "green"};
        return;
      }
    });
    return estilo;
  }
  verificarDocumentoUploadPendente(documento: AnuenciaDocumentoDTO){
    let estilo = {color: "#333"};

      if (documento.caminhoProvedor){
        estilo = {color: "green"};
      }
    return estilo;
  }

  verificarDocumentoPendente(documento: AnuenciaDocumentoDTO){
    let estilo = {color: "green"};
    documento.assinantes.forEach(assinante => {
      if (assinante.situacao=="P"){
        estilo = {color: "#333"};
        return;
      }
    });
    return estilo;
  }

  visualizarDocumento(documento: AnuenciaDocumentoDTO, idCadastro: number){

    if (!this.verificarVisualizacaoDocumentoHabilitada(documento)){
      return;
    }

    if (documento.nomeArquivo){
      this.visualizarDocumentoGeradoAssinado(
        documento.id!=null?documento.id:idCadastro);
    }else{
      this.visualizarDocumentoOperacao(documento.idDocumentoAnuencia,
        documento.idParametro!=null?documento.idParametro:idCadastro);
    }
  }

  visualizarDocumentoOperacao(idTipoDocumento: number, idParametro: number){

    this.gerenciamentoAnuenciaEletronica.visualizarDocumentoOperacao(idTipoDocumento,idParametro).subscribe(
        resposta => {
          const fileUrl = URL.createObjectURL(resposta.body);
          window.open(fileUrl);
        },
        erro => {
          this.gerenciadorErroRequisicao.tratarMensagemErroRequisicao(erro, "Ocorreu um erro na exibição do documento.")
          console.log("ERRO");
          console.log(JSON.stringify(erro));
        }
      );
  }

  visualizarDocumentoGeradoAssinado(idDocumento: number){

    this.gerenciamentoAnuenciaEletronica.visualizarDocumentoGeradoAssinado(idDocumento).subscribe(
        resposta => {
          const fileUrl = URL.createObjectURL(resposta.body);
          window.open(fileUrl);
        },
        erro => {
          this.gerenciadorErroRequisicao.tratarMensagemErroRequisicao(erro, "Ocorreu um erro na exibição do documento.")
          console.log("ERRO");
          console.log(JSON.stringify(erro));
        }
      );
  }

  verificarAssinaturasConcluidas(assinantes: AnuenciaDocumentoPessoaDTO[]): number{
    let assinaturas = 0;
    assinantes.forEach(assinante => {
      if (assinante.situacao=="F"){
        assinaturas += 1;
      }
    })
    return assinaturas;
  }

  verificarVisualizacaoDocumentoHabilitada(documento: AnuenciaDocumentoDTO){
    return documento.tipoOrigem=="S" || this.verificarAssinaturasConcluidas(documento.assinantes);
  }

  ordernarModeloPapel(papeis: ModeloPapelDTO[]): ModeloPapelDTO[]{
    return papeis.sort((a, b) => {
      return a.numeroOrdem - b.numeroOrdem;
    })
  }

  ordenarModeloDocumento(documentos: ModeloDocumentoDTO[]): ModeloDocumentoDTO[]{
    return documentos.sort((a, b) => {
      return a.idDocumento - b.idDocumento;
    })
  }

  ordenarModeloComprovante(comprovantes: ModeloComprovanteDTO[]): ModeloComprovanteDTO[]{
    return comprovantes.sort((a, b) => {
      let auxA: number;
        let auxB: number;
        switch (a.papeis[0].momento){
          case "I": auxA = 1;
          break;
          case "M": auxA = 5;
          break;
          case "F": auxA = 9;
          break;
        }
        switch (b.papeis[0].momento){
          case "I": auxB = 1;
          break;
          case "M": auxB = 5;
          break;
          case "F": auxB = 9;
          break;
        }
      if (auxA==auxB){
        if (a.papeis[0].numeroOrdem == b.papeis[0].numeroOrdem){
          return a.nomeComprovante.localeCompare(b.nomeComprovante);
        }else{
          return a.papeis[0].numeroOrdem - b.papeis[0].numeroOrdem;
        }
      }else{
        return auxA - auxB;
      }
    })
  }
}
