import { PREVIMIL_ICONE } from './tipos/constantes';

export const PERFORMANCE = {
    idModulo: "WEB_PORTAL_PERFORMANCE"
}

export const SEGURANCA = {
    idModulo: "WEB_PORTAL_SEGURANCA"
}

export const COMERCIAL = {
    idModulo: "WEB_PORTAL_COMERCIAL",
    filhos: [
        {nome:"SUSEP", idModulo: "WEB_PORTAL_PERFORMANCE", filho: PERFORMANCE},
    ]
}

export const DIVERSOS = {
    idModulo: "WEB_PORTAL_DIVERSOS",
    filhos: [
        {nome:"DIVERSOS", idModulo: "WEB_PORTAL_DIVERSOS", filho: PERFORMANCE},
    ]
}

export const ANUENCIA_ELETRONICA = {
    idModulo: "WEB_PORTAL_ANUENCIA_ELETRONICA"
}

export const ATUARIAL = {
    idModulo: "WEB_PORTAL_ATUARIAL"
}

export const RECEBIMENTO = {
    idModulo: "WEB_PORTAL_RECEBIMENTO"
}

export const FINANCEIRO = {
    idModulo: "WEB_PORTAL_FINANCEIRO"
}

export const COMPLIANCE = {
    idModulo: "WEB_PORTAL_COMPLIANCE"
}

export const CONTABILIDADE = {
    idModulo: "WEB_PORTAL_CONTABILIDADE"
}

export const SUSEP = {
    idModulo: "WEB_PORTAL_INTEGRACAO_SUSEP"
}

export const SRO = {
    idModulo: "WEB_PORTAL_INTEGRACAO_SRO"
}

export const SERASA = {
    idModulo: "WEB_PORTAL_INTEGRACAO_SERASA"
}

export const COSSEGURO = {
    idModulo: "WEB_PORTAL_CANCELAMENTO_COSSEGURO"
}

export const PIX = {
    idModulo: "WEB_PORTAL_INTEGRACAO_PIX"
}

export const COBRANCAS = {
    idModulo: "WEB_PORTAL_INTEGRACAO_COBRANCA"
}

export const INSTITUICOES_BANCARIAS = {
    idModulo: "WEB_PORTAL_INTEGRACAO_BANCOS",
}

export const INTEGRACAO = {
    idModulo: "WEB_PORTAL_INTEGRACAO",
    filhos: [
        {nome:"SUSEP", idModulo: "WEB_PORTAL_INTEGRACAO_SUSEP", filho: SUSEP},
        {nome:"SERASA", idModulo: "WEB_PORTAL_INTEGRACAO_SERASA", filho: SERASA},
        {nome:"Instituições Bancárias", idModulo: "WEB_PORTAL_INTEGRACAO_BANCOS", filho: INSTITUICOES_BANCARIAS}
    ]
}

export const OPERACOES = {
    idModulo: "WEB_PORTAL_OPERACOES"
}

export const PRINCIPAL = {
    idModulo: "WEB_PORTAL_DASHBOARD",
    filhos: [
        {nome:"Segurança", idModulo: "WEB_PORTAL_SEGURANCA", filho: SEGURANCA},
        {nome:"Comercial", idModulo: "WEB_PORTAL_COMERCIAL", filho: COMERCIAL},
        {nome:"Financeiro", idModulo: "WEB_PORTAL_FINANCEIRO", filho: FINANCEIRO},
        {nome:"Integracao", idModulo: "WEB_PORTAL_INTEGRACAO", filho: INTEGRACAO},
        {nome:"Operações", idModulo: "WEB_PORTAL_OPERACOES", filho: OPERACOES}
    ]
}
