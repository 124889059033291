import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ValidadorService } from '../validador.service';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';
import { entradaDadosSimples } from '../entrada';
import { DataHoraService } from 'src/app/frameworks/utilitarios/servicos/data-hora.service';
import { Filtro, Validacao, InicializadorFiltro, Predicado } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-edicao-simples',
  templateUrl: './edicao-simples.component.html',
  styleUrls: ['./edicao-simples.component.scss']
})
export class EdicaoSimplesComponent implements OnInit, AfterViewInit, entradaDadosSimples {

  @ViewChild('condicaoInput', { static: false }) condicaoInput: ElementRef;
  @ViewChild('valorInput', { static: false }) valorInput: ElementRef;

  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  @Input() tipoInput:string="text";
  @Input() padraoInput:string=null;
  @Output() pesquisar = new EventEmitter<boolean>();
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();
  @Output() campoInvalido = new EventEmitter<number>();
  
  valorSelecionado:any=null;
  params: any = null;
  valorInvalido: boolean = null;
  mensagemValorInvalido: string = "Campo preenchido com valor inválido";

  constructor(private activatedRoute: ActivatedRoute,
    private validador: ValidadorService,
    private parametrosUrl: ParametrosUrlService,
    private dataHoraService: DataHoraService) { }

  ngOnInit() {
    this.params = this.parametrosUrl.getPesquisa();
    let inputCustomizado:boolean = false;
    if (this.filtro!=null) {
      if ("tipo" in this.filtro) {
        if (this.filtro["tipo"]!=null) {
          inputCustomizado = true;
          this.tipoInput = this.filtro.tipo;
        }
      }
      /* if ("padrao" in this.filtro) {
        if (this.filtro["padrao"]!=null) {
          inputCustomizado = true;
          this.padraoInput = this.filtro.padrao;
        }
      } */
    }
    /*
    if (inputCustomizado) {
      switch (this.tipoInput) {
        case "data": 
          if (this.padraoInput==null) {
            this.padraoInput="\d{2}/\d{2}/\d{4}"
          }
          break;
      }
    }
    */

  }

  ngAfterViewInit(): void {
    this.valorInput.nativeElement.type = this.tipoInput;
    if (this.padraoInput!=null) {
      this.valorInput.nativeElement.pattern = this.padraoInput;
      console.log(this.valorInput.nativeElement.pattern);
    }
    this.setParametros();
  }

  estilizarFiltroOuParametro(){
    if (this.filtro.parametro){
      return {"grid-template-columns": "100px auto"}
    }
    return {"grid-template-columns": "200px 150px auto 40px"}
  }

  preencherValorInicial(){
    if (this.valorInicial){
      this.setValor(this.valorInicial.valor);
      this.setCondicao(this.valorInicial.condicao);
    }
  }

  preencherValorUrl(){
    if (this.params.filtros){
      if(this.filtro.campo in this.params.filtros){
        this.setValor(this.params.filtros[this.filtro.campo].valor);
        this.setCondicao(this.params.filtros[this.filtro.campo].condicao);
      }
    }else{
      if(this.filtro.campo in this.params){
        this.setValor(this.params[this.filtro.campo]);
      }
    }
  }

  setParametros(){
    if(this.params){
      this.preencherValorUrl();
    }else{
      this.preencherValorInicial()
    }
  }

  inputKeyDown(event:any) {
    if (event.keyCode==13){
      this.resgataValores();
      this.pesquisar.emit(true);
    }
  }

  onInputChange(event){
    this.validador.validarCaractere(event, this.filtro);
  }

  resgataValores(){
    if (this.filtro.tipo=="date") {
      this.valorSelecionado = this.dataHoraService.removerNaoNumericos(this.valorInput.nativeElement.value);
    } else if (this.filtro.tipo=="mes") {
      this.valorSelecionado = this.dataHoraService.mesAnoInputParaAnoMesPesquisa(this.valorInput.nativeElement.value);
    } else if (this.filtro.tipo=="case-sensitive") {
      this.valorSelecionado = this.valorInput.nativeElement.value;
    } else if (this.filtro.mascara == "cpf"){
      this.valorSelecionado = this.valorInput.nativeElement.value.replace(/[^0-9]*/g, '');
    }
    else {
      this.valorSelecionado = this.valorInput.nativeElement.value.toUpperCase();
    }

    if (this.filtro.validador){
      if (this.valorInvalido == null) this.valorInvalido = false;
      let valorInvalidoAnterior = this.valorInvalido;
      let resultadoValidacao: Validacao = this.filtro.validador(this.valorSelecionado);
      this.valorInvalido = !resultadoValidacao.validacao;
      this.mensagemValorInvalido = resultadoValidacao.mensagem;
      if (this.valorInvalido != valorInvalidoAnterior){
        this.campoInvalido.emit(this.valorInvalido? 1 : -1)
      }
    }
  }

  inputBlur() {
    this.resgataValores();
  }

  obterHash(tituloFiltro:string,tituloOpcao:string) {
    return tituloFiltro+tituloOpcao;
  }

  getValor() {
    return this.valorSelecionado; 
  }

  obterCondicaoValor(): Predicado {    
    let tipoDado:string = null;
    if (('tipo' in this.filtro) && (this.filtro['tipo']!=null)) {
      tipoDado = this.filtro['tipo'];
    }

    let filtro: Predicado = {
      tipoDado:tipoDado,      
      condicao:this.condicaoInput.nativeElement.value,
      valor:this.getValor()
    };

    /*
    filtro[this.filtro.campo] = {
      condicao:this.condicaoInput.nativeElement.value,
      valor:this.valorInput.nativeElement.value
    }
    */

    return filtro;
  }

  nomeCampo():string {
    return this.filtro.campo;
  }

  isCaseSensitive(){
    if (this.filtro.tipo){
      if (this.filtro.tipo == "case-sensitive"){
        return;
      }
    }
    return {"text-transform": "uppercase"}
  }

  condicaoInformada():boolean {
    if (this.condicaoInput){
      return (this.condicaoInput.nativeElement.value=="NULO") || (this.obterCondicaoValor().valor!=null && this.obterCondicaoValor().valor!="");
    }
    return this.getValor()!=null && this.getValor()!="";
  }

  public limpar() {
    this.valorInput.nativeElement.value = null;
    this.valorSelecionado = null;
    this.valorInput.nativeElement.disabled=false;
  }

  setValor(valor:any) {
    if (this.filtro.tipo == "date"){
      this.valorInput.nativeElement.value = this.dataHoraService.dataParaInput(valor);
    }else if (this.filtro.tipo == "mes" && !valor.includes("/")){
      this.valorInput.nativeElement.value = this.dataHoraService.anoMesParaMesAnoFormatado(valor);
    }else{
      this.valorInput.nativeElement.value = valor;
    }
    this.resgataValores();
  }

  setCondicao(valor: any) {
    if (valor){
      this.condicaoInput.nativeElement.value = valor;
    }
  }

  mudouCondicao(event) {
    if (this.condicaoInput.nativeElement.value=="NULO") {
      this.limpar();
      this.valorInput.nativeElement.disabled=true;
    } else {
      this.valorInput.nativeElement.disabled=false;
    }
  }

  removerFiltro(){
    this.filtroAvancadoRemovido.emit(this.filtro);
  }

}
