import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { entradaDadosSimples } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/entrada';
import { SelecaoIndividualComponent } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/selecao-individual/selecao-individual.component';
import { Filtro, InicializadorFiltro } from 'src/app/global/tipos/tiposGenericos';
import { TipoAnuencia } from 'src/app/global/tiposPrevimil';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'prv-filtro-tipo-anuencia',
  templateUrl: './filtro-tipo-anuencia.component.html',
  styleUrls: ['./filtro-tipo-anuencia.component.scss']
})
export class FiltroTipoAnuenciaComponent implements OnInit, entradaDadosSimples {

  @ViewChild('selecaoInput', { static: false }) selecaoInput: SelecaoIndividualComponent;

  @Input() tipoSelecao:string="combo";
  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  /* @Input() tituloLocal:string="Averbadora"; */
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();

  filtroTipoAnuencia:Filtro;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.filtroTipoAnuencia = this.filtro;
    this.listarTiposAnuencia().subscribe(
      resposta => {
        this.filtroTipoAnuencia.opcoes = new Array({titulo:"Todos os Tipos", valor:-1});
        (resposta as TipoAnuencia[]).forEach(
          tipoAnuenciaAtiva => {
            this.filtroTipoAnuencia.opcoes.push({titulo:tipoAnuenciaAtiva.nomeTipoOperacao, valor:tipoAnuenciaAtiva.id});
          }
        );
        this.setValor(this.filtroTipoAnuencia.opcoes[0].valor);
      },
      falha => {
        console.log(falha);
      }      
    );
  }

  public listarTiposAnuencia() {
    /* return this.httpClient.get(environment.API_ENDPOINT + "/rest/privado/anuenciaeletronica/modelo"); */
    return this.httpClient.get(environment.API_ENDPOINT + "/rest/privado/anuenciaeletronica/tipo");
  }  

  getValor() {
    return this.selecaoInput.getValor();
  }

  setValor(valorSelecionado: any) {
    this.selecaoInput.setValor(valorSelecionado);
  }

  setCondicao(valor: any) {
    this.selecaoInput.setCondicao(valor);
  }

  obterHash(tituloFiltro: string, tituloOpcao: string) {
    return this.selecaoInput.obterHash(tituloFiltro, tituloOpcao);
  }

  obterCondicaoValor() {
    return this.selecaoInput.obterCondicaoValor();
  }

  nomeCampo(): string {
    return this.selecaoInput.nomeCampo();
  }

  condicaoInformada(): boolean {
    return this.selecaoInput.condicaoInformada();
  }

  limpar() {
    this.setValor(-1);
  }
  
  removerFiltro(event){
    this.filtroAvancadoRemovido.emit(event);
  }

}
