import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { ValidadorService } from '../validador.service';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';
import { entradaDadosIntervalo } from '../entrada';
import { Filtro, InicializadorFiltro, Predicado } from 'src/app/global/tipos/tiposGenericos';
import { DataHoraService } from 'src/app/frameworks/utilitarios/servicos/data-hora.service';

@Component({
  selector: 'prv-edicao-intervalo',
  templateUrl: './edicao-intervalo.component.html',
  styleUrls: ['./edicao-intervalo.component.scss']
})
export class EdicaoIntervaloComponent implements OnInit, entradaDadosIntervalo {

  @ViewChild('condicaoInput', { static: false }) condicaoInput: ElementRef;
  @ViewChild('valorDeInput', { static: false }) valorDeInput: ElementRef;
  @ViewChild('valorAteInput', { static: false }) valorAteInput: ElementRef;

  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  @Input() tipoInput:string="text";
  @Input() padraoInput:string=null;
  @Output() pesquisar = new EventEmitter<boolean>();
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();

  valorDeSelecionado:any=null;
  valorAteSelecionado:any=null;
  params: any = null;

  constructor(private validador: ValidadorService,
    private parametrosUrl: ParametrosUrlService,
    private dataHoraService: DataHoraService) { }

  ngOnInit() {
    this.params = this.parametrosUrl.getPesquisa();
    let inputCustomizado:boolean = false;
    if (this.filtro!=null) {
      if ("tipo" in this.filtro) {
        if (this.filtro.tipo!=null) {
          inputCustomizado = true;
          this.tipoInput = this.filtro.tipo;
        }
      }
      /* if ("padrao" in this.filtro) {
        if (this.filtro["padrao"]!=null) {
          inputCustomizado = true;
          this.padraoInput = this.filtro.padrao;
        }
      } */
    }
  }

  ngAfterViewInit(): void {
    this.valorDeInput.nativeElement.type = this.tipoInput;
    if (this.padraoInput!=null) {
      this.valorDeInput.nativeElement.pattern = this.padraoInput;
    }
    this.valorAteInput.nativeElement.type = this.tipoInput;
    if (this.padraoInput!=null) {
      this.valorAteInput.nativeElement.pattern = this.padraoInput;
    }
    this.setParametros();
  }

  estilizarFiltroOuParametro(){
    if (this.filtro.parametro){
      return {"grid-template-columns": "100px auto"}
    }
    return {"grid-template-columns": "200px 150px auto 40px"}
  }

  preencherValorInicial(){
    if (this.valorInicial){
      this.setValor(this.valorInicial.valor, this.valorInicial.valorAte);
      this.setCondicao(this.valorInicial.condicao);
    }
  }

  preencherValorUrl(){
    if (this.params.filtros){
      if(this.filtro.campo in this.params.filtros){
        this.setValor(this.params.filtros[this.filtro.campo].valorDe, this.params.filtros[this.filtro.campo].valorAte);
        this.setCondicao(this.params.filtros[this.filtro.campo].condicao);
      }
    }else{
      if(this.filtro.campo in this.params){
        this.setValor(this.params[this.filtro.campo], this.params[this.filtro.campoAte]);
      }
    }
  }

  setParametros(){
    if(this.params){
      this.preencherValorUrl();
    }else{
      this.preencherValorInicial();
    }
  }

  inputKeyDown(event:any) {
    if (event.keyCode==13){
      this.resgataValores();
      this.pesquisar.emit(true);
    }
  }

  onInputChange(event){
    this.validador.validarCaractere(event, this.filtro);
  }


  inputDeBlur() {
    if (this.filtro.tipo=="date") {
      this.valorDeSelecionado = this.dataHoraService.removerNaoNumericos(this.valorDeInput.nativeElement.value);
    } else if (this.filtro.tipo=="mes"){
      this.valorDeSelecionado = this.dataHoraService.mesAnoInputParaAnoMesPesquisa(this.valorDeInput.nativeElement.value);
    } else {
      this.valorDeSelecionado = this.valorDeInput.nativeElement.value.toUpperCase();
    }
  }

  inputAteBlur() {
    if (this.filtro.tipo=="date") {
      this.valorAteSelecionado = this.dataHoraService.removerNaoNumericos(this.valorAteInput.nativeElement.value);
    } else if (this.filtro.tipo=="mes"){
      this.valorAteSelecionado = this.dataHoraService.mesAnoInputParaAnoMesPesquisa(this.valorAteInput.nativeElement.value);
    } else {
      this.valorAteSelecionado = this.valorAteInput.nativeElement.value.toUpperCase();
    }
  }

  public limpar() {
    this.valorDeInput.nativeElement.value = null;
    this.valorDeSelecionado = null;
    this.valorDeInput.nativeElement.disabled=false;
    this.valorAteInput.nativeElement.value = null;
    this.valorAteSelecionado = null;
    this.valorAteInput.nativeElement.disabled=false;
  }

  resgataValores(){
    if (this.filtro.tipo=="date") {
      this.valorDeSelecionado = this.dataHoraService.removerNaoNumericos(this.valorDeInput.nativeElement.value);
      this.valorAteSelecionado = this.dataHoraService.removerNaoNumericos(this.valorAteInput.nativeElement.value);
    } else if (this.filtro.tipo=="mes"){
      this.valorDeSelecionado = this.dataHoraService.mesAnoInputParaAnoMesPesquisa(this.valorDeInput.nativeElement.value);
      this.valorAteSelecionado = this.dataHoraService.mesAnoInputParaAnoMesPesquisa(this.valorAteInput.nativeElement.value);
    } else {
      this.valorDeSelecionado = this.valorDeInput.nativeElement.value.toUpperCase();
      this.valorAteSelecionado = this.valorAteInput.nativeElement.value.toUpperCase();
    }
  }

  nomeCampo():string {
    return this.filtro.campo;
  }

  nomeCampoAte():string {
    return this.filtro.campoAte;
  }

  getValorDe(){
    return this.valorDeSelecionado;
  }

  getValorAte(){
    return this.valorAteSelecionado;
  }

  setValor(valorDe, valorAte){
    if (this.filtro.tipo == "date" || this.filtro.tipo == "mes"){
      if(valorDe){
        this.setValorDataDe(valorDe);    
      }
      if(valorAte){
        this.setValorDataAte(valorAte);    
      }
    }else{
      if(valorDe){
        this.setValorDe(valorDe);    
      }
      if(valorAte){
        this.setValorAte(valorAte);    
      }
    }
  }

  setValorDe(valor:any) {
    this.valorDeInput.nativeElement.value = valor;
    this.valorDeSelecionado = valor;
  }

  setValorAte(valor:any) {
    this.valorAteInput.nativeElement.value = valor;
    this.valorAteSelecionado = valor;
  }

  setValorDataDe(valor:any) {
    let data = this.formatarData(valor);
    this.valorDeInput.nativeElement.value = data;
    this.resgataValores();
  }

  setValorDataAte(valor:any) {
    let data = this.formatarData(valor);
    this.valorAteInput.nativeElement.value = data;
    this.resgataValores();
  }

  setCondicao(valor: any) {
    if (valor){
      this.condicaoInput.nativeElement.value = valor;
    }
  }

  mudouCondicao(event) {
    if (this.condicaoInput.nativeElement.value=="NULO") {
      this.limpar();
      this.valorDeInput.nativeElement.disabled=true;
      this.valorAteInput.nativeElement.disabled=true;
    } else {
      this.valorDeInput.nativeElement.disabled=false;
      this.valorAteInput.nativeElement.disabled=false;
    }
  }
  
  obterHash(tituloFiltro:string,tituloOpcao:string) {
    return tituloFiltro+tituloOpcao;
  }

  condicaoInformada():boolean {
    if (this.condicaoInput){
      return (this.condicaoInput.nativeElement.value=="NULO") || (this.obterCondicaoValor().valorDe!=null && this.obterCondicaoValor().valorDe!=""
      && this.obterCondicaoValor().valorAte!=null && this.obterCondicaoValor().valorAte!="");
    }
    return this.getValorDe()!=null && this.getValorDe()!="" && this.getValorAte()!=null && this.getValorAte()!="";
  }

  formatarData(valor: any){
    if (this.filtro.tipo == "date"){
      return this.dataHoraService.dataParaInput(valor);
    }else if (this.filtro.tipo == "mes" && !valor.includes("/")){
      return this.dataHoraService.anoMesParaMesAnoFormatado(valor);
    }
    else{
      return valor;
    }
  }

  obterCondicaoValor(): Predicado {    
    let tipoDado:string = null;
    if (('tipo' in this.filtro) && (this.filtro['tipo']!=null)) {
      tipoDado = this.filtro['tipo'];
    }

    let filtro: Predicado = {
      
      tipoDado:tipoDado,      
      condicao:this.condicaoInput.nativeElement.value,
      valorDe:this.getValorDe(),
      valorAte:this.getValorAte()
    };

    return filtro;
  }
  
  removerFiltro(){
    this.filtroAvancadoRemovido.emit(this.filtro);
  }

}
