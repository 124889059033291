import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cargaPesquisaFiltro'
})
export class CargaPesquisaFiltroPipe implements PipeTransform {

  transform(pesquisas: any[], filtro: string): any {
    if (filtro){
      return pesquisas.filter(pesquisa => 
        pesquisa.nome.toLocaleLowerCase().includes(filtro.toLocaleLowerCase())
        || pesquisa.descricao.toLocaleLowerCase().includes(filtro.toLocaleLowerCase()))
    }
    return pesquisas;
  }

}
