import { Component, OnInit, Input} from '@angular/core';
import { TelaBasicaComponent } from '../tela-basica/tela-basica.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-tela-funcionalidade',
  templateUrl: './tela-funcionalidade.component.html',
  styleUrls: ['./tela-funcionalidade.component.scss']
})
export class TelaFuncionalidadeComponent extends TelaBasicaComponent implements OnInit {

  @Input() rotasEmAbas: boolean;
  @Input() exibeBotaoHome: boolean = false;  

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    protected gerenciadorErroRequisicao: GerenciadorErroRequisicaoService) { 
    super(gerenciadorErroRequisicao);
  }

  ngOnInit() {
  }

  public voltarNavegacao() {
    if (this.exibeBotaoHome) {
      this.router.navigate(["/"]);
    }
    else if (this.rotasEmAbas){
      this.router.navigate(["."], {relativeTo: this.activatedRoute.parent.parent});
    }else{
      this.router.navigate(["."], {relativeTo: this.activatedRoute.parent});
    }
  }

}
