import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AutenticacaoService } from '../../servicos/autenticacao.service';
import { SegurancaService } from '../../servicos/seguranca.service';
import * as tiposeguranca from 'src/app/frameworks/seguranca/tipos/tipos.seguranca';
import { Observable, BehaviorSubject } from 'rxjs';
import { UsuarioNaoConectado } from '../../tipos/tipos.seguranca';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './tela.autenticacao.component.html',
  styleUrls: ['./tela.autenticacao.component.scss']
})
export class TelaAutenticacaoComponent implements OnInit {

  @ViewChild('campoSenha', { static: false }) campoSenha: ElementRef;

  erroServidor: boolean = false;
  exibeSenha: boolean = false;
  conectando: boolean = false;
  formularioAutenticacao: FormGroup;
  usuarioNaoConectadoInfo$: Observable<UsuarioNaoConectado>;
  public falhaAutenticacaoOBS = new BehaviorSubject<UsuarioNaoConectado>(null);

  constructor(
    private formBuilder: FormBuilder,
    private autenticacaoService:AutenticacaoService,
    private activatedRoute: ActivatedRoute){ 
      this.usuarioNaoConectadoInfo$ = this.falhaAutenticacaoOBS.asObservable();
      this.falhaAutenticacaoOBS.subscribe(
        data => {
          console.log("Alimentou Observable....."+JSON.stringify(data))  
        }
      )
  }

  /*
  campoSenha
  Previmil@2019#04
  */
  ngOnInit() {
    this.formularioAutenticacao = this.formBuilder.group({
      campoNomeUsuario:["",Validators.required],
      campoSenha:["",Validators.required],
    });

  }

  /*
   * ATENÇÃO: O redirecionamento para página principal é feito através do serviço de autenticação.
   */

  public entrarSistema():void {
    
    this.formularioAutenticacao.disable();
    this.erroServidor = false;
    this.conectando = true;

    const nomeUsuario = this.formularioAutenticacao.get('campoNomeUsuario').value;
    const senha = this.formularioAutenticacao.get('campoSenha').value;
    const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';

    this.autenticacaoService.entrarSistema(nomeUsuario, senha, returnUrl).subscribe(resposta => {

      this.formularioAutenticacao.enable();
      this.conectando = false;
  
    },falha => {
      
      this.formularioAutenticacao.enable();
      this.conectando = false;
      console.log(falha);

      if (falha.status == 0){
        this.erroServidor = true;
      }else{
        let usuarioNaoConectado={} as tiposeguranca.UsuarioNaoConectado;
        usuarioNaoConectado.mensagem = falha.error.error_description;
        this.falhaAutenticacaoOBS.next(usuarioNaoConectado);
      }
    }
  );
  }

  habilitarDesabilitar(){
    if (this.conectando){
      return {
        "color" : "#444"
      }
    }else{
      return {};
    }
  }

  exibirEsconderSenha(){
    this.exibeSenha = !this.exibeSenha;
    if (this.exibeSenha){
      this.campoSenha.nativeElement.type = "text";
    }else{
      this.campoSenha.nativeElement.type = "password";
    }
  }

}
