import { Component, OnInit, Input } from '@angular/core';
import { SegurancaService } from 'src/app/frameworks/seguranca/servicos/seguranca.service';
import { AcaoPadraoQRY } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-opcoes-tela-principal',
  templateUrl: './opcoes-tela-principal.component.html',
  styleUrls: ['./opcoes-tela-principal.component.scss']
})
export class OpcoesTelaPrincipalComponent implements OnInit {

  @Input() opcoes: any;
  @Input() menuModulo: any;

  exibeDialogoEnvio: boolean = false;
  parametrosJanela:any;

  constructor(private segurancaService: SegurancaService) { }

  ngOnInit() {
  }

  chamarTelaParametros(opcaoMenu) {
    console.log(JSON.stringify(this.menuModulo));
    console.log(JSON.stringify(opcaoMenu));
    this.segurancaService.obterOpcaoParametro(opcaoMenu.idMenu).subscribe(
      resposta => {
        let acaoPadrao = (resposta as AcaoPadraoQRY);
        this.parametrosJanela = JSON.parse(acaoPadrao.parametros);
        this.exibeDialogoEnvio = true;
        console.log(acaoPadrao);
      },
      falha => {
        console.log(falha);
        this.exibeDialogoEnvio = false;
      }
    );
  }
}
