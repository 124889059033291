import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_CONSULTA_PLANO_REMUNERACAO: string = environment.API_ENDPOINT + '/rest/privado/remuneracaoproducao/PlanoRemueracaoCompleto/pesquisar';

@Injectable({
  providedIn: 'root'
})
export class GerenciarProdutoresService {

  constructor(private httpClient: HttpClient) { }

  public consultarProdutor(paramentrosConsulta:Object) {
    console.log(paramentrosConsulta);
    console.log(JSON.stringify(paramentrosConsulta));    
    return this.httpClient.post(API_CONSULTA_PLANO_REMUNERACAO, paramentrosConsulta,{});
  }

  public consultarPlanoRemuneracaoCompleto(idPlanoRemuneracao:number) {
    let parametros = {
      "nomePesquisa":"PADRAO",
      "filtros": {
          "idPlanoRemuneracao":{"condicao":"IGUAL","valor":idPlanoRemuneracao}
      }
    }
    console.log(parametros);
    console.log(JSON.stringify(parametros));
    return this.httpClient.post(API_CONSULTA_PLANO_REMUNERACAO, parametros,{});
  }

}
