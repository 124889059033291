import { Component, OnInit, Input } from '@angular/core';
import { IDescricaoModulo, CriticaMensagem } from 'src/app/global/tipos/tiposGenericos';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-tela-basica',
  templateUrl: './tela-basica.component.html',
  styleUrls: ['./tela-basica.component.scss']
})
export class TelaBasicaComponent implements OnInit {
  
  @Input() descricaoModulo:IDescricaoModulo;
  @Input() descricaoFuncionalidade:string='';
  @Input() exibirBarraTitulo:boolean=true;
  @Input() exibirApenasModulo:boolean=false;
  @Input() icone:string="";

  mensagemErro500:CriticaMensagem;
  mensagemErro400:CriticaMensagem;

  nomeModuloText:string;


  constructor(protected gerenciadorErroRequisicao: GerenciadorErroRequisicaoService) { }

  ngOnInit() {
    if (this.descricaoModulo!=null) {
      this.nomeModuloText = this.descricaoModulo.getNomeModulo();
    }
    this.gerenciadorErroRequisicao.mensagemErro500.subscribe(valor => {
      this.mensagemErro500 = valor;
    })
    this.gerenciadorErroRequisicao.mensagemErro400.subscribe(valor => {
      this.mensagemErro400 = valor;
    })
  }

}
