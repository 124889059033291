import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrastavelDirective } from './diretivas/arrastavel.directive';
import { TamanhoAjustavelDirective } from './diretivas/tamanho-ajustavel.directive';
import { MascaraMesanoDirective } from './mascaras/mascara-mesano.directive';
import { MascaraCnpjDirective } from './mascaras/mascara-cnpj.directive';
import { MascaraRgDirective } from './mascaras/mascara-rg.directive';
import { MascaraCpfDirective } from './mascaras/mascara-cpf.directive';
import { FormatarCnpjPipe } from './pipes/formatar-cnpj.pipe';
import { FormatarCpfPipe } from './pipes/formatar-cpf.pipe';
import { MascaraDinamicaDirective } from './mascaras/mascara-dinamica.directive';
import { FormatarDataPipe } from './pipes/formatar-data.pipe';
import { FormatarMesAnoPipe } from './pipes/formatar-mes-ano.pipe';
import { FormatarTelefonePipe } from './pipes/formatar-telefone.pipe';
import { DragAndDropDirective } from './diretivas/drag-and-drop.directive';
import { FormatarDataHoraPipe } from './pipes/formatar-data-hora.pipe';
import { PlayerComponent } from './player/player.component';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { ControlePaginasComponent } from './componentes/controle-paginas/controle-paginas.component';
import { ComandosListaComponent } from './componentes/comandos-lista/comandos-lista.component';
import { ControlePaginasExternoComponent } from './componentes/controle-paginas-externo/controle-paginas-externo.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FormatarCpfPipe,
    FormatarCnpjPipe,
    FormatarDataPipe,
    FormatarMesAnoPipe,
    FormatarTelefonePipe,
    FormatarDataHoraPipe,
    
    MascaraDinamicaDirective,
    MascaraCpfDirective,
    MascaraRgDirective,
    MascaraCnpjDirective,
    MascaraMesanoDirective,

    ArrastavelDirective,
    TamanhoAjustavelDirective,
    DragAndDropDirective,
    
    PlayerComponent,

    ControlePaginasComponent,
    ComandosListaComponent,
    ControlePaginasExternoComponent,
  ],
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    FormatarCpfPipe,
    FormatarCnpjPipe,
    FormatarDataPipe,
    FormatarMesAnoPipe,
    FormatarTelefonePipe,
    FormatarDataHoraPipe,
    
    MascaraDinamicaDirective,
    MascaraCpfDirective,
    MascaraRgDirective,
    MascaraCnpjDirective,
    MascaraMesanoDirective,

    ArrastavelDirective,
    TamanhoAjustavelDirective,
    DragAndDropDirective,

    PlayerComponent,

    ControlePaginasComponent,
    ComandosListaComponent,
    ControlePaginasExternoComponent,
  ]
})
export class UtilitariosModule { }
