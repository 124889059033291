import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DescricaoSistemaService } from '../servicos/descricao-sistema.service';
import { GerenciadorAcessosService } from 'src/app/frameworks/seguranca/servicos/gerenciador-acessos.service';

@Injectable({
  providedIn: 'root'
})
export class GuardaAcessoDashboardGuard implements CanActivate {

  constructor( 
    public gerenciadorAcessos:GerenciadorAcessosService,
    public descricaoModulo:DescricaoSistemaService) {}

    
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      console.log("## guarda dashboard");

      return this.gerenciadorAcessos.getAcessoGuarda(this.descricaoModulo.getIdModulo(), next.pathFromRoot);
  }

}
