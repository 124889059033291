import { Component, OnInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prv-janela-flutuante-lateral',
  templateUrl: './janela-flutuante-lateral.component.html',
  styleUrls: ['./janela-flutuante-lateral.component.scss']
})
export class JanelaFlutuanteLateralComponent implements OnInit {

  @ViewChild("screen", { static: true }) screen: ElementRef;
  @ViewChild("areaScroll", { static: true }) areaScroll: ElementRef;
  @ViewChild("janela", { static: false }) janela: ElementRef;

  @Output() fecharJanela = new EventEmitter();

  constructor(private location: Location,
    private renderer: Renderer2,
    private route: ActivatedRoute) { 

  }

  ngOnInit() {
  }

  public voltarNavegacao() {
    this.renderer.addClass(this.janela.nativeElement, "slideout");
    this.renderer.addClass(this.janela.nativeElement, "janela_lateral_escondida");
    //verifica se a rota atual está configurada como rota filha no routingModule 
    setTimeout(() => {
      if (!this.route.snapshot.parent.routeConfig || (this.route.snapshot.parent.routeConfig.children &&
      this.route.snapshot.parent.routeConfig.children.map(child => child.path).includes(this.route.routeConfig.path))){
        this.location.back()
      }else{
        this.fecharJanela.emit();
      }
    }, 500);
  }

  definirTamanhoMaximoAreaScroll(){
    let areaScrollTop = this.areaScroll.nativeElement.getBoundingClientRect().top;
    let alturaCalculada = 100 - ((areaScrollTop*100)/window.innerHeight) - 5;
    let retorno = {
      "overflow-y": "scroll",
      "max-height": alturaCalculada + "vh"
    }

    return retorno;
  }
}

