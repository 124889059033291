import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DominiosRoutingModule } from './dominios-routing.module';
import { FiltroRegiaoComponent } from './regiao/filtro-regiao/filtro-regiao.component';
import { ElementosVisuaisGlobaisModule } from '../frameworks/elementos-visuais-globais/elementos-visuais-globais.module';
import { FiltroPLanoComercialComponent } from './planocomercial/filtro-plano-comercial/filtro-plano-comercial.component';
import { FiltroPlanoRemuneracaoComponent } from './planoremuneracao/filtro-plano-remuneracao/filtro-plano-remuneracao.component';
import { FiltroAverbadoraComponent } from './averbadora/filtro-averbadora/filtro-averbadora.component';
import { FiltroTipoProdutorComponent } from './tipoprodutor/filtro-tipo-produtor/filtro-tipo-produtor.component';
import { FiltroTipoContaCorrenteComponent } from './tipocontacorrente/filtro-tipo-conta-corrente/filtro-tipo-conta-corrente.component';
import { FiltroEstadoCivilComponent } from './estadocivil/filtro-estadocivil/filtro-estado-civil.component';
import { FiltroCoberturaComponent } from './cobertura/filtro-cobertura/filtro-cobertura.component';
import { FiltroSituacaoParticipanteComponent } from './situacaoparticipante/filtro-situacao-participante/filtro-situacao-participante.component';
import { FiltroTipoAnuenciaComponent } from './tipoanuencia/filtro-tipo-anuencia/filtro-tipo-anuencia.component';

@NgModule({
  declarations: [
    FiltroRegiaoComponent, 
    FiltroPLanoComercialComponent, 
    FiltroPlanoRemuneracaoComponent, 
    FiltroAverbadoraComponent, 
    FiltroTipoProdutorComponent, 
    FiltroTipoContaCorrenteComponent, 
    FiltroEstadoCivilComponent, 
    FiltroCoberturaComponent, 
    FiltroSituacaoParticipanteComponent,
    FiltroTipoAnuenciaComponent
  ],
  imports: [
    CommonModule,
    DominiosRoutingModule,

    ElementosVisuaisGlobaisModule
  ],
  exports: [
    FiltroRegiaoComponent, 
    FiltroPLanoComercialComponent, 
    FiltroPlanoRemuneracaoComponent, 
    FiltroAverbadoraComponent, 
    FiltroTipoProdutorComponent, 
    FiltroTipoContaCorrenteComponent,
    FiltroEstadoCivilComponent,
    FiltroCoberturaComponent,
    FiltroSituacaoParticipanteComponent,
    FiltroTipoAnuenciaComponent
  ]
})
export class DominiosModule { }
