import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'prv-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {

  @Input() arquivo: SafeResourceUrl;
  @Input() tipo: SafeResourceUrl;

  constructor() { }

  ngOnInit() {
  }

}
