import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prv-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent implements OnInit {

  @Input() titulo: string;
  @Output() exibeMenu = new EventEmitter<boolean>();

  fecharMenu(event){
    this.exibeMenu.emit(event);
  }

  constructor() { }

  ngOnInit() {
  }

}
