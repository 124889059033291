import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';


import { SegurancaRoutingModule } from './seguranca-routing.module';
import { TelaAutenticacaoComponent } from './telas/tela.autenticacao/tela.autenticacao.component';
import { ElementosVisuaisGlobaisModule } from '../elementos-visuais-globais/elementos-visuais-globais.module';
import { AutenticacaoService } from './servicos/autenticacao.service';
import { UtilitariosModule } from '../utilitarios/utilitarios.module';
import { RouterModule } from '@angular/router';
import { TelaPerfilUsuarioComponent } from './telas/tela-perfil-usuario/tela-perfil-usuario.component';
import { TelaMigrarUsuariosComponent } from './telas/tela-migrar-usuarios/tela-migrar-usuarios.component';
import { TelaPrincipalSegurancaComponent } from './telas/tela-principal-seguranca/tela-principal-seguranca.component';
import { MenuOpcoesModule } from '../menu-opcoes/menu-opcoes.module';
import { TemplatesTelaModule } from '../templates-tela/templates-tela.module';

@NgModule({
  declarations: [
    TelaAutenticacaoComponent, 
    TelaPerfilUsuarioComponent, 
    TelaMigrarUsuariosComponent, 
    TelaPrincipalSegurancaComponent
  ],
  imports: [
    CommonModule,
    SegurancaRoutingModule, 
    ReactiveFormsModule, 
    HttpClientModule,
    RouterModule,
       
    UtilitariosModule,
    MenuOpcoesModule,
    ElementosVisuaisGlobaisModule,
    TemplatesTelaModule
  ],
  exports: [TelaAutenticacaoComponent],
  providers: [AutenticacaoService]
})
export class SegurancaModule { }
