import { ANUENCIA_ELETRONICA } from '../navegacao';

export const PORTAS_MICROSSERVICO = {
    SISTEMA: 7000
}

export const PREVIMIL_ICONE = {
    SISTEMA: {
        GRANDE:"fa fa-bars"
    },        
    APROVACAO: {
        GRANDE:"icone_menu icone_aprovacao"
    },        
    PRODUCAO: {
        GRANDE:"fa fa-bullhorn"
    },
    TELEMARKETING: {
        GRANDE:"fa fa-volume-control-phone"
    },
    COMERCIAL:  {
        GRANDE:"icone_menu icone_comercial_principal"
    },
    GESTAO_INTENA:  {
        GRANDE:"fa fa-bullseye"
    },
    FINANCEIRO:  {
        GRANDE:"icone_menu icone_financeiro"
    },
    CONFIGURACOES:  {
        GRANDE:"fa fa-gears"
    },
    SEGURANCA:  {
        GRANDE:"icone_menu icone_seguranca"
    },
    PERFORMANCE: {
        GRANDE:"icone_menu icone_comercial_performance"
    },
    INTEGRACAO:  {
        GRANDE:"icone_menu icone_integracao"
    },
    SUSEP: {
        GRANDE:"icone_menu icone_integracao_SUSEP"
    },
    SERASA: {
        GRANDE:"icone_menu icone_integracao_SERASA"
    },
    SRO: {
        GRANDE:"icone_menu icone_integracao_SRO"
    },
    INSTITUICOES_BANCARIAS: {
        GRANDE:"icone_menu icone_integracao_instituicoes_bancarias"
    },
    OPERACOES: {
        GRANDE:"icone_menu icone_operacoes"
    },
    DIVERSOS: {
        GRANDE:"icone_menu icone_diversos"
    },
    COMPLIANCE: {
        GRANDE:"icone_menu icone_compliance"
    },
    CONTABILIDADE: {
        GRANDE:"icone_menu icone_contabilidade"
    },
    ANUENCIA_ELETRONICA: {
        GRANDE:"icone_menu icone_anuencia_eletronica"
    },
    RECEBIMENTO: {
        GRANDE:"icone_menu icone_recebimento"
    },
    COSSEGURO: {
        GRANDE: "icone_menu icone_planilha_cosseguro"
    },
    ATUARIAL: {
        GRANDE: "icone_menu icone_atuarial"
    }
};