import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prvMascaraMesano]'
})
export class MascaraMesanoDirective {

  constructor(private el: ElementRef<HTMLInputElement>) { }

  ngOnInit() {
    this.el.nativeElement.placeholder = 'MM/AAAA';
  }  

  @HostListener('input', ['$event'])
  onInput(event) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/\D/g, ''); 

    const start = input.selectionStart;
    const end = input.selectionEnd;
    const originalLength = input.value.length;    

    if (value.length > 6) {
      value = value.substring(0, 6); 
    }

    if (value.length >= 2) {
      const month = value.substring(0, 2);
      const year = value.substring(2, 6);
      value = `${month}/${year}`;
    }   

    input.value = value;

    let posicaoCursor = start + (input.value.length - originalLength);

    input.setSelectionRange(posicaoCursor, posicaoCursor);    
    
    input.dispatchEvent(new Event('input'));
  }
  
}

