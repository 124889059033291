import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TelaFuncionalidadeComponent } from '../tela-funcionalidade/tela-funcionalidade.component';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-tela-pesquisa',
  templateUrl: './tela-pesquisa.component.html',
  styleUrls: ['./tela-pesquisa.component.scss']
})
export class TelaPesquisaComponent extends TelaFuncionalidadeComponent implements OnInit {

  @ViewChild('screen', { static: false }) screen: ElementRef;

  @Input() possuiAgrupamento:boolean=false;
  @Input() possuiOrdenacao:boolean=false;
  @Input() possuiPesquisaNicho:boolean=false;
  @Input() possuiPesquisaGenerica:boolean=false;
  @Input() exibindoResultado:boolean=true;
  @Input() exportarAtivo: boolean=false;
  @Input() abas:any[]=[];

  @Output() mudaExibicao = new EventEmitter<boolean>();
  @Output() exportar = new EventEmitter();
  @Output() atualizar = new EventEmitter<boolean>();
  @Output() novaPesquisa = new EventEmitter();

  constructor(router: Router,
    activatedRoute: ActivatedRoute,
    protected gerenciadorErroRequisicao: GerenciadorErroRequisicaoService) {
    super(router, activatedRoute, gerenciadorErroRequisicao);
  }

  ngOnInit() {
  }

  exportarCsv(){
    this.exportar.emit();
  }

  atualizarPesquisa(event: boolean){
    this.atualizar.emit(event);
  }

  getAmbiente() {
    console.log(environment.AMBIENTE);

    return environment.AMBIENTE;
  }

  prepararNovaPesquisa(){
    this.novaPesquisa.emit();
  }

  scrollTopo() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
  }

  scrollBase() {
    window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
    });
  }
}
