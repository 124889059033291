import { Component, OnInit, Input } from '@angular/core';
import { RespostaServidorVO } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-resposta-servidor',
  templateUrl: './resposta-servidor.component.html',
  styleUrls: ['./resposta-servidor.component.scss']
})
export class RespostaServidorComponent implements OnInit {

  @Input() respostaServidor:RespostaServidorVO<Object>;
  constructor() { }

  ngOnInit() {
  }

}
