import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-grupo-campos',
  templateUrl: './grupo-campos.component.html',
  styleUrls: ['./grupo-campos.component.scss']
})
export class GrupoCamposComponent implements OnInit {

  @Input() icone:string;
  @Input() titulo:string;
  @Input() centralizar:boolean;

  constructor() { }

  ngOnInit() {
  }

}
