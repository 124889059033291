import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-aguardando',
  templateUrl: './aguardando.component.html',
  styleUrls: ['./aguardando.component.scss']
})
export class AguardandoComponent implements OnInit {

  @Input() exibir:boolean=false;

  constructor() { }

  ngOnInit() {
  }

}
