import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prv-controle-paginas-externo',
  templateUrl: './controle-paginas-externo.component.html',
  styleUrls: ['./controle-paginas-externo.component.scss']
})
export class ControlePaginasExternoComponent implements OnInit, OnChanges {

  @ViewChild('controle', { static: false }) controle: TemplateRef<HTMLElement>;

  @Input() totalItens: number;
  @Input() tamanhoPagina: number;
  @Input() quantidadePaginas: number;
  @Input() paginaAtual: number;
  @Input() top: boolean = true;
  @Input() bottom: boolean = true;
  @Input() tamanhoPaginaDinamico: boolean = false;
  @Input() controleCentralizado: boolean = true;
  @Output() tamanhoPaginaChange = new EventEmitter<number>();
  @Output() paginaChange = new EventEmitter<number>();

  listaPaginas: number [] = [];
  tamanhoPaginaPadrao: number;
  
  tamanhoPaginaSelect: FormControl;
  paginaSelect: FormControl;

  constructor() { }

  ngOnInit() {
    this.tamanhoPaginaPadrao = this.tamanhoPagina;
    this.listaPaginas = this.criarListaPaginas();
    this.tamanhoPaginaSelect = new FormControl(this.tamanhoPagina);
    this.paginaSelect = new FormControl(this.paginaAtual);
    /* this.paginaSelect.valueChanges.subscribe(valor => {
      this.atualizarPagina(valor);
    }); */
    /* this.tamanhoPaginaSelect.valueChanges.subscribe(valor => {
      this.mudarTamanhoPagina(valor);
    }); */
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pagina"]){
      this.paginaSelect = new FormControl(changes["pagina"].currentValue);
    }
    if (changes["tamanhoPagina"]){
      this.tamanhoPaginaSelect = new FormControl(changes["tamanhoPagina"].currentValue);
    }
  }

  criarListaPaginas(){
    let paginas = [];
    for (let i=0; i < this.quantidadePaginas; i++){
      paginas.push(i);
    }
    return paginas;
  }

  atualizarPagina(valor: number) {
    this.paginaAtual = +valor;
    this.paginaSelect.setValue(valor);
    this.paginaChange.emit(this.paginaAtual);
  }

  mudarTamanhoPagina(tamanho: number) {
    this.tamanhoPaginaSelect.setValue(tamanho);
    this.tamanhoPagina = +tamanho;
    this.tamanhoPaginaChange.emit(this.tamanhoPagina);
  }

  desabilitarSetaEsquerda() {
    if (this.paginaAtual == 0){
      return true;
    }
  }

  desabilitarSetaDireita() {
    if (this.paginaAtual == this.quantidadePaginas - 1){
      return true;
    }
  }

  passarPagina(valor: number){
    this.paginaSelect.setValue(this.paginaAtual + valor);
    this.paginaChange.emit(this.paginaAtual + valor);
  }

  exibirSetas(){
    return this.quantidadePaginas>1;
  }

}
