import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SelecaoIndividualComponent } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/selecao-individual/selecao-individual.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RespostaServidorVO, TipoProdutorProjecaoQRY} from 'src/app/global/tiposPrevimil';
import { entradaDadosSimples } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/entrada';
import { Filtro, InicializadorFiltro } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-filtro-tipo-produtor',
  templateUrl: './filtro-tipo-produtor.component.html',
  styleUrls: ['./filtro-tipo-produtor.component.scss']
})
export class FiltroTipoProdutorComponent implements OnInit, entradaDadosSimples {

  @ViewChild('selecaoInput', { static: false }) selecaoInput: SelecaoIndividualComponent;

  @Input() tipoSelecao:string="combo";
  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  /* @Input() tituloLocal:string="Tipo Produtor"; */
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();

  filtroTipoProdutor:Filtro;

  constructor(private httpClient: HttpClient) { }

  public listarTiposProdutor() {
    let parametros = {
      "nomePesquisa":"PADRAO",
      "filtros": {}
    }
    let cabecalhos = new HttpHeaders({
        "Content-Type":"application/json"
    });     
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/privado/dominios/TipoDeProdutor/pesquisar",parametros
      ,{});
  }

  ngOnInit() {
    this.filtroTipoProdutor = this.filtro;
    this.listarTiposProdutor().subscribe(
      resposta => {
        this.filtroTipoProdutor.opcoes = new Array({titulo:"Todos os Tipos de Produtor", valor:-1});
        (resposta as RespostaServidorVO<TipoProdutorProjecaoQRY[]>).sucesso.forEach(
          tipoProdutorAtivo => {
            this.filtroTipoProdutor.opcoes.push({titulo:tipoProdutorAtivo.nome, valor:tipoProdutorAtivo.nome});
          }
        );
        this.setValor(this.filtroTipoProdutor.opcoes[0].valor);
      },
      falha => {
        console.log(falha);
        //this.aguardandoResultadoPesquisa = false;
      }      
    );
  }

  getValor() {
    return this.selecaoInput.getValor();
  }
  setValor(valorSelecionado: any) {
    console.log(valorSelecionado);
    this.selecaoInput.setValor(valorSelecionado);
  }
  setCondicao(valor: any) {
    this.selecaoInput.setCondicao(valor);
  }
  obterHash(tituloFiltro: string, tituloOpcao: string) {
    return this.selecaoInput.obterHash(tituloFiltro, tituloOpcao);
  }
  obterCondicaoValor() {
    return this.selecaoInput.obterCondicaoValor();
  }
  nomeCampo(): string {
    return this.selecaoInput.nomeCampo();
  }
  condicaoInformada(): boolean {
    return this.selecaoInput.condicaoInformada();
  }
  limpar() {
    this.setValor(-1);
  }
  removerFiltro(event){
    this.filtroAvancadoRemovido.emit(event);
  }
}
