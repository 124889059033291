import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioConectado } from 'src/app/frameworks/seguranca/tipos/tipos.seguranca';
import { AutenticacaoService } from 'src/app/frameworks/seguranca/servicos/autenticacao.service';

@Component({
  selector: 'prv-informacao-usuario',
  templateUrl: './informacao-usuario.component.html',
  styleUrls: ['./informacao-usuario.component.scss']
})
export class InformacaoUsuarioComponent implements OnInit {

  usuarioConectadoInfo$: Observable<UsuarioConectado>;

  constructor(private autenticacao:AutenticacaoService) { 
    this.usuarioConectadoInfo$ = autenticacao.usuarioConectadoOBS.asObservable();
  }

  ngOnInit() {
    this.autenticacao.publicarDadosUsuario();
  }

  sairSistema() {
    console.log("sairSistema()");
    this.autenticacao.sairSistema();
  }

}
