import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Data } from '@angular/router';
import { CacheRouteReuseStrategy } from 'src/app/global/cache-route-reuse.strategy';

type TipoQuadro = "padrao" | "transparente" | "totalizador";

@Component({
  selector: 'prv-quadro-padrao',
  templateUrl: './quadro-padrao.component.html',
  styleUrls: ['./quadro-padrao.component.scss']
})
export class QuadroPadraoComponent implements OnInit {

  @Input() exibirCabecalho:boolean = true;
  @Input() exibirCorpo:boolean = true;
  @Input() exibirRodape:boolean = true;
//  @Input() padrao:boolean = true;
  @Input() tipoQuadro:TipoQuadro="padrao";
  @Input() destacar:boolean=false;  
  @Input() corpoDiferenciado:boolean=false;
  @Input() transparente:boolean=false;
  @Input() sombra:boolean=true;
  @Input() abas:any[]=[];
  @Input() expansivel:boolean=false;
  @Input() habilitarExpansao:boolean=true;
  
  queryParams: Params;
  data: Data;

  constructor(private activatedRoute: ActivatedRoute) {  
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe( params => {
      this.queryParams = {
        "pesquisa": params.get("pesquisa"),
        "agrupamento": params.get("agrupamento"),
        "ordenacao": params.get("ordenacao")
      }
    });
  }

  clickNulo() {
    
  }

  definirExpansao(){
    if (!this.habilitarExpansao){
      return ['expansao_bloqueada']
    }else{
      return [];
    }
  }

  obterClassesQuadro() {
    let classesResposta = [];
    if (this.tipoQuadro=="padrao") {
      if (this.destacar) {
        classesResposta = ['previmil__quadro','previmil__quadroPadraoDestacado'];
      } else {
        classesResposta = ['previmil__quadro','previmil__quadroPadrao'];
      } 
    } else 
    if (this.tipoQuadro=="totalizador") {
      classesResposta = ['previmil__quadro','previmil__quadroTotalizador'];
    } else 
    {
      classesResposta = ['previmil__quadro','previmil__quadroTransparente'];
    }
    if (!this.sombra){
      classesResposta.push('previmil__quadroSemSombra');
    }
    return classesResposta;
  }

  obterClassesQuadroCorpo() {
    let classesResposta = [];
    if (this.corpoDiferenciado==true) {
      if (this.tipoQuadro=="totalizador") {
        classesResposta = ['previmil__quadroTotalizadorCorpo'];
      } 
    }
    if (this.transparente==true) {
      classesResposta.push('previmil__quadroPadraoTransparente');
    }
    return classesResposta;
  }

  obterEstilo() {
    let estiloBase={};
    /*    
    if (this.tipoQuadro=="padrao") {
      estiloBase = {
        "background-color": "#d6e5fa"
      } ;
    } else 
    if (this.tipoQuadro=="totalizador") {
      estiloBase = {
        "background-color": "#f2f2f2",
        "border-width":"1px",
        "border-style":"dotted"
      };
    } else 
    {
      estiloBase = {
        "background-color": "white",
//        "border-style": "inset",
//        "border-width": "1px",
        "box-shadow": "rgb(136, 136, 136) 0px 0px 0px 0px",
        "border-radius": "3px"};
  
    }
    */
    let estiloDestaque={};
    /*
    if (this.destacar==true) {
      if (this.tipoQuadro=="padrao") {
        estiloDestaque = {
          "background-color": "#93c6f7"
        }
      }
    };
    */
    let estiloResposta = Object.assign(estiloBase,estiloDestaque);
    console.log (estiloResposta);
    return estiloResposta;
  }

  obterEstiloExpansivel(){
    if (this.expansivel){
      return {
        "display": "grid",
        "grid-template-columns": "auto 30px"
      }
    }
  }

  possuiAbas(){
    return this.abas.length>0;
  }

  obterEstiloAbas(aba){
    let classesResposta = this.obterClassesQuadro();
    classesResposta.push("previmil__aba");
    if (aba.ativo){
      classesResposta.push("aba_ativa");
    }
    return classesResposta;
  }

  exibirEsconderCorpo(){
    if (!this.habilitarExpansao) return;
    this.exibirCorpo = !this.exibirCorpo;
  }

  getQueryParams(){
    return this.queryParams;
  }

  getUrlData(rota){
    /* console.log(history.state) */
    /* if (history.state && history.state.params){
      return history.state.params;
    } */
    let chave: string;
    let segmentos: string [] = rota.split("/");
    chave = segmentos[segmentos.length - 1]
    return CacheRouteReuseStrategy.queryParams[chave];
  }

}
