const DEF_BASELOCALSTORAGE="PREVIMIL_HML";

const AUTH_API_URL = 'https://auth-homolog.previmil.net';
const RESOURCE_API_URL = 'https://resource-homolog.previmil.net';

export const environment = {
  production: false,
  AMBIENTE:"HOMOLOG",
  BASE_LOCALSTORAGE: DEF_BASELOCALSTORAGE,
  CHAVE_TOKEN: DEF_BASELOCALSTORAGE+'_TOKEN',
  INDEX_ENDPOINT: RESOURCE_API_URL+':9200',

  AUTH_ENDPOINT: AUTH_API_URL+':9001/oauth2/token',
  API_ENDPOINT: RESOURCE_API_URL+':7001/previmil',

  SRO_ENDPOINT: RESOURCE_API_URL+':8095',
  PESQUISA_PARTICIPANTE_ENDPOINT: RESOURCE_API_URL+':8090',
  COBRANCA_ENDPOINT: RESOURCE_API_URL+':8093',
  ATUARIAL_ENDPOINT: RESOURCE_API_URL+':8096',
  FINANCEIRO_ENDPOINT: RESOURCE_API_URL+':8097',

  BASE_URL_SITE: 'https://portal-homolog.previmil.net'
};
