import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-rotulo-valor',
  templateUrl: './rotulo-valor.component.html',
  styleUrls: ['./rotulo-valor.component.scss']
})
export class RotuloValorComponent implements OnInit {

  @Input() rotulo:string;
  @Input() valor:any=null;
  @Input() larguraRotulo:string="120px";
  @Input() posicaoRotulo:string="center";
  @Input() valorAbaixo:boolean = false;

  constructor() { }

  ngOnInit() {
  }

  obterEstilo() {
    if (this.valorAbaixo){
      return {
        "display": "grid",
        "place-items": "normal left",
        "text-align": "left"
        /* "grid-template-columns": this.larguraRotulo */
      }
    }else{
      return {
        "display": "grid",
        "grid-template-columns": this.larguraRotulo+" auto",
        "text-align": "right"
      }
    }
  }

  obterPosicaoRotulo(){
    return {"align-self": this.posicaoRotulo};
  }

}
