import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'prv-janela-flutuante',
  templateUrl: './janela-flutuante.component.html',
  styleUrls: ['./janela-flutuante.component.scss']
})
export class JanelaFlutuanteComponent implements OnInit {

  @ViewChild("screen", { static: true }) screen: ElementRef;

  constructor(private location: Location) { 

  }

  ngOnInit() {
  }

  public voltarNavegacao() {
    this.location.back();
  }
}
