import { Directive, HostListener, ElementRef, Renderer2, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, flatMap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[prvArrastavel]'
})
export class ArrastavelDirective {

    @Input() gancho;

    mouseup = new EventEmitter<MouseEvent>();
    mousedown = new EventEmitter<MouseEvent>();
    mousemove = new EventEmitter<MouseEvent>();

    mousedrag: Observable<{top, left}>;

    @HostListener('document:mouseup', ['$event'])
    onMouseup(event: MouseEvent) {
        this.mouseup.emit(event);
    }

    @HostListener('document:mousedown', ['$event'])
    onMousedown(event) {
        if (this.gancho.isSameNode(event.target)){
            this.mousedown.emit(event);
            return false;
        }
    }

    @HostListener('document:mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        this.mousemove.emit(event);
    }

    constructor(private element: ElementRef,
      private renderer: Renderer2) {
        
        //this.renderer.setStyle(this.element.nativeElement, "cursor", "pointer");
        
        this.mousedrag = this.mousedown.pipe(map(event => {
            return {
                top: event.clientY - this.element.nativeElement.getBoundingClientRect().top,
                left: event.clientX - this.element.nativeElement.getBoundingClientRect().left,
            };
        }))
        .pipe(flatMap(
            imageOffset => this.mousemove.pipe(map(pos => ({
                top: pos.clientY - imageOffset.top,
                left: pos.clientX - imageOffset.left
            })))
            .pipe(takeUntil(this.mouseup)
        )));
    }

    ngOnInit() {
        this.mousedrag.subscribe({
            next: pos => {
                this.renderer.setStyle(this.element.nativeElement, "top", pos.top + 'px');
                this.renderer.setStyle(this.element.nativeElement, "left", pos.left + 'px');
            }
        });
    }
}
