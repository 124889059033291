import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Gerenciador } from 'src/app/frameworks/templates-tela/padroes-telas';

@Injectable({
  providedIn: 'root'
})
export class GerenciarRemessasSerasaService implements Gerenciador{
  
  constructor(private httpClient: HttpClient) { }

  consultarRemessasRetornos(paramentrosConsulta: object) {
    console.log(paramentrosConsulta);
    console.log(JSON.stringify(paramentrosConsulta));

    let cabecalhos = new HttpHeaders({
    //      "Authorization":"Basic UHJldmltaWxXZWI6UHJvSmV0b1Npc3RlbWFOb3Zv",
      "Content-Type":"application/json"
    });    

    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/integracao/serasa/consultarremessa/agruparPorMes", paramentrosConsulta,{});
  }

  consultarConteudoArquivo(idArquivo: string) {

    let filtros = {
      "idRemessa":{
        "tipoDado":null,
        "condicao":"IGUAL",
        "valor": idArquivo
      }
    }

    let parametrosConsulta = {
      "nomePesquisa": "PADRAO",
      "filtros": filtros
    }

    console.log(parametrosConsulta);
    console.log(JSON.stringify(parametrosConsulta));

    let cabecalhos = new HttpHeaders({
    //      "Authorization":"Basic UHJldmltaWxXZWI6UHJvSmV0b1Npc3RlbWFOb3Zv",
      "Content-Type":"application/json"
    });    

    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/integracao/serasa/consultardetalhes/pesquisar", parametrosConsulta,{});
  }

  public gerarRemessa(tipoRemessa: number, idContrato?: string): Observable<any> {
    
    let parametros = {
      "tipoRemessa": tipoRemessa,
	    "idContrato": idContrato
    }

    let cabecalhos = new HttpHeaders();
    cabecalhos = cabecalhos.append("Accept","text/plain;charset=utf-8");
    cabecalhos = cabecalhos.append("Content-Type", "application/json");

    return this.httpClient.post(
      environment.API_ENDPOINT + '/rest/privado/integracao/serasa/gerararquivoremessa/todos',
      JSON.stringify(parametros), {headers:cabecalhos, responseType: "text", observe:"response"}).pipe(map(resposta => {
        const contentDispositionHeader: string = resposta.headers.get('Content-Disposition');
        if (contentDispositionHeader){
          this.salvarArquivo(resposta);
          return true;
        }else{
          return resposta;
        }
      },
      catchError(falha => of(false))
      ));
  }

  carregarRetorno(formData: FormData){
    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/integracao/serasa/uploadarquivoremessa", formData, {})
  }

  pesquisaPadrao(filtros: any) {
    return this.consultarRemessasRetornos(filtros);
  }

  consultarDatasUltimaRemessaRetorno(){  
    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/integracao/serasa/consultarultimoenvio/pesquisar", {});
  }

  downloadArquivo(path: string){

    let parametro = {pathUrl: path};

    console.log(parametro);
    console.log(JSON.stringify(parametro));

    const cabecalhos = new HttpHeaders();
    cabecalhos.append("Accept","text/plain;charset=utf-8");
    
    this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/integracao/serasa/downloadarquivogerado",
    parametro, {headers:cabecalhos, responseType: "text", observe:"response"}).subscribe(
      resposta => {
        this.salvarArquivo(resposta);
      },
      falha => {
        console.log(falha);
      }
    );
  }

  salvarArquivo(response) {
                    //var data = response.data;
                    //var status = response.status;
                    //var header = response.headers();
                    //var fileType = header['content-type']; 
                    //: attachment; filename="exportarPlanilhaLancamentosCMDC.csv"
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    let filename = parts[1].split('=')[1];
    const blob = new Blob([response.body], { type: 'text/plain' });
    FileSaver.saveAs(blob, filename.replace(/"([^"]+(?="))"/g, '$1'));    
  }

  pesquisaDestaqueInicial() {
  }
  
}
