import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Filtro, HashMapInicializadorFiltros } from 'src/app/global/tipos/tiposGenericos';
import { ComponentePesquisa } from '../../templates-tela/componente-pesquisa';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametrosUrlService } from '../parametros-url.service';

@Component({
  selector: 'prv-pesquisa-dinamica',
  templateUrl: './pesquisa-dinamica.component.html',
  styleUrls: ['./pesquisa-dinamica.component.scss']
})
export class PesquisaDinamicaComponent implements OnInit, ComponentePesquisa {

  @ViewChild('pesquisaNicho', { static: false }) pesquisaNicho: ComponentePesquisa;
  @ViewChild('pesquisaGenerica', { static: false }) pesquisaGenerica: ComponentePesquisa;
  @ViewChild('parametrosPesquisa', { static: false }) parametrosPesquisa: ComponentePesquisa;

  @Input() nichoAtiva = true;
  @Input() genericaAtiva = true;
  @Input() customizadaAtiva = true;
  @Input() filtros: Filtro[] = [];
  @Input() valoresIniciais: HashMapInicializadorFiltros = {};

  @Output() pesquisar = new EventEmitter<boolean>();

  exibeDialogoSalvarPesquisa:boolean = false;
  exibeMensagemFormularioInvalido: boolean = false;
  exibeCarregamentoPesquisa: boolean = false;

  pesquisaCorrente: any;
  
  pesquisaSalvaCarregada: any;

  constructor() { }

  ngOnInit() {
  }

  ativarDesativarNicho() {
    this.nichoAtiva = !this.nichoAtiva;
  }

  alternarGenericaECustomizada(){
    if (!this.customizadaAtiva && !this.genericaAtiva){
      this.genericaAtiva = true;
    }else{
      this.genericaAtiva = !this.genericaAtiva;
      this.customizadaAtiva = !this.customizadaAtiva;
    }
  }

  limparFiltros() {
    if (this.pesquisaNicho){
      this.pesquisaNicho.limparFiltros();
    }
    if (this.pesquisaGenerica){
      this.pesquisaGenerica.limparFiltros();
    }
    if (this.parametrosPesquisa){
      this.parametrosPesquisa.limparFiltros();
    }
  }

  montarPesquisa() {
    if (this.pesquisaSalvaCarregada){
      //tratar pesquisa salva
    }
    if (this.genericaAtiva){
      return this.pesquisaGenerica.montarPesquisa();
    }else{
      return this.parametrosPesquisa.montarPesquisa();
    }
  }

  pesquisaTeclado(){
    this.pesquisar.emit();
  }

  exibirDialogoSalvarPesquisa() {
    this.exibeDialogoSalvarPesquisa = true;
  }

  carregarPesquisa(pesquisaSalvaCarregada: any){ 
    this.exibeCarregamentoPesquisa=false;
    this.pesquisaSalvaCarregada = pesquisaSalvaCarregada;
  }

}
