import { Component, OnInit, ViewChild } from '@angular/core';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';
import { RespostaServidorVO, FichaUsuarioDTO, DetalheRegiaoUsuarioQRY, DetalheProdutorUsuarioQRY } from 'src/app/global/tiposPrevimil';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from 'src/app/modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-usuario',
  templateUrl: './janela-usuario.component.html',
  styleUrls: ['./janela-usuario.component.scss']
})
export class JanelaUsuarioComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idUsuario: any;
  fichaCabecalho: FichaUsuarioDTO;
  fichaRegioes: DetalheRegiaoUsuarioQRY[];
  fichaProdutor: DetalheProdutorUsuarioQRY[];

  desenvolvedor: string;


  constructor(private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idUsuario = params.get("idUsuario");
        console.log(this.idUsuario);
      }
    );
    this.carregarUsuario();
  }

  carregarUsuario() {
    this.gerenciarFichasService.consultarDadosFichaUsuario(this.idUsuario).subscribe(
      (resposta) => {
        this.fichaCabecalho = resposta as FichaUsuarioDTO;
        console.log(this.fichaCabecalho);
        this.usuarioDesenvolvedor(this.fichaCabecalho.desenvolvedor);
        this.carregarRegioes();
        this.carregarProdutor();
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );

  }

  carregarRegioes() {
    this.gerenciarFichasService.consultarRegioesUsuario(this.idUsuario).subscribe(
      (resposta) => {
        this.fichaRegioes = (resposta as RespostaServidorVO<DetalheRegiaoUsuarioQRY[]>).sucesso;
        console.log(this.fichaRegioes);
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  carregarProdutor() {
    this.gerenciarFichasService.consultarProdutorUsuario(this.idUsuario).subscribe(
      (resposta) => {
        this.fichaProdutor = (resposta as RespostaServidorVO<DetalheProdutorUsuarioQRY[]>).sucesso;
        console.log(this.fichaProdutor);
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  usuarioDesenvolvedor (desenv: number) {
    if (desenv == 1) {
      this.desenvolvedor = "USUÁRIO (DESENVOLVEDOR)";
    } else 
      this.desenvolvedor = "USUÁRIO";
  }
}
