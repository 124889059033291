import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { RespostaServidorVO } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-quadro-dialogo',
  templateUrl: './quadro-dialogo.component.html',
  styleUrls: ['./quadro-dialogo.component.scss']
})
export class QuadroDialogoComponent implements OnInit {

  @Input() titulo: string;
  @Input() descricao: string = "";
  @Input() botaoTexto: string;
  @Input() carregando: boolean;
  @Input() resposta: RespostaServidorVO<any> = null;
  @Input() textoResposta: string;
  @Input() desabilitado: boolean = false;
  @Input() requisicaoValida: boolean = true;

  @Output() concluido = new EventEmitter();
  @Output() fechado = new EventEmitter();

  @ViewChild('gancho', { static: false }) gancho: ElementRef;
  @ViewChild('botaoFechar', { static: false }) botaoFechar: ElementRef;
  @ViewChild('botaoProcessar', { static: false }) botaoProcessar: ElementRef;
  
  constructor() { }

  ngOnInit() {
  }

  concluir(){
    this.concluido.emit();
  }

  fechar(){
    this.fechado.emit();
  }

}
