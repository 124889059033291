import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Validacao } from 'src/app/global/tiposPrevimil';
import { CriticaMensagem } from 'src/app/global/tipos/tiposGenericos';

@Injectable({
    providedIn: 'root'
  })
export class GerenciadorErroRequisicaoService{
    
    public mensagemErro500 = new Subject<CriticaMensagem>();
    public mensagemErro400 = new Subject<CriticaMensagem>();

    tratarMensagemErroRequisicao(erro: any, mensagemPadrao: string){
        if (erro.error){
            if (erro.error.criticas){
                this.tratarErroObjetoRespostaServidor(erro);
                return;
            }
            if (erro.error.mensagem){
                this.tratarErroObjeto(erro);
                return
            }
            if (erro.error.message){
                this.tratarErroPadrao(erro);
                return;
            }
            this.enviarMensagemPadraoDefinida(erro, mensagemPadrao);
        }else{
            this.enviarMensagemPadraoDefinida(erro, mensagemPadrao);
        }
    }

    tratarMensagemErroRequisicaoParaTexto(erro: any, mensagemPadrao: string) : string {
        if (erro.error){
            if (erro.error.criticas){
                let validacao = erro.error as Validacao;
                let erroMensagem = validacao.criticas[0].mensagem;
                let erroComplemento = validacao.criticas[0].complemento
                return `ERRO: ${erroMensagem} ${erroComplemento?erroComplemento:""}`;
            }
            if (erro.error.mensagem){
                return `ERRO: ${erro.error.mensagem}`;
            }
            if (erro.error.message){
                return `ERRO: ${erro.error.message}`;
            }
        }
        return mensagemPadrao;
    }

    private tratarErroObjetoRespostaServidor(erro: any){
        let validacao = erro.error as Validacao;
        let erroMensagem = validacao.criticas[0].mensagem;
        let erroComplemento = validacao.criticas[0].complemento
        let respostaErro = {
            mensagem: erroMensagem, 
            complemento:erroComplemento?erroComplemento:""
        }
        this.definirTipoDeErro(erro, respostaErro);
    }

    private tratarErroPadrao(erro: any){
        let respostaErro = {
            mensagem: erro.error.message, 
            complemento:null
        }
        this.definirTipoDeErro(erro, respostaErro);
    }

    private tratarErroObjeto(erro: any){
        let respostaErro = {
            mensagem: erro.error.mensagem, 
            complemento:null
        }
        this.definirTipoDeErro(erro, respostaErro);
    }

    private enviarMensagemPadraoDefinida(erro: any, mensagemPadrao: string){
        let respostaErro = {
            mensagem: mensagemPadrao, 
            complemento:null
        }
        this.definirTipoDeErro(erro, respostaErro);
    }

    private definirTipoDeErro(erro: any, respostaErro: any){
        if (erro.status>=500 && erro.status<600){
            this.mensagemErro500.next(respostaErro);
        }
        if (erro.status>=400 && erro.status<500){
            this.mensagemErro400.next(respostaErro);
        }
    }
}
