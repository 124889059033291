import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prv-rodape-sistema',
  templateUrl: './rodape-sistema.component.html',
  styleUrls: ['./rodape-sistema.component.scss']
})
export class RodapeSistemaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
