import { Injectable } from '@angular/core';
import { AcessoQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SegurancaService } from './seguranca.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { GerenciadorTokenService } from './gerenciador-token.service';
import * as jtw_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class GerenciadorAcessosService {

  constructor(
    public segurancaService:SegurancaService,
    private gerenciadorTokenService:GerenciadorTokenService) { }

  getAcessoGuarda(idModulo: string, pathFromRoot: ActivatedRouteSnapshot[]): Observable<boolean>{
    return this.segurancaService.obterAcessosUsuario(idModulo).pipe(map(
      resposta => {
        let funcionalidadesUsuario = (resposta as RespostaServidorVO<AcessoQRY[]>).sucesso;
        return this.verificarAcessoPorUrl(funcionalidadesUsuario, pathFromRoot);
      }),
      catchError(falha => of(false))
    );
  }

  private verificarAcessoPorUrl(acessos:AcessoQRY[] , pathFromRoot: ActivatedRouteSnapshot[]): boolean{
    let url = pathFromRoot.map(segment => segment.url.map(segment => segment.toString()).join('/')).join('/');
    let possuiAcesso: boolean = false;
    if (acessos.length > 0){
      acessos.forEach(acesso => {
        if (acesso.caminho == url && !acesso.bloqueado){
          possuiAcesso = true;
          return;
        }
      });
    }
    return possuiAcesso;
  }

  public obterEscopos() {
    let esteToken = this.gerenciadorTokenService.lerToken();
    let dadosToken = jtw_decode(esteToken);

    console.log("-----------------------------------------------");
    console.log(dadosToken);
    console.log("-----------------------------------------------");

    return dadosToken.escope;
  }

}

