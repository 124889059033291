import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';

@Component({
  selector: 'prv-botao-compartilhar',
  templateUrl: './botao-compartilhar.component.html',
  styleUrls: ['./botao-compartilhar.component.scss']
})
export class BotaoCompartilharComponent implements OnInit {

  @Input() exibindoResultado: boolean = false;

  @ViewChild('mensagem', { static: false }) mensagem: ElementRef;

  constructor(private parametrosUrl: ParametrosUrlService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  copied(event){
    this.renderer.setStyle(this.mensagem.nativeElement, "display", "block");
    setTimeout(()=>{
      this.renderer.setStyle(this.mensagem.nativeElement, "display", "none")
    }, 2000);
  }

  getUrl(){
    return this.parametrosUrl.getPesquisaUrl();
  }

}
