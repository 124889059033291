import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'prv-upload-arquivos',
  templateUrl: './upload-arquivos.component.html',
  styleUrls: ['./upload-arquivos.component.scss']
})
export class UploadArquivosComponent implements OnInit, AfterViewInit {

  @Input() titulo:string = "Carregar Arquivo";
  @Input() placeholder:string = "Selecione um arquivo";
  @Input() exibeDialogo:boolean = true;
  @Input() multiplosArquivos:boolean = false;
  @Input() tiposArquivosAceitos:string []; 
  @Input() mensagemSucesso: string;
  @Input() mensagemErro: string;
  @Input() carregando: boolean;
  @Input() desabilitado: boolean = false;

  @Output() exibeDialogoChange = new EventEmitter<boolean>();
  @Output() mensagemSucessoChange = new EventEmitter<string>();
  @Output() mensagemErroChange = new EventEmitter<string>();
  @Output() concluido = new EventEmitter<FormData>();
  @Output() finalizado = new EventEmitter();

  @ViewChild("uploadArquivos", { static: false }) uploadArquivos: ElementRef;

  selecaoInvalida: boolean = false;
  arquivos: File [] = [];
  formData: FormData;
  botaoTexto: string = "Concluir"

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    if (this.tiposArquivosAceitos){
      this.aceitarTiposArquivos();
    }
    if (this.multiplosArquivos){
      this.aceitarMultiplosArquivos();
    }
  }

  aceitarMultiplosArquivos(){
    this.renderer.setProperty(this.uploadArquivos.nativeElement, "multiple", true);
  }

  aceitarTiposArquivos(){
    this.renderer.setProperty(this.uploadArquivos.nativeElement, "accept", this.tiposArquivosAceitos.toString());
  }

  listarArquivos(arquivos: File[]){
    this.arquivos = [];
    for (const arquivo of arquivos) {
      this.arquivos.push(arquivo);
    }
    this.uploadArquivos.nativeElement.value = "";
  }

  tratarArquivosArrastados(arquivos: File[]){
    if (arquivos.length > 1 && !this.multiplosArquivos){
      this.selecaoInvalida = true;
      this.mensagemErro = "Selecione apenas um arquivo!"
    }else{
      this.selecaoInvalida = false;
      this.listarArquivos(arquivos);
    }
  }

  removerArquivo(indice){
    this.arquivos.splice(indice, 1);
  }

  fecharDialogo(){
    this.mensagemErroChange.emit(null);
    this.mensagemSucessoChange.emit(null);
    this.exibeDialogoChange.emit(false);
    this.arquivos = [];
  }

  habilitarDesabilitarBotao():boolean{
    return this.arquivos.length?false:true;
  }

  desabilitarBotao() {
    return !this.arquivos.length || this.desabilitado;
  }

  concluir(){

    this.formData = new FormData();

    this.arquivos.forEach(arquivo => {
      this.formData.append("file", arquivo);
    });
    this.concluido.emit(this.formData);
    
  }

  finalizar(){
    this.mensagemErroChange.emit(null);
    this.mensagemSucessoChange.emit(null);
    this.finalizado.emit();
  }

}
