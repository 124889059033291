import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { GerenciadorTokenService } from '../servicos/gerenciador-token.service';

@Injectable({
  providedIn: 'root'
})
export class GuardaUsuarioAutenticadoService implements CanActivate {

  constructor(
    private router:Router,
    private gerenciadorTokenService:GerenciadorTokenService) { }

  canActivate( 
    route: import("@angular/router").ActivatedRouteSnapshot, 
    state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    console.log('## Verifica se Usuário está autenticado e se seu token ainda está válido.');

    let acessoAutorizado = this.gerenciadorTokenService.possuiToken();
    if (!acessoAutorizado) {
      this.router.navigate(['/autenticacao'], {queryParams: { returnUrl: state.url }});
    }

    return acessoAutorizado;
  }


}
