import { Component, OnInit } from '@angular/core';
import { FichaAverbadoraQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from '../../../modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-averbadora',
  templateUrl: './janela-averbadora.component.html',
  styleUrls: ['./janela-averbadora.component.scss']
})
export class JanelaAverbadoraComponent implements OnInit {

  idAverbadora: any;
  fichaAverbadora: FichaAverbadoraQRY;

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idAverbadora = params.get("idAverbadora");
      }
    );
    this.carregarAverbadora();
  }

  carregarAverbadora() {
    this.gerenciarFichasService.consultarDadosFichaAverbadora(this.idAverbadora).subscribe(
      (resposta) => {
        this.fichaAverbadora = (resposta as RespostaServidorVO<FichaAverbadoraQRY[]>).sucesso[0];

        console.log(this.fichaAverbadora);
        
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

}
