import { Component, OnInit, Input, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import html2canvas from "html2canvas";
import { FormControl, FormGroup } from '@angular/forms';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';
import { jsPDF } from "jspdf";

@Component({
  selector: 'prv-botao-salvar-imagem',
  templateUrl: './botao-salvar-imagem.component.html',
  styleUrls: ['./botao-salvar-imagem.component.scss']
})
export class BotaoSalvarImagemComponent implements OnInit {

  @ViewChild('download', { static: false }) download: ElementRef;
  @ViewChild('nomeInput', { static: false }) nomeInput: ElementRef;
  @ViewChild('nomeFormulario', { static: false }) nomeFormulario: ElementRef;

  @Input() imagem: HTMLDivElement;
  @Input() exibindoResultado: boolean = false;

  exibeInput: boolean = false;
  formulario: FormGroup;
  carregandoPdf: boolean = false;
  cloneImagem: HTMLDivElement;

  constructor(private parametrosUrl: ParametrosUrlService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.formulario = new FormGroup({
      nomeArquivo: new FormControl()
   });
  }

  salvarImagem(){
    window.scroll({
      top: 0, 
      left: 0, 
    });
    html2canvas(this.imagem).then(canvas => {
      this.download.nativeElement.href = canvas.toDataURL('image/png');
      this.download.nativeElement.download = this.formulario.controls.nomeArquivo.value + '.png';
      this.download.nativeElement.click();
      this.formulario.reset();
      this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "none");
    });
  }

  salvarPdf(){
    this.carregandoPdf = true;
    window.scroll({
      top: 0, 
      left: 0, 
    });
    this.renderer.setStyle(document.body, "overflow", "hidden");
    this.ajustarImagemPdf();
    this.ajustarElementos(this.cloneImagem).then(()=>{
      this.gerarPdf();
    });
  }

  ajustarImagemPdf(){
    let img: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.appendChild(img, this.imagem.cloneNode(true));
    this.cloneImagem = img;
    this.renderer.setStyle(this.cloneImagem, "padding", "20px");
    this.renderer.appendChild(document.body, this.cloneImagem);
  }

  gerarPdf(){
      let atributosImagem = this.cloneImagem.getBoundingClientRect();
      let doc = new jsPDF(this.setOrientation(atributosImagem.width, atributosImagem.height), 'px', [atributosImagem.height + 10, atributosImagem.width + 10])
      
      doc.html(this.cloneImagem).then(()=>{
        doc.save(this.formulario.controls.nomeArquivo.value + '.pdf');
        this.renderer.removeChild(document.body, this.cloneImagem);
        this.renderer.setStyle(document.body, "overflow", "scroll");
        this.carregandoPdf = false;
      });
  }

  buscarBackgroudColor(elemento: Element){
    if (elemento.isEqualNode(this.cloneImagem)){
      return;
    }
    if (getComputedStyle(elemento).backgroundColor != "rgba(0, 0, 0, 0)"){
      return getComputedStyle(elemento).backgroundColor;
    }else{
      return this.buscarBackgroudColor(elemento.parentElement);
    }
  }

  async fontAwesomeParaImagem(elemento: Element){
    if (elemento.classList.contains("fa") || elemento.classList.contains("far") || elemento.classList.contains("fas")) {
      let container: HTMLElement = this.renderer.createElement("div");
      this.renderer.appendChild(elemento.parentElement, container);
      let background = this.buscarBackgroudColor(elemento);
      this.renderer.setStyle(container, "background-color", background);
      this.renderer.removeChild(elemento.parentElement, elemento);
      this.renderer.appendChild(container, elemento);
      let canvas = await html2canvas(container);
      let substituto = this.renderer.createElement("img");
      substituto.src = canvas.toDataURL("image/png", "png");
      container.parentElement.appendChild(substituto);
      this.renderer.removeChild(container.parentElement, container);
    }
  }

  removerEstiloStick(elemento: Element){
    if (getComputedStyle(elemento).position == "sticky"){
      this.renderer.setStyle(elemento, "position", "unset");
    }
  }

  async ajustarElementos(elemento: Element){

    if (elemento.getAttributeNames().includes("data-html2canvas-ignore")){
      return;
    }
    await this.fontAwesomeParaImagem(elemento);
    this.removerEstiloStick(elemento);

    if (!elemento.hasChildNodes()){
      return;
    }else{
      for (let i = 0; i < elemento.children.length; i++){
        await this.ajustarElementos(elemento.children[i]);
      }
    };
  }

  setOrientation(width: number, height: number){
    if (width < height){
      return 'p';
    }else{
      return 'l';
    }
  }

  copiarImagem(){
    html2canvas(this.imagem).then(canvas => {
      let navigator: any;
      navigator = window.navigator;
      canvas.toBlob((blob)=>{
        let data = [new ClipboardItem({ [blob.type]: blob })];
        navigator.clipboard.write(data).then(()=>{
          console.log("sucesso");
        }),
        ()=>{
          console.log("falha");
        }
      })
      this.formulario.reset();
      this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "none");
    });
  }

  exibirInput(){
    this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "block");
    this.nomeInput.nativeElement.focus();
  }

  cancelar(){
    this.formulario.reset();
    this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "none");
  }
  
  getUrl(){
    return this.parametrosUrl.getPesquisaUrl();
  }

}
