import { Component, OnInit, Input } from '@angular/core';
import { DataHoraService } from 'src/app/frameworks/utilitarios/servicos/data-hora.service';

@Component({
  selector: 'prv-grupo-contratos-remessas-retornos',
  templateUrl: './grupo-contratos-remessas-retornos.component.html',
  styleUrls: ['./grupo-contratos-remessas-retornos.component.scss']
})
export class GrupoContratosRemessasRetornosComponent implements OnInit {

  @Input() contratos: any [];

  pagina: number = 1;
  tamanhoPagina: number = 20;

  constructor(private dataHoraService: DataHoraService) { }

  ngOnInit() {
  }

  formatarDataHora(dataHora: string): string{
    return this.dataHoraService.formatarDiaMesAnoHora(dataHora);
  }

}
