import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpcoesTelaPrincipalComponent } from './opcoes-tela-principal/opcoes-tela-principal.component';
import { ElementosVisuaisGlobaisModule } from '../elementos-visuais-globais/elementos-visuais-globais.module';
import { RouterModule } from '@angular/router';
import { PesquisaModule } from '../pesquisa/pesquisa.module';
import { OpcoesSubmenuComponent } from './opcoes-submenu/opcoes-submenu.component';
import { DialogosModule } from '../dialogos/dialogos.module';
import { TemplatesTelaModule } from '../templates-tela/templates-tela.module';

@NgModule({
  declarations: [
    OpcoesTelaPrincipalComponent,
    OpcoesSubmenuComponent
  ],
  exports: [
    OpcoesTelaPrincipalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    ElementosVisuaisGlobaisModule,
    TemplatesTelaModule,
    PesquisaModule,
    DialogosModule
  ]
})
export class MenuOpcoesModule { }
