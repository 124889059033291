import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParticipanteQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { GerenciarFichasService } from 'src/app/modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-participante',
  templateUrl: './janela-participante.component.html',
  styleUrls: ['./janela-participante.component.scss']
})
export class JanelaParticipanteComponent implements OnInit {

  pagina: number = 1;
  tamanhoPagina: number = 12;

  public idParticipante : any;
  public participante : any;


  constructor(
    private activatedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(
      params => {
        this.idParticipante = params.get("idParticipante");

        console.log(params.get("idParticipante"));
      }
    );
    this.carregarParticipante();
  }

  carregarParticipante() {

    this.gerenciarFichasService.consultarDadosFichaParticipante(this.idParticipante).subscribe(
      (resposta) => {
        this.participante = (resposta as RespostaServidorVO<ParticipanteQRY[]>).sucesso[0];
        console.log(this.participante);

      },
      (falha) => {
        console.log("error");

        console.log(JSON.stringify(falha));
      }
    );

  }

}
