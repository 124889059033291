import { Pipe, PipeTransform } from '@angular/core';
import { TransacaoSusepQRY } from 'src/app/global/tiposPrevimil';

@Pipe({
  name: 'filtrarCorretores'
})
export class FiltrarCorretoresPipe implements PipeTransform {

  transform(corretores: TransacaoSusepQRY [], filtro: string): any {
    if (filtro){
      return corretores.filter(corretor => 
        corretor.nomeProdutor.toLowerCase().includes(filtro) || 
        corretor.codigoProdutor.toLowerCase().includes(filtro)
      )
    }
    return corretores;
  }
}
