import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prv-centro-cabecalho',
  templateUrl: './centro-cabecalho.component.html',
  styleUrls: ['./centro-cabecalho.component.scss']
})
export class CentroCabecalhoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
