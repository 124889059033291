import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as jtw_decode from 'jwt-decode';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class GerenciadorTokenService {

  constructor() {
  }

  private testaExisteToken() {
    let tokenArmazenado = this.lerToken();
    let tokenValido = !!tokenArmazenado;

    if (tokenValido) {
      let dados = jtw_decode(tokenArmazenado);
      //console.log("OAUTH2-TOKEN --> "+stringify(dados));
      //console.log("OAUTH2-TOKEN (EXP) --> "+dados.exp);
      tokenValido = (dados.exp > (new Date().getTime() + 1) / 1000);
      //console.log("OAUTH2-TOKEN Válido (EXP) --> "+tokenValido);
    }

    if (!tokenValido) {
      this.excluirTodosTokens();
    }

    return tokenValido;
  }

  possuiToken() {
//    return !!this.lerToken();
    return this.testaExisteToken();
  }

  salvarToken(token) {
    if (environment.AMBIENTE != "PRD" && environment.AMBIENTE != "HML"){
      window.sessionStorage.setItem(environment.CHAVE_TOKEN, token);
    }else{
      window.localStorage.setItem(environment.CHAVE_TOKEN, token);
    }
  }

  lerToken() {
    if (environment.AMBIENTE != "PRD" && environment.AMBIENTE != "HML"){
      return window.sessionStorage.getItem(environment.CHAVE_TOKEN);
    }else{
      return window.localStorage.getItem(environment.CHAVE_TOKEN);
    }
  }

  excluirToken() {
    if (environment.AMBIENTE != "PRD" && environment.AMBIENTE != "HML"){
      window.sessionStorage.removeItem(environment.CHAVE_TOKEN);
    }else{
      window.localStorage.removeItem(environment.CHAVE_TOKEN);
    }
  }

  excluirTodosTokens() {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

}
