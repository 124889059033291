import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { Gerenciador } from 'src/app/frameworks/templates-tela/padroes-telas';

@Injectable({
  providedIn: 'root'
})
export class GerenciarArquivosSusepService implements Gerenciador {

  constructor(private httpClient: HttpClient) { }

  pesquisaDestaqueInicial() {
  }

  consultarCorretoresAtivosEnvio(){
    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/MODULO/CorretorAtivo/pesquisar", {});
  }

  getEnviarCorretoresEndpoint(){
    return environment.API_ENDPOINT + "/rest/privado/integracao/susep/enviarbasecorretores";
  }

  consultarArquivosEnviadosSusep(parametrosConsulta: object){
    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/MODULO/ArquivoSusep/AgrupamentoMes", parametrosConsulta, {});
  }

  consultarConteudoArquivoSusep(idArquivo: any) {

    let parametros = {
      "nomePesquisa":"PADRAO",
      "filtros": {
          "idArquivoSusepCorretor":{"condicao":"IGUAL","valor":idArquivo}
      }
    }

    return this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/MODULO/TransacaoSusep/pesquisar", parametros, {});
  }

  pesquisaPadrao(filtros: any) {
    return this.consultarArquivosEnviadosSusep(filtros);
  }

  downloadArquivo(path: string){

    let parametro = {pathArquivo: path};

    let cabecalhos = new HttpHeaders();
    cabecalhos = cabecalhos.append("Accept","text/plain;charset=utf-8");
    
    this.httpClient.post(environment.API_ENDPOINT + "/rest/privado/MODULO/ArquivoSusep/downloadarquivogerado",
    parametro, {headers:cabecalhos, responseType: "text", observe:"response"}).subscribe(
      resposta => {
        this.salvarArquivo(resposta);
      },
      falha => {
        console.log(falha);
      }
    );
  }

  salvarArquivo(response) {
                    //var data = response.data;
                    //var status = response.status;
                    //var header = response.headers();
                    //var fileType = header['content-type']; 
                    //: attachment; filename="exportarPlanilhaLancamentosCMDC.csv"
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    let filename = parts[1].split('=')[1];
    const blob = new Blob([response.body], { type: 'text/plain' });
    FileSaver.saveAs(blob, filename.replace(/"([^"]+(?="))"/g, '$1'));    
  }
}
