import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoEmpresaComponent } from './global/visualizacao/fragmentos/logo-empresa/logo-empresa.component';
import { InformacaoUsuarioComponent } from './global/visualizacao/fragmentos/informacao-usuario/informacao-usuario.component';
import { AreaComponent } from './global/visualizacao/componentes/area/area.component';
import { CentroCabecalhoComponent } from './global/visualizacao/fragmentos/centro-cabecalho/centro-cabecalho.component';
import { CabecalhoSistemaComponent } from './global/visualizacao/fragmentos/cabecalho-sistema/cabecalho-sistema.component';
import { RodapeSistemaComponent } from './global/visualizacao/fragmentos/rodape-sistema/rodape-sistema.component';
import { DashboardUsuarioComponent } from './global/telas/dashboard-usuario/dashboard-usuario.component';

import { ElementosVisuaisGlobaisModule } from './frameworks/elementos-visuais-globais/elementos-visuais-globais.module';
import { SegurancaModule } from './frameworks/seguranca/seguranca.module';
import { UtilitariosModule } from './frameworks/utilitarios/utilitarios.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InteceptadorIncluirTokenService } from './frameworks/seguranca/servicos/inteceptador-incluir-token.service';
import { MenuNavegacaoComponent } from './global/componentes-nao-utilizados/menu-lateral/menu-navegacao/menu-navegacao.component';
import { MenuLateralComponent } from './global/componentes-nao-utilizados/menu-lateral/menu-lateral.component';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './global/cache-route-reuse.strategy';
import { MenuOpcoesModule } from './frameworks/menu-opcoes/menu-opcoes.module';
import { ToastrPrincipalComponent } from './global/visualizacao/fragmentos/toastr-principal/toastr-principal.component';
import { registerLocaleData } from '@angular/common';

import ptBr from '@angular/common/locales/pt';
import { TemplatesTelaModule } from './frameworks/templates-tela/templates-tela.module';
import { FichasModule } from './fichas/fichas.module';
import { MensagemComponent } from './modulos/mensagem/mensagem.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

registerLocaleData(ptBr);


@NgModule({
  declarations: [
    AppComponent,
    LogoEmpresaComponent,
    InformacaoUsuarioComponent,
    AreaComponent,
    CentroCabecalhoComponent,
    CabecalhoSistemaComponent,
    RodapeSistemaComponent,
    DashboardUsuarioComponent,
    MenuLateralComponent,
    MenuNavegacaoComponent,
    ToastrPrincipalComponent,
    MensagemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    
    TemplatesTelaModule,
    ElementosVisuaisGlobaisModule,
    UtilitariosModule,
    SegurancaModule,
    MenuOpcoesModule,
    FichasModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "pt-BR"},
    {provide: HTTP_INTERCEPTORS, useClass: InteceptadorIncluirTokenService, multi: true},
    {provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
