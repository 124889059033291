import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'prv-barra-progresso',
  templateUrl: './barra-progresso.component.html',
  styleUrls: ['./barra-progresso.component.scss']
})
export class BarraProgressoComponent implements OnInit {

  @ViewChild("barra", { static: false }) barra: ElementRef<HTMLDivElement>;

  @Input() progresso: number;
  @Input() largura: string = "150px";
  @Input() altura: string = "20px";
  @Input() cor: string = "rgb(71, 121, 79)";
  @Input() corTexto: string = "white";

  constructor() { }

  ngOnInit() {
  }

  desenharBarraProgresso(){
    return {
      background: `linear-gradient(90deg, ${this.cor} ${this.progresso}%, #ccc ${this.progresso}%`,
      color: this.corTexto,
      width: this.largura,
      height: this.altura
    }
  }

}
