import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MascaraCpfDirective } from './mascara-cpf.directive';
import { MascaraCnpjDirective } from './mascara-cnpj.directive';
import { MascaraRgDirective } from './mascara-rg.directive';

@Directive({
  selector: '[prvMascaraDinamica]'
})
export class MascaraDinamicaDirective {

  @Input() prvMascaraDinamica: string;

  cpf: MascaraCpfDirective;
  cnpj: MascaraCnpjDirective;
  rg: MascaraRgDirective; 

  constructor(private el: ElementRef/* ,
    private cpf: MascaraCpfDirective,
    private cnpj: MascaraCnpjDirective,
    private rg: MascaraRgDirective */) {
      this.cpf = new MascaraCpfDirective(this.el);
      this.cnpj = new MascaraCnpjDirective(this.el);
      this.rg = new MascaraRgDirective(this.el);
    }
  
  @HostListener('input', ['$event'])
  onInput(event) {
    
    switch(this.prvMascaraDinamica){
      case "cpf":
        this.cpf.onInput(event);
        break;
      case "cnpj":
        this.cnpj.onInput(event);
      break;
      case "rg":
        this.rg.onInput(event);
      break;
      default:
    }
  }
}
