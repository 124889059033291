import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AbaRota } from 'src/app/global/tipos/tiposGenericos';
import { CacheRouteReuseStrategy } from 'src/app/global/cache-route-reuse.strategy';
import { Params, Data, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'prv-apresentacao-abas-rotas',
  templateUrl: './apresentacao-abas-rotas.component.html',
  styleUrls: ['./apresentacao-abas-rotas.component.scss']
})
export class ApresentacaoAbasRotasComponent implements OnInit, OnDestroy {

  @Input() abas: AbaRota[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  queryParams: Params;
  data: Data;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe( params => {
      this.queryParams = {
        "pesquisa": params.get("pesquisa"),
        "agrupamento": params.get("agrupamento"),
        "ordenacao": params.get("ordenacao")
      }
    });
  }

  getUrlData(rota){
    /* console.log(history.state) */
    /* if (history.state && history.state.params){
      return history.state.params;
    } */
    let chave: string;
    let segmentos: string [] = rota.split("/");
    chave = segmentos[segmentos.length - 1]
    return CacheRouteReuseStrategy.queryParams[chave];
  }

  obterEstiloAba(aba: AbaRota): string[]{
    if (aba.ativo){
      return ["aba", "aba_ativa"];
    }
    return ["aba"];
  }

  ngOnDestroy(): void{
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
