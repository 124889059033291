import { Component, OnInit, ViewChild } from '@angular/core';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';
import { DetalheCoberturaRepactuacaoQRY, RespostaServidorVO, FichaRepactuacaoQRY } from 'src/app/global/tiposPrevimil';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from 'src/app/modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-repactuacao',
  templateUrl: './janela-repactuacao.component.html',
  styleUrls: ['./janela-repactuacao.component.scss']
})
export class JanelaRepactuacaoComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idDocumento: any;
  idProposta: any;
  fichaCabecalho: any;
  fichaCoberturas: DetalheCoberturaRepactuacaoQRY[];

  totalRepactuacao: number = 0;

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idDocumento = params.get("idDocumento");
      }
    );
    this.carregarRepactuacao();
    // this.carregarCoberturas();
  }

  carregarRepactuacao() {
    this.gerenciarFichasService.consultarDadosFichaRepactuacao(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaCabecalho = (resposta as RespostaServidorVO<FichaRepactuacaoQRY[]>).sucesso[0];
        this.idProposta = this.fichaCabecalho.idProposta;
        console.log(this.fichaCabecalho)
        this.carregarCoberturas();
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  carregarCoberturas() {
    this.gerenciarFichasService.consultarCoberturasFichaRepactuacao(this.idDocumento, this.idProposta).subscribe(
      (resposta) => {
        this.fichaCoberturas = (resposta as RespostaServidorVO<DetalheCoberturaRepactuacaoQRY[]>).sucesso;
        this.totalRepactuacao = this.calculaRepactuacao(this.fichaCoberturas);
        console.log(this.totalRepactuacao);
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  calculaRepactuacao(ficha : DetalheCoberturaRepactuacaoQRY[]) {
    let totalContribuicaoAtual: number = 0;
    let totalContribuicaoNovo: number = 0;
    ficha.forEach(cobertura => {
      totalContribuicaoAtual += Number(cobertura.valorContribuicaoAtual);
      totalContribuicaoNovo += Number(cobertura.valorContribuicaoNovo);
      console.log(cobertura.valorContribuicaoAtual);
      console.log(cobertura.valorContribuicaoNovo);
    });
    console.log(totalContribuicaoAtual);
    console.log(totalContribuicaoNovo);
    return Number(totalContribuicaoNovo) - Number(totalContribuicaoAtual);
  }

}
