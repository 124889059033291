import { Component, OnInit, ViewChild } from '@angular/core';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';
import { FichaDpsQRY, RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from 'src/app/modulos/comercial/servicos/gerenciar-fichas.service';

@Component({
  selector: 'prv-janela-dps',
  templateUrl: './janela-dps.component.html',
  styleUrls: ['./janela-dps.component.scss']
})
export class JanelaDpsComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idDocumento: any;
  fichaCabecalho: FichaDpsQRY;

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idDocumento = params.get("idDocumento");
        console.log(this.idDocumento);
      }
    );
    this.carregarDps();
  }

  carregarDps() {
    this.gerenciarFichasService.consultarDadosFichaDps(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaCabecalho = (resposta as RespostaServidorVO<FichaDpsQRY>).sucesso;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );

  }

}
