import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { TipoMensagem } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent implements OnInit {

  @Input() titulo: string;
  @Input() mensagem: string;
  @Input() tipoToastr: TipoMensagem;
  @Input() exibeToastr:boolean = true;

  @ViewChild("toastr", { static: false }) toastr: ElementRef;

  @Output() exibeToastrChange = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    /* setTimeout(this.fecharToastr.bind(this), 5000); */
  }

  fecharToastr(){
    this.renderer.addClass(this.toastr.nativeElement, "toastr_close");
    setTimeout(() => this.exibeToastrChange.emit(false), 300);
  }

  definirEstiloToastr(){
    switch (this.tipoToastr) {
      case "SUCESSO":
        return ["sucesso"];
      case "ERRO":
        return ["erro"];
      case "ALERTA":
        return ["alerta"];
      case "INFO":
        return ["info"];
      default:
        return [];
    }
  }

  definirEstiloIcone(){
    switch (this.tipoToastr) {
      case "SUCESSO":
        return "icone_sucesso fas fa-check-circle";
      case "ERRO":
        return "icone_erro fas fa-bug";
      case "ALERTA":
        return "icone_alerta fas fa-exclamation-triangle";
      case "INFO":
        return "icone_info fas fa-info-circle";
      default:
        return [];
    }
  }

}
