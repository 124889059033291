import { Component, OnDestroy, OnInit } from '@angular/core';
import { WebSocketConnector } from 'src/app/global/WebSocketConnector';
import { MensagemWebSocketVO } from 'src/app/global/tiposPrevimil';
import * as Stomp from 'stompjs';

@Component({
  selector: 'prv-toastr-principal',
  templateUrl: './toastr-principal.component.html',
  styleUrls: ['./toastr-principal.component.scss']
})
export class ToastrPrincipalComponent implements OnInit, OnDestroy{
  
  inscricaoSocketUsuario: Stomp.Subscription;
  inscricaoSocketBroadcast: Stomp.Subscription;
  mensagens: MensagemWebSocketVO<string>[] = [/* 
    {
      mensagem: "TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE TESTE",
      tipoMensagem: "ALERTA"
    },
    {
      mensagem: "TESTE",
      tipoMensagem: "INFO"
    },
    {
      mensagem: "TESTE",
      tipoMensagem: "SUCESSO"
    },
    {
      mensagem: "TESTE",
      tipoMensagem: "ERRO"
    }, */
  ];

  constructor(private webSocketConnector: WebSocketConnector) {
  }

  ngOnInit() {
    console.log("-----------------INSCRICAO_TOASTR-----------------")
    this.inscricaoSocketUsuario = this.webSocketConnector.inscrever("/user/queue/websocket", this.criarToastr.bind(this)); 
    this.inscricaoSocketBroadcast = this.webSocketConnector.inscrever("/topic/websocket", this.criarToastr.bind(this)); 
  }

  criarToastr(mensagem: any){
    let mensagemWebsocket = JSON.parse(mensagem.body) as MensagemWebSocketVO<string>;
    this.mensagens.push(mensagemWebsocket);
  }

  fecharToastr(indice: number){
    this.mensagens.splice(indice, 1);
  }

  ngOnDestroy() {
    if (this.inscricaoSocketUsuario) {
      this.inscricaoSocketUsuario.unsubscribe();
    }
    if (this.inscricaoSocketBroadcast) {
      this.inscricaoSocketBroadcast.unsubscribe();
    }
  }
}
