import { Component, OnInit } from '@angular/core';
import { DescricaoSistemaService } from '../../servicos/descricao-sistema.service';
import { PRINCIPAL } from 'src/app/global/navegacao';
import { SegurancaService } from 'src/app/frameworks/seguranca/servicos/seguranca.service';
import { AcessoQRY, RespostaServidorVO } from '../../tiposPrevimil';
import { GerenciadorAcessosService } from 'src/app/frameworks/seguranca/servicos/gerenciador-acessos.service';

@Component({
  selector: 'prv-dashboard-usuario',
  templateUrl: './dashboard-usuario.component.html',
  styleUrls: ['./dashboard-usuario.component.scss']
})
export class DashboardUsuarioComponent implements OnInit {

  /*
    {nome:"Telemarketing",icone:PREVIMIL_ICONE.TELEMARKETING.GRANDE,caminho:"/telemarketing/principal"},
    {nome:"Produção",icone:PREVIMIL_ICONE.PRODUCAO.GRANDE,caminho:"/producao/principal"},

    {nome:"Gestão Interna",icone:PREVIMIL_ICONE.GESTAO_INTENA.GRANDE,caminho:"/gestaoInterna/principal"},
    {nome:"Configuracoes",icone:PREVIMIL_ICONE.CONFIGURACOES.GRANDE,caminho:"/gestaoInterna/financeiro"},
    */

  /* public modulosUsuario = PRINCIPAL.rotas; */
  public modulosUsuario:AcessoQRY[] = [];

  escopos: Set<string> = new Set<string>();
  
  constructor(
    public descricaoModulo:DescricaoSistemaService,
    private segurancaService:SegurancaService,
    private gerenciadorAcessosService: GerenciadorAcessosService) { }

  ngOnInit() {
      console.log("Carregar menu: WEB_PORTAL_DASHBOARD");
      this.segurancaService.obterAcessosUsuario(this.descricaoModulo.getIdModulo()).subscribe(
          resposta => {
            this.modulosUsuario = (resposta as RespostaServidorVO<AcessoQRY[]>).sucesso;
            console.log("Carregar menu: WEB_PORTAL_DASHBOARD - SUCESSO");
          },
          falha => {
            console.log(falha);
          }
      );

      this.escopos = new Set<string>();
      this.gerenciadorAcessosService.obterEscopos().forEach(e => this.escopos.add(e));
      console.log(this.escopos);
  }

  temAcessoInformePagamento(){
    return this.escopos.has('informe.pagamento:baixar_informe_anual');
  }

}
