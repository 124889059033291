import { Component, OnInit, Renderer2, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'prv-quadro-salvar',
  templateUrl: './quadro-salvar.component.html',
  styleUrls: ['./quadro-salvar.component.scss']
})
export class QuadroSalvarComponent implements OnInit {

  @ViewChild('nomeInput', { static: false }) nomeInput: ElementRef;
  @ViewChild('nomeFormulario', { static: false }) nomeFormulario: ElementRef;
  @Output() salvar = new EventEmitter();

  exibeInput: boolean = false;
  formulario: FormGroup;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.formulario = new FormGroup({
      nomeArquivo: new FormControl()
    });
  }

  exibirInput(){
    this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "block");
    this.nomeInput.nativeElement.focus();
  }

  esconderInput(){
    this.formulario.reset();
    this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "none");
  }

  getNomeInput(){
    return this.formulario.controls.nomeArquivo.value;
  }

  cancelar(){
    this.formulario.reset();
    this.renderer.setStyle(this.nomeFormulario.nativeElement, "display", "none");
  }

  submit(){
    this.salvar.emit("");
  }
}
