import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';

enum ModoExibicao { GRAFICO, TABELA }

@Component({
  selector: 'prv-quadro-grafico-tabela',
  templateUrl: './quadro-grafico-tabela.component.html',
  styleUrls: ['./quadro-grafico-tabela.component.scss']
})
export class QuadroGraficoTabelaComponent implements OnInit, AfterViewInit {

  @Input() titulo: string;
  @Input() tituloTabela: string;
  @Input() icone: string;
  @Input() icone2: string;
  @Input() grafico: boolean = true;
  @Input() tabela: boolean = false;
  /* @Input() download: boolean = false; */

  @Output() exportar = new EventEmitter<boolean>();

  @ViewChild("graficoIcone", { static: false }) graficoIcone: ElementRef;
  @ViewChild("tabelaIcone", { static: false }) tabelaIcone: ElementRef; 

  modoExibicao: ModoExibicao;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    if (this.tabela){
      this.modoExibicao = ModoExibicao.TABELA;
    }else{
      this.modoExibicao = ModoExibicao.GRAFICO;
    }
  }

  definirTitulo(){
    if (this.modoExibicao == 0){
      return this.titulo;
    }else{
      return this.tituloTabela;
    }
}

  ngAfterViewInit(){
    if (this.grafico && this.tabela){
      this.estilizarBotaoAtivo();
    }
  }

  exibirTabela(){
    this.modoExibicao = ModoExibicao.TABELA;
    this.estilizarBotaoAtivo();
  }

  exibirGrafico(){
    this.modoExibicao = ModoExibicao.GRAFICO;
    this.estilizarBotaoAtivo();
  }

  estilizarBotaoAtivo(){
    if (this.modoExibicao == ModoExibicao.GRAFICO){
      this.renderer.setStyle(this.graficoIcone.nativeElement, "border-style", "inset");
      this.renderer.setStyle(this.tabelaIcone.nativeElement, "border-style", "outset");
    }else{
      this.renderer.setStyle(this.tabelaIcone.nativeElement, "border-style", "inset")
      this.renderer.setStyle(this.graficoIcone.nativeElement, "border-style", "outset");
    }
  }

  exportarCsv(){
    this.exportar.emit(true);
  }
}
