import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltrosAvancadosComponent } from './filtros-avancados/filtros-avancados.component';
import { DominiosModule } from 'src/app/dominios/dominios.module';
import { ElementosVisuaisGlobaisModule } from '../elementos-visuais-globais/elementos-visuais-globais.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ComandosPesquisaComponent } from './comandos-pesquisa/comandos-pesquisa.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ParametrosPesquisaComponent } from './parametros-pesquisa/parametros-pesquisa.component';
import { PesquisaGenericaComponent } from './pesquisa-generica/pesquisa-generica.component';
import { PesquisaNichoComponent } from './pesquisa-nicho/pesquisa-nicho.component';
import { PesquisaDinamicaComponent } from './pesquisa-dinamica/pesquisa-dinamica.component';
import { QuadroDialogoDinamicoDownloadComponent } from './quadro-dialogo-dinamico-download/quadro-dialogo-dinamico-download.component';
import { QuadroDialogoDinamicoComponent } from './quadro-dialogo-dinamico/quadro-dialogo-dinamico.component';
import { DialogosModule } from '../dialogos/dialogos.module';
import { CargaPesquisaComponent } from './carga-pesquisa/carga-pesquisa.component';
import { TemplatesTelaModule } from '../templates-tela/templates-tela.module';
import { CargaPesquisaFiltroPipe } from './carga-pesquisa/carga-pesquisa-filtro/carga-pesquisa-filtro.pipe';
import { PesquisaSalvaItemComponent } from './carga-pesquisa/pesquisa-salva-item/pesquisa-salva-item.component';
import { SalvaPesquisaComponent } from './salva-pesquisa/salva-pesquisa.component';

@NgModule({
  declarations: [
    FiltrosAvancadosComponent,
    ComandosPesquisaComponent,
    QuadroDialogoDinamicoDownloadComponent,
    QuadroDialogoDinamicoComponent,
    ParametrosPesquisaComponent,
    PesquisaGenericaComponent,
    PesquisaNichoComponent,
    PesquisaDinamicaComponent,
    CargaPesquisaComponent,
    CargaPesquisaFiltroPipe,
    PesquisaSalvaItemComponent,
    SalvaPesquisaComponent
  ],
  exports: [
    ComandosPesquisaComponent,
    QuadroDialogoDinamicoDownloadComponent,
    QuadroDialogoDinamicoComponent,
    ParametrosPesquisaComponent,
    PesquisaGenericaComponent,
    PesquisaNichoComponent,
    PesquisaDinamicaComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ElementosVisuaisGlobaisModule,
    DominiosModule,
    ClipboardModule,
    FormsModule,
    DialogosModule,
    TemplatesTelaModule
  ]
})
export class PesquisaModule { }