import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef, Output, EventEmitter, AfterViewInit, HostListener, ViewChild, Renderer2 } from '@angular/core';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';
import { Filtro } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-filtros-avancados',
  templateUrl: './filtros-avancados.component.html',
  styleUrls: ['./filtros-avancados.component.scss']
})
export class FiltrosAvancadosComponent implements OnInit, AfterViewInit {

  @ViewChild("botaoDropdown", { static: false }) botaoDropdown: ElementRef;
  @ViewChild("menuDropdown", { static: false }) menuDropdown: ElementRef;
  @ViewChildren("filtrosAvancadosInput") filtrosAvancadosInput: QueryList<ElementRef>;

  @Input() filtrosAvancados: Filtro[] = [];

  @Output() filtroMarcado = new EventEmitter<Event>();

  params: any = null;

  constructor(private parametrosUrl: ParametrosUrlService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.params = this.parametrosUrl.getPesquisa();
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.botaoDropdown.nativeElement.contains(event.target)){
      if(this.menuDropdown.nativeElement.classList.contains("visivel")){
        this.renderer.removeClass(this.menuDropdown.nativeElement, "visivel");
      }else{
        this.renderer.addClass(this.menuDropdown.nativeElement, "visivel")
      }
    }else{
      if (!this.menuDropdown.nativeElement.contains(event.target)){
        this.renderer.removeClass(this.menuDropdown.nativeElement, "visivel");
      }
    }
  }

  ngAfterViewInit(){
    if (this.params){
      this.marcarFiltrosComValor();
    }
  }

  marcarFiltrosComValor(){    
    this.filtrosAvancadosInput.forEach(filtro => {
      if (filtro.nativeElement.value in this.params.filtros){
        filtro.nativeElement.checked = true;
      }
    });
  }

  desmarcarFiltro(filtroAvancado){
    this.filtrosAvancadosInput.forEach(filtro => {
      if (filtro.nativeElement.value == filtroAvancado.campo){
        filtro.nativeElement.checked = false;
      }
    });
  }

  adicionarRemoverFiltro(event){
    this.filtroMarcado.emit(event);
  }
}
