import { Component, OnInit, Input, ContentChildren, TemplateRef, QueryList, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { AbaRota } from 'src/app/global/tipos/tiposGenericos';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';

@Component({
  selector: 'prv-apresentacao-abas',
  templateUrl: './apresentacao-abas.component.html',
  styleUrls: ['./apresentacao-abas.component.scss']
})
export class ApresentacaoAbasComponent implements OnInit, AfterContentInit {

  @ContentChildren(TemplateRef) conteudos: QueryList<TemplateRef<any>>;

  @Input() abas: AbaRota[];
  @Input() posicaoTitulo: string;
  @Output() abaAlterada = new EventEmitter<number>();

  listaConteudo: TemplateRef<any>[];
  conteudoAtivo: TemplateRef<any>;
  abaAtiva: number = 0;

  constructor(private parametrosUrl: ParametrosUrlService) { }

  ngOnInit() {
    let abaUrl = this.parametrosUrl.getQueryParams().params.aba;
    if (abaUrl){
      this.abaAtiva = abaUrl;
    }
  }

  ngAfterContentInit(){
    this.listaConteudo = this.conteudos.toArray();
    this.conteudoAtivo = this.listaConteudo[this.abaAtiva];
  }

  definirPosicaoTitulo(){
    return this.posicaoTitulo?{position:this.posicaoTitulo}:{};
  }

  obterEstiloAba(aba: number): string[]{
    if (this.abaAtiva==aba){
      return ["aba", "aba_ativa"];
    }
    return ["aba"];
  }

  ativarAba(indice: number){
    this.parametrosUrl.limparUrl().then(() =>{
      this.abaAtiva=indice;
      this.abaAlterada.emit(indice);
      this.conteudoAtivo = this.listaConteudo[this.abaAtiva];
    });
  }

}
