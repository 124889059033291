import { Pipe, PipeTransform } from '@angular/core';
import { DataHoraService } from '../servicos/data-hora.service';

@Pipe({
  name: 'formatarMesAno'
})
export class FormatarMesAnoPipe implements PipeTransform {

  constructor(private dataHoraService: DataHoraService){};

  transform(anomes:string, tipo: string): string {
    if (tipo=="texto"){
      return this.dataHoraService.anoMesParaAnoNomeMes(anomes);
    }else{
      return this.dataHoraService.anoMesParaMesAnoFormatado(anomes);
    }
  }

}
