import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PesquisaService {

  constructor(private httpClient: HttpClient) { }
  
  salvarPesquisa(pesquisa: any){
    return this.httpClient.post(environment.API_ENDPOINT + "", pesquisa);
  }

  carregarPesquisas(filtroAcesso: any){
    return this.httpClient.post(environment.API_ENDPOINT + "", filtroAcesso);
  }
}
