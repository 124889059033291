import { Component, OnInit } from '@angular/core';
import { DescricaoModuloSegurancaService } from '../../servicos/descricao-modulo-seguranca.service';
import { SegurancaService } from '../../servicos/seguranca.service';
import { RespostaServidorVO, MigrarUsuarioRESULT, UsuarioVO } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-tela-migrar-usuarios',
  templateUrl: './tela-migrar-usuarios.component.html',
  styleUrls: ['./tela-migrar-usuarios.component.scss']
})
export class TelaMigrarUsuariosComponent implements OnInit {

  usuariosPendentesMigracao:UsuarioVO[]=[];
  mensagem:string;

  constructor(
    public descricaoModulo:DescricaoModuloSegurancaService,
    private segurancaService:SegurancaService) { }

  ngOnInit() {
    this.carregarListaUsuariosPendentes();
  }

  carregarListaUsuariosPendentes() {
    this.segurancaService.obterUsuariosPendentesMigracao().subscribe(
      (resposta) => {
        this.usuariosPendentesMigracao = (resposta as RespostaServidorVO<UsuarioVO[]>).sucesso;
      },
      (falha) => {
        console.log(falha);
      }
    );
  }

  migrarUsuarios() {
    this.segurancaService.migrarUsuarios().subscribe(
      (resposta) => {
        this.mensagem = (resposta as RespostaServidorVO<MigrarUsuarioRESULT>).sucesso.mensagem;
        console.log(this.mensagem);
        this.carregarListaUsuariosPendentes();
      },
      (falha) => {
        console.log(falha);
      }
    );
  }


}
