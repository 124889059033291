import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-quadrinho',
  templateUrl: './quadrinho.component.html',
  styleUrls: ['./quadrinho.component.scss']
})
export class QuadrinhoComponent implements OnInit {

  @Input() exibirCabecalho:boolean = true;
  @Input() exibirCorpo:boolean = true;
  @Input() exibirRodape:boolean = true;
  @Input() destacar:boolean=false;  

  @Input() menorLargura:number;
  @Input() menorAltura:number;
  @Input() maiorLargura:number;
  @Input() maiorAltura:number;

  constructor() { }

  ngOnInit() {
  }

  obterEstilo() {
    return {
      "min-width": this.menorLargura?this.menorLargura+"px":"auto",
      "max-width": this.maiorLargura?this.maiorLargura+"px":"none",
      "min-height": this.menorAltura?this.menorAltura+"px":"auto",
      "max-height": this.maiorAltura?this.maiorAltura+"px":"none"
    };
  }

}
