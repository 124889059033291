import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DescricaoModuloSegurancaService } from '../servicos/descricao-modulo-seguranca.service';
import { Observable} from 'rxjs';
import { GerenciadorAcessosService } from '../servicos/gerenciador-acessos.service';

@Injectable({
  providedIn: 'root'
})
export class GuardaAcessoSeguranca implements CanActivate {

  constructor(public gerenciadorAcessos:GerenciadorAcessosService,
    public descricaoModulo:DescricaoModuloSegurancaService) {}

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{

      console.log("## guarda seguranca");

      return this.gerenciadorAcessos.getAcessoGuarda(this.descricaoModulo.getIdModulo(), next.pathFromRoot);
  }

  
  
}
