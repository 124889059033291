import { Component, OnInit, Output, ViewChild, Input, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filtro, HashMapInicializadorFiltros } from 'src/app/global/tipos/tiposGenericos';
import { ParametrosPesquisaComponent } from '../../pesquisa/parametros-pesquisa/parametros-pesquisa.component';
import * as FileSaver from 'file-saver';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prv-quadro-dialogo-dinamico-download',
  templateUrl: './quadro-dialogo-dinamico-download.component.html',
  styleUrls: ['./quadro-dialogo-dinamico-download.component.scss']
})
export class QuadroDialogoDinamicoDownloadComponent implements OnInit {

  @Input() titulo: string;
  @Input() descricao: string = "";
  @Input() parametros: Filtro [] = [];
  @Input() endpoint: string;
  @Input() exibeDialogo: boolean = true;
  @Input() valoresIniciais: HashMapInicializadorFiltros = {};

  @ViewChild('parametrosPesquisa', { static: false }) parametrosPesquisa: ParametrosPesquisaComponent;
  
  @Output() exibeDialogoChange = new EventEmitter<boolean>();
  @Output() concluido = new EventEmitter();
  
  carregando: boolean = false;
  parametrosAtivos: any = {};
  requisicaoValida: boolean = true;
  mensagemErro: string;
  desabilitado: boolean = false;

  opcaoRecebimentoInput: FormControl = new FormControl();

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
  }

  fecharDialogo(){
    this.exibeDialogoChange.emit(false);
    this.concluido.emit();
  }

  montarParametros(){
    return this.parametrosPesquisa.montarParametros()
  }

  verificarRequisicaoValida(){
    let parametros = this.parametrosAtivos;
    if (Object.keys(parametros).length==0){
      this.requisicaoValida=false;
    }else{
      this.requisicaoValida=true;
    }
  }

  gerarArquivo(){
    if (this.desabilitado) return;
    let valorOpcaoRecebimento = this.opcaoRecebimentoInput.value;
    switch (valorOpcaoRecebimento){
      case "V":
        this.visualizarArquivo();
        break;
      case "B":
        this.baixarArquivo();
        break;
      default:
        this.visualizarArquivo();
    }
  }

  visualizarArquivo(){
    this.parametrosAtivos = this.montarParametros();
    this.verificarRequisicaoValida()
    if (!this.requisicaoValida){
      return;
    }
    this.carregando = true;
    this.httpClient.post(this.endpoint, this.parametrosAtivos, {responseType: 'blob', observe:"response"}).subscribe(resposta => {
      this.carregando = false;
      if (resposta.status == 202){
        const blob = new Blob([resposta.body], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(blob);
        let tab = window.open();
        tab.location.href = fileUrl;
        //this.salvarArquivo(resposta);
      }
    },
    falha => {
      console.log(falha);
      this.carregando = false;    
    });
  }

  baixarArquivo(){
    this.parametrosAtivos = this.montarParametros();
    this.verificarRequisicaoValida()
    if (!this.requisicaoValida){
      return;
    }
    this.carregando = true;
    this.httpClient.post(this.endpoint, this.parametrosAtivos, {responseType: 'blob', observe:"response"}).subscribe(resposta => {
      if (resposta.status == 202){
        this.salvarArquivo(resposta);
      }
      this.carregando = false;
    },
    falha => {
      console.log(falha);
      this.carregando = false;    
    });
  }

  salvarArquivo(response) {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    let filename = parts[1].split('=')[1];
    const blob = new Blob([response.body], { type: 'application/pdf' });
    FileSaver.saveAs(blob, filename.replace(/"([^"]+(?="))"/g, '$1'));
  }

}
