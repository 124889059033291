import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarContratos'
})
export class FiltrarContratosPipe implements PipeTransform {

  transform(contratos: any[], filtro: string, criticas: boolean): any {
    if (filtro && !criticas){
      return contratos.filter(contrato => 
        contrato.nomeParticipante.toLowerCase().includes(filtro) || 
        contrato.cpfParticipante.toLowerCase().includes(filtro) ||
        contrato.numeroContrato.toString().toLowerCase().includes(filtro) 
      )
    }else if (filtro && criticas){
      return contratos.filter(contrato => {
        return (contrato.nomeParticipante.toLowerCase().includes(filtro) || 
        contrato.cpfParticipante.toLowerCase().includes(filtro) ||
        contrato.numeroContrato.toString().toLowerCase().includes(filtro)) && 
        (contrato.dataRetorno && contrato.critica != 0)
      })
    }else if (!filtro && criticas){
      return contratos.filter(contrato => 
        (contrato.dataRetorno && contrato.critica != 0)
      )
    }
    return contratos;
  }

}
