import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JanelaAverbadoraComponent } from './janelas/janela-averbadora/janela-averbadora.component';
import { JanelaProdutorComponent } from './janelas/janela-produtor/janela-produtor.component';
import { JanelaAssistenciaFinanceiraComponent } from './janelas/janela-assistencia-financeira/janela-assistencia-financeira.component';
import { janelaPropostaIndividualComponent } from './janelas/janela-proposta-individual/janela-proposta-individual.component';
import { JanelaPlanoRemuneracaoComponent } from './janelas/janela-plano-remuneracao/janela-plano-remuneracao.component';
import { ElementosVisuaisGlobaisModule } from '../frameworks/elementos-visuais-globais/elementos-visuais-globais.module';
import { ComandosFichaComponent } from './comandos-ficha/comandos-ficha.component';
import { PesquisaModule } from '../frameworks/pesquisa/pesquisa.module';
import { ClipboardModule } from 'ngx-clipboard';
import { JanelaCoberturaComponent } from './janelas/janela-cobertura/janela-cobertura.component';
import { JanelaParticipanteComponent } from './janelas/janela-participante/janela-participante.component';
import { JanelaProcessoComponent } from './janelas/janela-processo/janela-processo.component';
import { JanelaSerasaComponent } from './janelas/janela-serasa/janela-serasa.component';
import { GrupoContratosRemessasRetornosComponent } from './janelas/janela-serasa/grupo-contratos-remessas-retornos/grupo-contratos-remessas-retornos.component';
import { FiltrarContratosPipe } from './janelas/janela-serasa/filtro-pipe/filtrar-contratos.pipe';
import { JanelaSusepComponent } from './janelas/janela-susep/janela-susep.component';
import { GrupoCorretoresRemessasComponent } from './janelas/janela-susep/grupo-corretores-remessas/grupo-corretores-remessas.component';
import { FiltrarCorretoresPipe } from './janelas/janela-susep/filtro-corretores-pipe/filtrar-corretores.pipe';
import { JanelaCancelamentoComponent } from './janelas/janela-cancelamento/janela-cancelamento.component';
import { JanelaRepactuacaoComponent } from './janelas/janela-repactuacao/janela-repactuacao.component';
import { JanelaAnuenciaEEnvelopeComponent } from './janelas/janela-anuencia-e-envelope/janela-anuencia-e-envelope.component';
import { JanelaDpsComponent } from './janelas/janela-dps/janela-dps.component';
import { TemplatesTelaModule } from '../frameworks/templates-tela/templates-tela.module';
import { UtilitariosModule } from '../frameworks/utilitarios/utilitarios.module';
import { JanelaUsuarioComponent } from './janelas/janela-usuario/janela-usuario.component';

@NgModule({
  declarations: [
    janelaPropostaIndividualComponent,
    JanelaAssistenciaFinanceiraComponent,
    JanelaProdutorComponent,
    JanelaAverbadoraComponent,
    JanelaPlanoRemuneracaoComponent,
    ComandosFichaComponent,
    JanelaCoberturaComponent,
    JanelaParticipanteComponent,
    JanelaProcessoComponent,
    JanelaSerasaComponent,
    GrupoContratosRemessasRetornosComponent,
    FiltrarContratosPipe,
    JanelaSusepComponent,
    GrupoCorretoresRemessasComponent,
    FiltrarCorretoresPipe,
    JanelaCancelamentoComponent,
    JanelaRepactuacaoComponent,
    JanelaAnuenciaEEnvelopeComponent,
    JanelaDpsComponent,
    JanelaUsuarioComponent
  ],
  exports: [
    janelaPropostaIndividualComponent,
    JanelaAssistenciaFinanceiraComponent,
    JanelaProdutorComponent,
    JanelaAverbadoraComponent,
    JanelaPlanoRemuneracaoComponent,
    ComandosFichaComponent,
    JanelaSerasaComponent,
    JanelaSusepComponent,
    JanelaUsuarioComponent
  ],
  imports: [
    CommonModule,
    UtilitariosModule,
    ElementosVisuaisGlobaisModule,
    TemplatesTelaModule,
    PesquisaModule,
    ClipboardModule
  ]
})
export class FichasModule { }
