import { Injectable } from '@angular/core';
//import { CriptografiaService } from '../../utilitarios/servicos/criptografia.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_AUTENTICACAO:string = environment.AUTH_ENDPOINT;

@Injectable({
  providedIn: 'root'
})
export class SegurancaService {

  constructor(
    //private criptografia:CriptografiaService,
    private httpClient:HttpClient
    ) {

  }

  public autenticar(nomeUsuario:string, senha:string) {

    //let senhaCriptogradada = this.criptografia.bcryptData(senha);

    const parametrosAcesso = `grant_type=password&username=${nomeUsuario}&password=${senha}`;


    let cabecalhos = new HttpHeaders({
      "Authorization":"Basic UG9ydGFsQ29sYWJvcmFkb3IyMDIzOnNlbmhhU2VjcmV0YQ==",
      "Content-Type":"application/x-www-form-urlencoded"
    });

    console.log(cabecalhos);
    console.log("-------------------------------------------------");

    return this.httpClient.post(
      API_AUTENTICACAO
      , parametrosAcesso, {
      headers:cabecalhos
    });


  }


  public obterUsuariosPendentesMigracao() {

    let cabecalhos = new HttpHeaders({
      "Content-Type":"application/json"
    });

    let pesquisa = {
      "filtros": {},
      "nomePesquisa": "PADRAO"
    }

    return this.httpClient.post(
      environment.API_ENDPOINT+"/rest/privado/seguranca/UsuarioPendenteMigracao/pesquisar"
      , pesquisa, {
      headers:cabecalhos
    });
  }

  public migrarUsuarios() {

    let cabecalhos = new HttpHeaders({
      "Content-Type":"application/json"
    });

    let parametros = {}

    return this.httpClient.post(
      environment.API_ENDPOINT+"/rest/privado/seguranca/MigrarUsuario"
      , parametros, {
      headers:cabecalhos
    });
  }

  public obterAcessosUsuario(nomeModulo:string) {

    let cabecalhos = new HttpHeaders({
      "Content-Type":"application/json"
    });

    let pesquisa = {
      "nomePesquisa":"PADRAO",
      "filtros":{
        "nomeModulo":
          {"tipoDado":null,"condicao":"IGUAL","valor":nomeModulo}
      }
    }

    console.log(pesquisa);

    return this.httpClient.post(
      environment.API_ENDPOINT+"/rest/privado/seguranca/acesso/pesquisar"
      , pesquisa, {
      headers:cabecalhos
    });
  }


  public obterMenuUsuario(nomeModulo:string) {

    let cabecalhos = new HttpHeaders({
      "Content-Type":"application/json"
    });

    return this.httpClient.get(
      environment.API_ENDPOINT+"/rest/privado/seguranca/acesso/menuModulo/"+nomeModulo
      , {
      headers:cabecalhos
    });
  }

  public obterOpcaoParametro(idMenu:number) {

    let cabecalhos = new HttpHeaders({
      "Content-Type":"application/json"
    });

    return this.httpClient.get(
      environment.API_ENDPOINT+"/rest/privado/seguranca/menu/acaoPadrao/"+idMenu
      , {
      headers:cabecalhos
    });
  }


}
