import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'prv-pesquisa-salva-item',
  templateUrl: './pesquisa-salva-item.component.html',
  styleUrls: ['./pesquisa-salva-item.component.scss']
})
export class PesquisaSalvaItemComponent implements OnInit {

  @Input() pesquisaSalva: any;
  @Output() pesquisaCarregada = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  carregarPesquisa(){
    this.pesquisaCarregada.emit(this.pesquisaSalva);
  }

}
