import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from '../../../modulos/comercial/servicos/gerenciar-fichas.service';
import { RespostaServidorVO, DetalheAmortizacaoFinanceiraQRY, DetalheLiquidacaoAntecipadaQRY, FichaAssistenciaFinanceiraQRY } from 'src/app/global/tiposPrevimil';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';

@Component({
  selector: 'prv-janela-assistencia-financeira',
  templateUrl: './janela-assistencia-financeira.component.html',
  styleUrls: ['./janela-assistencia-financeira.component.scss']
})
export class JanelaAssistenciaFinanceiraComponent implements OnInit {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;  

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idDocumento: any;
  fichaCabecalho: any;
  fichaAmortizacoes: DetalheAmortizacaoFinanceiraQRY[];
  fichaLiquidacoes: DetalheLiquidacaoAntecipadaQRY[];

  constructor(
    private activedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idDocumento = params.get("idDocumento");
      }
    );
    this.carregarAssistencia();
    this.carregarAmortizacoes();
    this.carregarLiquidacoes();
  }

  carregarAssistencia() {
    this.gerenciarFichasService.consultarDadosCabecalhoFichaAssistencia(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaCabecalho = (resposta as RespostaServidorVO<FichaAssistenciaFinanceiraQRY[]>).sucesso[0];
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );

  }

  carregarAmortizacoes() {
    this.gerenciarFichasService.consultarAmortizacoesFichaAssistencia(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaAmortizacoes = (resposta as RespostaServidorVO<DetalheAmortizacaoFinanceiraQRY[]>).sucesso;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  carregarLiquidacoes() {
    this.gerenciarFichasService.consultarLiquidacoesFichaAssistencia(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaLiquidacoes = (resposta as RespostaServidorVO<DetalheLiquidacaoAntecipadaQRY[]>).sucesso;
        console.log(this.fichaLiquidacoes);
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }


}
