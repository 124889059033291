import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Filtro } from 'src/app/global/tipos/tiposGenericos';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PesquisaService } from '../pesquisa.service';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-carga-pesquisa',
  templateUrl: './carga-pesquisa.component.html',
  styleUrls: ['./carga-pesquisa.component.scss']
})
export class CargaPesquisaComponent implements OnInit {

  @Output() fecharJanela = new EventEmitter();
  @Output() pesquisaCarregada = new EventEmitter<any>();

  acessoControl = new FormControl("U");

  debounce = new Subject;

  filtroTexto: string; 

  pesquisasSalvas: any [] = [
    {
      nome: "Pesquisa por negativados",
      descricao: "Pesquisa todos os participantes negativados"
    },
    {
      nome: "Pesquisa por participantes de risco",
      descricao: "Pesquisa todos os participantes com idade acima de 75 anos"
    },
  ];

  filtros: Filtro[] = [
    {
      titulo: "Usuário",
      campo: "tipoSelecao",
      tipoFiltro: "selecao individual",
      condicoes: [
        { titulo: "Igual", valor: "IGUAL" }
      ],
      opcoes: [
        {valor: "U", titulo: "Usuário"},
        {valor: "G", titulo: "Grupo de Trabalho"},
        {valor: "T", titulo: "Todos"}
      ]
    }
  ]

  constructor(private route: ActivatedRoute,
    private pesquisaService: PesquisaService,
    private gerenciadorErroRequisicaoService: GerenciadorErroRequisicaoService) { }

  ngOnInit() {
    this.debounce.pipe(debounceTime(500)).subscribe(texto => {
      this.filtroTexto = texto.toString();
    })
  }

  pesquisar(){
    let filtroAcesso = this.acessoControl.value;
    this.pesquisaService.carregarPesquisas(filtroAcesso).subscribe({
      next: pesquisas => {
        this.pesquisasSalvas = pesquisas as any[];
      },
      error: erro => {
        console.log(erro);
        this.gerenciadorErroRequisicaoService.tratarMensagemErroRequisicao(erro, "Erro ao carregar pesquisas salvas.")
      }
    })
  }

}
