import { Component, OnInit, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { TipoDialogo } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-quadro-dialogo-padrao',
  templateUrl: './quadro-dialogo-padrao.component.html',
  styleUrls: ['./quadro-dialogo-padrao.component.scss']
})
export class QuadroDialogoPadraoComponent implements OnInit {

  @Input() tipoDialogo: TipoDialogo = null;
  @Input() botaoTexto: "OK";
  @Input() titulo: string;
  @Input() exibeDialogo:boolean = true;
  @Input() mensagemSucesso: string;
  @Input() mensagemErro: string;
  @Input() carregando: boolean = false;
  @Input() desabilitado: boolean = false;

  @Output() exibeDialogoChange = new EventEmitter<boolean>();
  @Output() mensagemSucessoChange = new EventEmitter<string>();
  @Output() mensagemErroChange = new EventEmitter<string>();
  @Output() concluido = new EventEmitter();
  @Output() finalizado = new EventEmitter();


  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    console.log(this.desabilitado);
  }

  estiloTipoDialogo(): Object{
    if (this.tipoDialogo){
      return {
        "display": "grid",
        "grid-template-columns": "30px auto",
        "gap": "10px",
        "place-items": "center normal"
      };
    }else{
      return {};
    }
  }

  fecharDialogo(){
    this.limparMensagens();
    this.exibeDialogoChange.emit(false);
  }

  concluir(){
    this.limparMensagens();
    this.concluido.emit();
  }

  finalizar(){
    this.limparMensagens();
    this.finalizado.emit();
  }

  limparMensagens(){
    this.mensagemErroChange.emit(null);
    this.mensagemSucessoChange.emit(null);
  }

}
