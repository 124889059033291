import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GerenciarFichasService {

  constructor(private httpClient: HttpClient) { }

  consultarDadosCabecalhoFichaProposta(idDocumento: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/propostaindividual", parametros
      , {});
  }

  consultarDadosFichaCobertura(idCobertura: number) {
    let parametros = {
      "filtros": {
        "idCobertura":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idCobertura
        }
      }
    }
    console.log(parametros);

    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/cobertura/pesquisar", parametros
      , {});
  }

  consultarDadosCabecalhoFichaAssistencia(idDocumento: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/assinteciafinanceira", parametros
      , {});
  }

  consultarDadosFichaParticipante(idParticipante: number) {
    let parametros = {
      "filtros": {
        "idParticipante":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idParticipante
        }
      }
    }

    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/participantes/pesquisar", parametros
      , {});

  }

  consultarDadosFichaProcesso(idProcesso: number) {
    let parametros = {
      "filtros": {
        "idProcesso":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idProcesso
        }
      }
    }

    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/processos/pesquisar", parametros
      , {});

  }

  consultarAmortizacoesFichaAssistencia(idDocumento: number){
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/assinteciafinanceira/amortizacoes", parametros
      , {});
  }

  consultarLiquidacoesFichaAssistencia(idDocumento: number){
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/assinteciafinanceira/liquidacoes", parametros
      , {});
  }

  consultarContribuicoesFichaProposta(idDocumento: number){
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/propostaindividual/cotribuicoespremios", parametros
      , {});
  }

  consultarProdutoresFichaProposta(idDocumento: number){
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/propostaindividual/produtores", parametros
      , {});
  }

  consultarDadosFichaAverbadora(idAverbadora: number){
    let parametros = {
      "filtros": {
        "idAverbadora":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idAverbadora
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/averbadora", parametros
      , {});
  }

  consultarDadosFichaProdutor(idProdutor: number){
    let parametros = {
      "filtros": {
        "idProdutor":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idProdutor
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/produtor", parametros
      , {});
  }

  consultarDadosFichaCancelamento(idDocumento: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/cancelamento", parametros
      , {});
  }

  consultarCoberturasFichaCancelamento(idDocumento: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/cancelamento/coberturas", parametros
      , {});
  }

  consultarDadosFichaRepactuacao(idDocumento: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/repactuacao", parametros
      , {});
  }

  consultarCoberturasFichaRepactuacao(idDocumento: number, idProposta: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        },
        "idProposta":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idProposta
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/repactuacao/coberturas", parametros
      , {});
  }

  consultarDadosFichaDps(idDocumento: number) {
    let parametros = {
      "filtros": {
        "idDocumento":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idDocumento
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/dps", parametros
      , {});
  }

  consultarDadosFichaUsuario(idUsuario: number) {
    return this.httpClient.get(
      environment.API_ENDPOINT + "/rest/ficha/usuario/"+idUsuario);
  }

  consultarRegioesUsuario(idUsuario: number) {
    let parametros = {
      "filtros": {
        "idUsuario":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idUsuario
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/usuario/regioes", parametros
      , {});
  }

  consultarProdutorUsuario(idUsuario: number) {
    let parametros = {
      "filtros": {
        "idUsuario":
        {
          "tipoDado": null,
          "condicao": "IGUAL",
          "valor": idUsuario
        }
      }
    }
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/ficha/usuario/produtor", parametros
      , {});
  }
}