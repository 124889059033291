import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

type TipoExibicao = "emLista" | "porItens";

@Component({
  selector: 'prv-quadro-agregacao-pesquisa',
  templateUrl: './quadro-agregacao-pesquisa.component.html',
  styleUrls: ['./quadro-agregacao-pesquisa.component.scss']
})
export class QuadroAgregacaoPesquisaComponent implements OnInit {

  @Input() exibeZero:boolean=true;
  @Input() titulo:string="";
  @Input() aggregacao:any;
  @Input() tipoExibicao:TipoExibicao="porItens";
  @Output() refinarPesquisa = new EventEmitter<String>();

  constructor() { }

  ngOnInit() {

  }

  refinar(comando:String) {
    console.log("QRD rerinar: "+comando)
    this.refinarPesquisa.emit(comando);
  }

}
