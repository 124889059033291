import * as SockJs from 'sockjs-client';
import * as Stomp from 'stompjs';
import * as jtw_decode from 'jwt-decode';
import { GerenciadorTokenService } from '../frameworks/seguranca/servicos/gerenciador-token.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UsuarioConectado } from '../frameworks/seguranca/tipos/tipos.seguranca';

@Injectable({
    providedIn: 'root'
})
export class WebSocketConnector {

    public stompClient$ = new BehaviorSubject<Stomp.Client>(null);

    constructor(private httpClient: HttpClient,
        private gerenciadorTokenService: GerenciadorTokenService) {
    }

    obterTicketEConectar(webSocketEndPoint: string, onConnect: Function, callbackError?: Function){
        if (this.gerenciadorTokenService.possuiToken()){
            this.httpClient.get(environment.API_ENDPOINT + "/rest/private/ticket/novo").subscribe({
                next: (ticket) => this.connect(webSocketEndPoint, (ticket as any).ticket, onConnect, callbackError),
                error: (erro) => this.onError(erro, webSocketEndPoint, onConnect)
            })
        }
    }

    connect(webSocketEndPoint: string, ticket: string, onConnect: Function, callbackError?: Function) {
        const errorCallback = callbackError; /* || this.onError.bind(this); */
        console.log("Starting a WebSocket connection");
        let token = this.gerenciadorTokenService.lerToken();
        let tokenDecoded = jtw_decode(token);
        /* const ws = new SockJs(webSocketEndPoint+"?access_token="+token); */
        const ws = new SockJs(webSocketEndPoint+"?"+(tokenDecoded.usename as string).toUpperCase());
        let stompClient = Stomp.over(ws);
        stompClient.connect({ticket: ticket}, 
            frame => {
                console.log(frame);
                this.stompClient$.next(stompClient);
                onConnect(frame);
            }, 
            error => {
                if (errorCallback){
                    errorCallback(error);
                }else{
                    this.onError(error, webSocketEndPoint, onConnect);
                }
            });
        console.log(stompClient);
    }

    disconnect(){
        let stompClient = this.stompClient$.getValue();
        stompClient.disconnect(()=>{
            this.stompClient$.next(null);
        });
    }

    inscrever(endpoint: string, onMessage){
        console.log(this.stompClient$.getValue());
        let conexao = this.stompClient$.getValue();
        if (!conexao){
            setTimeout(() => {
                console.log("Trying to subscribe again...");
                this.inscrever(endpoint, onMessage);
            }, 3000);
        }else{
            return conexao.subscribe(endpoint, onMessage);
        }
    }

    desinscrever(id: string){
        this.stompClient$.getValue().unsubscribe(id);
    }

    private onError(error: any, webSocketEndPoint: string, onConnect: Function) {
        console.log("Error while connect: " + error);
        setTimeout(() => {
            console.log("Trying to connect again...");
            this.obterTicketEConectar(webSocketEndPoint, onConnect, this.onError.bind(this));
        }, 3000);
    }

}