import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GerenciarFichasService } from '../../../modulos/comercial/servicos/gerenciar-fichas.service';
import { RespostaServidorVO, FichaPropostaIndividualQRY, DetalheProdutorPropostaQRY, DetalheContribuicaoPropostaQRY} from 'src/app/global/tiposPrevimil';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';

@Component({
  selector: 'prv-janela-proposta-individual',
  templateUrl: './janela-proposta-individual.component.html',
  styleUrls: ['./janela-proposta-individual.component.scss']
})
export class janelaPropostaIndividualComponent implements OnInit {

  @ViewChild("template", { static: true }) template: JanelaFlutuanteLateralComponent;  

  pagina: number = 1;
  tamanhoPagina: number = 12;

  idDocumento: any;
  
  fichaCabecalho: FichaPropostaIndividualQRY;
  detalheContribuicoes: DetalheContribuicaoPropostaQRY[];
  detalheProdutores: DetalheProdutorPropostaQRY[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private gerenciarFichasService: GerenciarFichasService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(
      params => {
        this.idDocumento = params.get("idDocumento");
      }
    );
    this.carregarProposta();
    this.carregarContribuicoes();
    this.carregarProdutores();
  }

  carregarProposta() {
    this.gerenciarFichasService.consultarDadosCabecalhoFichaProposta(this.idDocumento).subscribe(
      (resposta) => {
        this.fichaCabecalho = (resposta as RespostaServidorVO<FichaPropostaIndividualQRY[]>).sucesso[0];
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  carregarContribuicoes() {
    this.gerenciarFichasService.consultarContribuicoesFichaProposta(this.idDocumento).subscribe(
      (resposta) => {
        this.detalheContribuicoes = (resposta as RespostaServidorVO<DetalheContribuicaoPropostaQRY[]>).sucesso;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

  carregarProdutores() {
    this.gerenciarFichasService.consultarProdutoresFichaProposta(this.idDocumento).subscribe(
      (resposta) => {
        this.detalheProdutores = (resposta as RespostaServidorVO<DetalheProdutorPropostaQRY[]>).sucesso;
        console.log(this.detalheProdutores);
      },
      (falha) => {
        console.log(JSON.stringify(falha));
      }
    );
  }

}
