import { Injectable, Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidadorService {

  constructor() { }

  validarCaractere(event, filtro){
    if (filtro.tipo=="codigoNumerico" || filtro.tipo=="data"){
      const initalValue = event.target.value;
      event.target.value = initalValue.replace(/[^0-9\.]*/g, '');
      if ( initalValue !== event.target.value) {
        event.stopPropagation();
      }
    }
    if (filtro.tipo=="codigoAlfaNumerico"){
      const initalValue = event.target.value;
      event.target.value = initalValue.replace(/\W*/g, '');
      if ( initalValue !== event.target.value) {
        event.stopPropagation();
      }
    }
    if (filtro.tipo=="mes"){
      if (event.target.value.length > 7){
        event.target.value = event.target.value.replace(/.$/g, '');
        event.stopPropagation();
      }
      const initalValue = event.target.value;
      event.target.value = initalValue.replace(/[^0-9]*/g, '');
      if ( initalValue !== event.target.value) {
        event.stopPropagation();
      }
      
      var newVal = event.target.value; 

      if (newVal.length <= 2) {
        newVal = newVal.replace(/^(\d{0,2})/, '$1');
      }else{
        newVal = newVal.replace(/^(\d{0,2})(.*)/, '$1/$2')
      }
      event.target.value = newVal;
    }
  }
}
