import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { SelecaoIndividualComponent } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/selecao-individual/selecao-individual.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RespostaServidorVO, RegiaoAtivaQRY } from 'src/app/global/tiposPrevimil';
import { entradaDadosSimples } from 'src/app/frameworks/elementos-visuais-globais/componentes/entrada/entrada';
import { Filtro, InicializadorFiltro } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-filtro-regiao',
  templateUrl: './filtro-regiao.component.html',
  styleUrls: ['./filtro-regiao.component.scss']
})
export class FiltroRegiaoComponent implements OnInit, entradaDadosSimples {

  @ViewChild('selecaoInput', { static: false }) selecaoInput: SelecaoIndividualComponent;

  @Input() tipoSelecao:string="combo";
  @Input() filtro:Filtro;
  @Input() valorInicial:InicializadorFiltro;
  /* @Input() tituloLocal:string="Região"; */
  @Output() filtroAvancadoRemovido = new EventEmitter<any>();

  filtroRegiao: Filtro;

  constructor(private httpClient: HttpClient) { }

  public listarRegicoes() {
    let parametros = {
      "nomePesquisa":"PADRAO",
      "filtros": {}
    }
    let cabecalhos = new HttpHeaders({
        "Content-Type":"application/json"
    });     
    return this.httpClient.post(
      environment.API_ENDPOINT + "/rest/privado/dominios/RegiaoAtiva/pesquisar",parametros
      ,{});
  }  

  ngOnInit() {
    this.filtroRegiao = this.filtro;
    this.listarRegicoes().subscribe(
      resposta => {
        this.filtroRegiao.opcoes = new Array({titulo:"Todas as Regiões", valor:-1});
        (resposta as RespostaServidorVO<RegiaoAtivaQRY[]>).sucesso.forEach(
          regiaoAtiva => {
            this.filtroRegiao.opcoes.push({titulo:regiaoAtiva.nome, valor:regiaoAtiva.idRegiao});
          }
        );
        this.setValor(this.filtroRegiao.opcoes[0].valor);
      },
      falha => {
        console.log(falha);
        //this.aguardandoResultadoPesquisa = false;
      }      
    );
  }

  getValor() {
    return this.selecaoInput.getValor();
  }
  setValor(valorSelecionado: any) {
    console.log(valorSelecionado);
    this.selecaoInput.setValor(valorSelecionado);
  }
  setCondicao(valor: any) {
    this.selecaoInput.setCondicao(valor);
  }
  obterHash(tituloFiltro: string, tituloOpcao: string) {
    return this.selecaoInput.obterHash(tituloFiltro, tituloOpcao);
  }
  obterCondicaoValor() {
    return this.selecaoInput.obterCondicaoValor();
  }
  nomeCampo(): string {
    return this.selecaoInput.nomeCampo();
  }
  condicaoInformada(): boolean {
    return this.selecaoInput.condicaoInformada();
  }
  limpar() {
    this.setValor(-1);
  }
  removerFiltro(event){
    this.filtroAvancadoRemovido.emit(event);
  }
}
