import { Component, OnInit, Input } from '@angular/core';
import { TipoMensagem } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-quadro-resultado',
  templateUrl: './quadro-resultado.component.html',
  styleUrls: ['./quadro-resultado.component.scss']
})
export class QuadroResultadoComponent implements OnInit {

  @Input() transparente: boolean;
  @Input() statusTag: TipoMensagem;
  @Input() corTag: string;
  @Input() indisponivel:boolean=false;
  @Input() corFundo:string;
  @Input() corBorda:string;

  constructor() { }

  ngOnInit() {
  }

  definirCor(){
    return {
      "background-color": this.corFundo,
      "border-color": this.corBorda
    }
  }

  definirCorTag(){
    return {"background-color": this.corTag}
  }

  definirTransparencia(){
    let listaEstilos = [];
    if (!this.transparente){
      listaEstilos.push("previmil__card")
    }
    if (this.indisponivel) {
      listaEstilos.push("previmil__recurso_indisponivel");
    }
    return listaEstilos;
  }

  estiloStatusTag(){
    switch (this.statusTag){
      case "ALERTA": return["status_tag_alerta"];
      case "ERRO": return["status_tag_erro"];
      case "SUCESSO": return["status_tag_sucesso"];
    }
  }

}
