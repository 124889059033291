import { Directive, HostListener, Output, EventEmitter, HostBinding, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[prvDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() fileDropped = new EventEmitter<File[]>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  @HostListener("dragover", ["$event"]) public onDragOver(evento) {
    evento.preventDefault();
    evento.stopPropagation();
    
    this.renderer.setStyle(this.el.nativeElement, "border", "2px solid #3234e1");
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evento) {
    evento.preventDefault();
    evento.stopPropagation();
    
    this.renderer.removeStyle(this.el.nativeElement, "border");
  }

  @HostListener("drop", ["$event"]) public onDrop(evento) {
    evento.preventDefault();
    evento.stopPropagation();
    this.renderer.removeStyle(this.el.nativeElement, "border");
    const files: File[] = evento.dataTransfer.files;
    console.log(files);
    if (files.length > 0){
      this.fileDropped.emit(files);
    }
  }

}
