import { Component, OnInit, Input, ViewChildren, Renderer2, QueryList, ElementRef, Output, EventEmitter } from '@angular/core';
import { PRINCIPAL } from 'src/app/global/navegacao';
import { SegurancaService } from 'src/app/frameworks/seguranca/servicos/seguranca.service';
import { RespostaServidorVO, AcessoQRY } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-menu-navegacao',
  templateUrl: './menu-navegacao.component.html',
  styleUrls: ['./menu-navegacao.component.scss']
})
export class MenuNavegacaoComponent implements OnInit {

  @Input() subItem = null;
  @Output() exibeMenu = new EventEmitter<boolean>();
  @ViewChildren("menuSubItem") menuSubItem: QueryList<ElementRef>;

  rotas: any [] = [];

  constructor(private renderer: Renderer2, private segurancaService: SegurancaService) { }

  ngOnInit() {
    if (!this.subItem){
      this.subItem = PRINCIPAL;
    }
    this.segurancaService.obterAcessosUsuario(this.subItem.idModulo).subscribe(
        resposta => {
          let comFilho = false;
          (resposta as RespostaServidorVO<AcessoQRY[]>).sucesso.forEach(modulo => {
            if (this.subItem.filhos) {
              this.subItem.filhos.forEach(item => {
                
                if (modulo.nome == item.nome){
                  comFilho = true;
                  modulo["filho"] = item;
                  this.rotas.push(modulo);
                }
              });
            }
            if (!comFilho){
              this.rotas.push(modulo);
            }
            comFilho = false;
          });
        },
        falha => {
          console.log(falha);
        }
    );
  }

  fecharMenu(){
    this.exibeMenu.emit(false);
  }

  exibirSubitem(indice: number){
    this.renderer.setStyle(this.menuSubItem.toArray()[indice].nativeElement, "display", "inline");
  }

  esconderSubitem(indice: number){
    this.renderer.setStyle(this.menuSubItem.toArray()[indice].nativeElement, "display", "none");
  }

}