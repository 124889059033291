import * as SockJs from 'sockjs-client';
import * as Stomp from 'stompjs';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebSocketConnector } from 'src/app/global/WebSocketConnector';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MensagemWebSocketVO } from 'src/app/global/tiposPrevimil';

@Component({
  selector: 'prv-mensagem',
  templateUrl: './mensagem.component.html',
  styleUrls: ['./mensagem.component.scss']
})
export class MensagemComponent implements OnInit {

  inscricaoSocketUsuario: Stomp.Subscription;
  inscricaoSocketBroadcast: Stomp.Subscription;
  resposta = "Sem Resposta";

  stompClient: Stomp.Client;

  formulario: FormGroup;

  constructor(private http: HttpClient,
    private webSocketConnector: WebSocketConnector) {
    this.webSocketConnector.stompClient$.subscribe(
      {
        next: (stomp) => this.stompClient = stomp as Stomp.Client
      }
    )
  }

  ngOnInit() {
    this.inscricaoSocketUsuario = this.webSocketConnector.inscrever("/user/queue/teste", this.tratarMensagem.bind(this)); 
    this.inscricaoSocketBroadcast = this.webSocketConnector.inscrever("/topic/teste", this.tratarMensagem.bind(this)); 
    this.formulario = new FormGroup({
      mensagem: new FormControl(),
      destinatario: new FormControl(),
      endpoint: new FormControl("toastr"),
      tipoMensagem: new FormControl("usuario")
    });
  }

  enviarMensagem(){
    switch (this.formulario.get("endpoint").value + "_" + this.formulario.get("tipoMensagem").value){
      case "toastr_usuario": {
        this.http.post(environment.API_ENDPOINT + "/mensagem/toastr/" + this.formulario.get("destinatario").value, this.formulario.get("mensagem").value).subscribe();
        break;
      }
      case "toastr_broadcast": {
        this.http.post(environment.API_ENDPOINT + "/mensagem/toastr", this.formulario.get("mensagem").value).subscribe();
        break;
      }
      case "teste_usuario": {
        this.http.post(environment.API_ENDPOINT + "/mensagem/teste/" + this.formulario.get("destinatario").value, this.formulario.get("mensagem").value).subscribe();
        break;
      }
      case "teste_broadcast": {
        this.http.post(environment.API_ENDPOINT + "/mensagem/teste", this.formulario.get("mensagem").value).subscribe();
        break;
      }
    }
  }

  tratarMensagem(mensagem: any){
    let mensagemWebsocket = mensagem.body as string;
    this.resposta = mensagemWebsocket;
  }

  ngOnDestroy() {
    if (this.inscricaoSocketUsuario) {
      this.inscricaoSocketUsuario.unsubscribe();
    }
    if (this.inscricaoSocketBroadcast) {
      this.inscricaoSocketBroadcast.unsubscribe();
    }
  }

}
