import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParametrosUrlService {

  queryParams: Params;
  parametros: any = {};

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.queryParams = queryParams;
      this.parametros = {
        params: JSON.parse(queryParams.get("pesquisa")),
        agrupamento: queryParams.get("agrupamento"),
        ordenacao: queryParams.get("ordenacao")
      }
    });
  }
  
  getQueryParams(){
    return this.queryParams;
  }

  setQueryParams(queryParams: Params) {
    return this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams, state: history.state ,replaceUrl: true });
  }

  getPesquisaUrl(){
    return window.location.href;
  }

  getPesquisa(){
    return this.parametros.params;
  }

  getAgrupamento(){
    return this.parametros.agrupamento;
  }

  getOrdenacao(){
    return this.parametros.ordenacao;
  }
  
  setUrlParametros(pesquisa, agrupamento?, ordenacao?){
    let queryParams: Params = {
      "pesquisa": JSON.stringify(pesquisa),
      "agrupamento": agrupamento,
      "ordenacao": ordenacao
    };

  
    return this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams, state: history.state ,replaceUrl: true });
  }

  atribuirParametros(pesquisa, agrupamento?, ordenacao?){
    let queryParams: Params = {
      "pesquisa": JSON.stringify(pesquisa),
      "agrupamento": agrupamento,
      "ordenacao": ordenacao
    };

    return queryParams;
  }

  limparUrl(){
    return this.router.navigate([], { relativeTo: this.activatedRoute, state: history.state, replaceUrl: true });
  }
}
