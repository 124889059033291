import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'prv-logo-empresa',
  templateUrl: './logo-empresa.component.html',
  styleUrls: ['./logo-empresa.component.scss']
})
export class LogoEmpresaComponent implements OnInit {

  /* @ViewChild("botaoMenu") botaoMenu: ElementRef;
  @ViewChild("menuLateral") menuLateral: ElementRef; */

  constructor(private renderer:Renderer2) { }

  ngOnInit() {
  }

  /* @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.botaoMenu.nativeElement.contains(event.target)){
      if(this.menuLateral.nativeElement.classList.contains("visivel")){
        this.renderer.removeClass(this.menuLateral.nativeElement, "visivel");
      }else{
        this.renderer.addClass(this.menuLateral.nativeElement, "visivel")
      }
    }else{
      if (!this.menuLateral.nativeElement.contains(event.target)){
        this.renderer.removeClass(this.menuLateral.nativeElement, "visivel");
      }
    }
  } */

  getAmbiente() {
    return environment.AMBIENTE;
  }

  /* exibirEsconderMenu(event){
    this.renderer.removeClass(this.menuLateral.nativeElement, "visivel");
  } */
}
