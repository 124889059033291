import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, ContentChild, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prv-controle-paginas',
  templateUrl: './controle-paginas.component.html',
  styleUrls: ['./controle-paginas.component.scss']
})
export class ControlePaginasComponent implements OnInit, OnChanges {

  @ViewChild('controle', { static: false }) controle: TemplateRef<HTMLElement>;

  @Input() dados: number;
  @Input() tamanhoPagina: number;
  @Input() pagina: number;
  @Input() top: boolean = true;
  @Input() bottom: boolean = true;
  @Input() controleCentralizado: boolean = true;
  @Output() tamanhoPaginaChange = new EventEmitter<number>();
  @Output() paginaChange = new EventEmitter<number>();

  listaPaginas: number [] = [];
  listaTamanhoPaginas: number [] = [];
  tamanhoPaginaPadrao: number;
  
  tamanhoPaginaSelect: FormControl;
  paginaSelect: FormControl;

  constructor() { }

  ngOnInit() {
    this.tamanhoPaginaPadrao = this.tamanhoPagina;
    this.listaPaginas = this.opcoesPagina();
    this.listaTamanhoPaginas = this.criarListaTamanhos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pagina"]){
      this.paginaSelect = new FormControl(changes["pagina"].currentValue);
    }
    if (changes["tamanhoPagina"]){
      this.tamanhoPaginaSelect = new FormControl(changes["tamanhoPagina"].currentValue);
    }
  }

  criarListaTamanhos(){
    let listaIndices: number [] = [];
    let somador: number = this.tamanhoPaginaPadrao;
    let cont: number = 1;
    listaIndices.push(somador);
    while (somador < this.dados && cont < 4){
      somador = somador * 2;
      if (somador > this.dados){
        somador = this.dados;
      }
      listaIndices.push(somador);
      cont += 1;
    }
    return listaIndices;
  }

  atualizarPagina(valor: number){
    this.paginaSelect.setValue(valor);
    this.pagina = Number(valor);
    this.paginaChange.emit(this.pagina);
  }

  desabilitarSetaEsquerda() {
    if (this.pagina == 1){
      return true;
    }
  }

  desabilitarSetaDireita() {
    if (this.pagina*this.tamanhoPagina >= this.dados){
      return true;
    }
  }

  mudarTamanhoPagina(tamanho: number){
    let tamanhoPaginaAnterior = this.tamanhoPagina;
    this.tamanhoPaginaSelect.setValue(tamanho);
    this.tamanhoPagina = Number(tamanho);
    this.tamanhoPaginaChange.emit(this.tamanhoPagina);
    this.listaPaginas = this.opcoesPagina();
    let paginaNova;
    paginaNova = Math.ceil(((this.pagina*tamanhoPaginaAnterior)-tamanhoPaginaAnterior+1)/this.tamanhoPagina);
    this.atualizarPagina(paginaNova);
    console.log(paginaNova);
  }

  paginaSeguinte(){
    this.paginaSelect.setValue(this.pagina + 1);
    this.pagina += 1;
    this.paginaChange.emit(this.pagina);
  }

  paginaAnterior(){
    this.paginaSelect.setValue(this.pagina - 1);
    this.pagina -= 1;
    this.paginaChange.emit(this.pagina);
  }

  exibirSetas(){
    return this.dados > this.tamanhoPagina;
  }

  exibirTamanhoPagina(){
    return this.dados > this.tamanhoPaginaPadrao;
  }

  calcularQuantidadePaginas(){
    return Number(Math.ceil(this.dados/this.tamanhoPagina).toFixed(0));
  }

  opcoesPagina(){
    let paginas = [];
    for (let i=1; i <= this.calcularQuantidadePaginas(); i++){
      paginas.push(i);
    }
    return paginas;
  }
}
