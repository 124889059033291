import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { QuadroSalvarComponent } from 'src/app/frameworks/elementos-visuais-globais/componentes/layout/quadro-salvar/quadro-salvar.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';

const LIMITE_METODOS_ASSINCRONOS = 1;

@Component({
  selector: 'prv-comandos-lista',
  templateUrl: './comandos-lista.component.html',
  styleUrls: ['./comandos-lista.component.scss']
})
export class ComandosListaComponent implements OnInit {
  
  @ViewChild('mensagem', /* TODO: add static flag */ { static: false }) mensagem: ElementRef;
  @ViewChild("formulario", /* TODO: add static flag */ { static: false }) formulario: QuadroSalvarComponent;

  @Input() imagem: HTMLDivElement;
  @Input() exportarAtivo: boolean;
  @Output() exportar = new EventEmitter();
  @Output() atualizar = new EventEmitter<boolean>();
  @Output() novaPesquisa = new EventEmitter();

  carregandoPdf: boolean = false;
  comando: Function;
  cloneImagem: HTMLDivElement;
  nomeArquivo: string;
  valorTranferirParaClone: string;

  limitador: number = 0;
  contadorImagens: number = 0;
  filaConversao = [];

  constructor(protected parametrosUrl: ParametrosUrlService,
    protected renderer: Renderer2) {};
    

  ngOnInit() {
  }

  exibirInput(comando: Function){
    this.comando = comando;
    this.formulario.exibirInput();
  }

  copied(event){
    this.renderer.setStyle(this.mensagem.nativeElement, "display", "block");
    setTimeout(()=>{
      this.renderer.setStyle(this.mensagem.nativeElement, "display", "none")
    }, 2000);
  }

  salvarImagem(){
    window.scroll({
      top: 0, 
      left: 0, 
    });
    this.nomeArquivo = this.formulario.getNomeInput() + '.png';
    this.formulario.esconderInput();
    let img = this.ajustarImagem();
    html2canvas(img).then(canvas => {
      let download = this.renderer.createElement('a');
      download.href = canvas.toDataURL('image/png');
      download.download = this.nomeArquivo;
      download.click();
    });
    this.renderer.removeChild(document.body, img);
  }

  /* salvarPdf(){
    this.formulario.esconderInput();
    let img = this.ajustarImagem();
    html2canvas(img).then(canvas => {
      let atributosImagem = this.imagem.getBoundingClientRect();
      let doc = new jsPDF(this.setOrientation(atributosImagem.width, atributosImagem.height), 'px', [atributosImagem.height, atributosImagem.width])
      doc.addImage(canvas.toDataURL(), 'png', 0, 0, atributosImagem.width, atributosImagem.height);
      doc.save(this.formulario.getNomeInput() + '.pdf');
    });
    this.renderer.removeChild(document.body, img);
  } */

  copiarImagem(){
    let img = this.ajustarImagem();
    html2canvas(img).then(canvas => {
      let navigator: any;
      navigator = window.navigator;
      canvas.toBlob((blob)=>{
        let data = [new ClipboardItem({ [blob.type]: blob })];
        navigator.clipboard.write(data).then(()=>{
          console.log("sucesso");
        }),
        ()=>{
          console.log("falha");
        }
      })
    });
    this.renderer.removeChild(document.body, img);
  }

  ajustarImagem(){
    let img: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.setStyle(img, "width", this.imagem.offsetWidth.toString() + "px")
    this.renderer.appendChild(img, this.imagem.cloneNode(true));
    this.renderer.setStyle(img, "padding", "20px");
    this.renderer.appendChild(document.body, img);
    return img;
  }

  salvarPdf(){
    this.carregandoPdf = true;
    window.scroll({
      top: 0, 
      left: 0, 
    });
    this.renderer.setStyle(document.body, "overflow", "hidden");
    this.nomeArquivo = this.formulario.getNomeInput() + '.pdf';
    this.formulario.esconderInput();
    this.ajustarImagemPdf();
    this.ajustarElementos(this.cloneImagem);
    console.time('converterImagem');
    this.fontAwesomeParaImagem();
  }

  ajustarImagemPdf(){
    let img: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.appendChild(img, this.imagem.cloneNode(true));
    this.cloneImagem = img;
    this.renderer.setStyle(this.cloneImagem, "padding", "20px");
    this.renderer.appendChild(document.body, this.cloneImagem);
  }

  gerarPdf(){
      let atributosImagem = this.cloneImagem.getBoundingClientRect();
      let doc = new jsPDF(this.setOrientation(atributosImagem.width, atributosImagem.height), 'px', [atributosImagem.height + 10, atributosImagem.width + 10])
      
      doc.html(this.cloneImagem).then(()=>{
        doc.save(this.nomeArquivo);
        this.renderer.removeChild(document.body, this.cloneImagem);
        this.renderer.setStyle(document.body, "overflow", "scroll");
        this.carregandoPdf = false;
        this.nomeArquivo = null;
        this.filaConversao = [];
        this.contadorImagens = 0;
        console.timeEnd('converterImagem');
      });
  }

  buscarBackgroudColor(elemento: Element){
    if (elemento.isEqualNode(this.cloneImagem)){
      return;
    }
    if (getComputedStyle(elemento).backgroundColor != "rgba(0, 0, 0, 0)"){
      return getComputedStyle(elemento).backgroundColor;
    }else{
      return this.buscarBackgroudColor(elemento.parentElement);
    }
  }

  buscarFontAwesome(elemento: Element){
    if (elemento.classList.contains("fa") || elemento.classList.contains("far") || elemento.classList.contains("fas") || elemento.classList.contains("fab")) {
      let container: HTMLElement = this.renderer.createElement("div");
      this.renderer.appendChild(elemento.parentElement, container);
      let background = this.buscarBackgroudColor(elemento);
      this.renderer.setStyle(container, "background-color", background);
      this.renderer.removeChild(elemento.parentElement, elemento);
      this.renderer.appendChild(container, elemento);
      this.filaConversao.push(container);
    }
  }

  fontAwesomeParaImagem(){
    if (this.limitador == 0 && this.contadorImagens == this.filaConversao.length){
      this.gerarPdf();
      return;
    }
    if (this.limitador < LIMITE_METODOS_ASSINCRONOS && this.contadorImagens < this.filaConversao.length){
      /* let indice = this.contadorImagens; */
      let elemento = this.filaConversao[this.contadorImagens];
      this.contadorImagens ++;
      this.limitador++;
      console.log("limitador: " + this.limitador);
      console.log("contador: " + this.contadorImagens);
      html2canvas(elemento).then(canvas => {
        let substituto = this.renderer.createElement("img");
        substituto.src = canvas.toDataURL("image/png", "png");
        elemento.parentElement.appendChild(substituto);
        this.renderer.removeChild(elemento.parentElement, elemento);
        this.limitador--;
        console.log("limitador: " + this.limitador);
        this.fontAwesomeParaImagem();
      });
      this.fontAwesomeParaImagem();
    }
  }

  removerEstiloStick(elemento: Element){
    if (getComputedStyle(elemento).position == "sticky"){
      this.renderer.setStyle(elemento, "position", "unset");
    }
  }

  removerDropdown(elemento: Element){
    if (elemento.tagName == 'SELECT'){
      this.pegarValorDropdown(this.imagem, elemento);
      let container: HTMLSpanElement = this.renderer.createElement("span");
      this.renderer.insertBefore(elemento.parentElement, container, elemento);
      this.renderer.removeChild(elemento.parentElement, elemento);
      let inputTexto = this.renderer.createText(this.valorTranferirParaClone); 
      this.renderer.appendChild(container, inputTexto);
      console.log(container);
    }
  }

  pegarValorDropdown(elemento, elementoClone){
    if (elemento.isEqualNode(elementoClone)){
      this.valorTranferirParaClone = (<HTMLSelectElement>elemento).options[(<HTMLSelectElement>elemento).selectedIndex].text;
      return;
    }else if (!elemento.hasChildNodes()){
      return;
    }else{
      for (let i = 0; i < elemento.children.length; i++){
        this.pegarValorDropdown(elemento.children[i], elementoClone);
      }
    };
  }

  ajustarElementos(elemento: Element){

    if (elemento.getAttributeNames().includes("data-html2canvas-ignore")){
      return;
    }
    this.buscarFontAwesome(elemento);
    this.removerEstiloStick(elemento);
    this.removerDropdown(elemento);

    if (!elemento.hasChildNodes()){
      return;
    }else{
      for (let i = 0; i < elemento.children.length; i++){
        this.ajustarElementos(elemento.children[i]);
      }
    };
  }

  setOrientation(width: number, height: number){
    if (width < height){
      return 'p';
    }else{
      return 'l';
    }
  }

  exportarCsv(){
    this.exportar.emit();
  }

  prepararNovaPesquisa(){
    this.novaPesquisa.emit();
  }

  atualizarPesquisa(){
    this.atualizar.emit(true);
  }

  getUrl(){
    return this.parametrosUrl.getPesquisaUrl();
  }

  calcularProcessoDeConversao(){
    return (this.contadorImagens/this.filaConversao.length*100).toFixed(0);
  }

/*   buscarElemento(node: Node){
    if (!node.hasChildNodes){
      return;
    }
    if (node.isEqualNode(this.imagem)){
      return node;
    }else{
      node.childNodes.forEach(element => {
        this.buscarElemento(element);
      });
    }
  } */
}
