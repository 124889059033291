import { Injectable } from '@angular/core';
import { PRINCIPAL } from 'src/app/global/navegacao';
import { IDescricaoModulo } from '../tipos/tiposGenericos';

@Injectable({
  providedIn: 'root'
})
export class DescricaoSistemaService implements IDescricaoModulo {

  constructor() { }

  getIdModulo(): string {
    return PRINCIPAL.idModulo;
  }

  getNomeModulo(): string {
    return "SISTEMA";
  }
  getIconeGrande(): string {
    return "fa fa-bars"
  }
}
