import { Directive, OnInit, OnDestroy, Inject, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prvTamanhoAjustavel]'
})
export class TamanhoAjustavelDirective implements OnInit, OnDestroy {
  private nodes: HTMLElement[] = [];
  private data: {x: number, y: number, rect: ClientRect, direction: string};
  constructor(@Inject(ElementRef) private element: ElementRef) {
      this.mousemove = this.mousemove.bind(this);
      this.mouseup = this.mouseup.bind(this);
  }
  mousemove(e) {
      if (this.data) {
          switch(this.data.direction) {
              case 'top':
                  var offset = this.data.y - e.clientY;
                  var height = this.data.rect.height;
                  var top = this.data.rect.top;
                  var style = this.element.nativeElement.style;
                  style.height = height + offset + 'px';
                  style.top = top - offset + 'px';
                  break;
          }
      }
  }
  ngOnInit() {
      var node = document.createElement('div');
      node.classList.add('border-top', 'border');
      this.element.nativeElement.appendChild(node);
      this.nodes.push(node);
      window.addEventListener('mousemove', this.mousemove);
      window.addEventListener('mouseup', this.mouseup);
  }
  @HostListener('mousedown', ['$event'])
  mousedown(e) {
      if (e.target.classList.contains('border')) {
          var rect = this.element.nativeElement.getBoundingClientRect();
          this.data = {
              x: e.clientX,
              y: e.clientY,
              rect,
              direction: e.target.className.match(/border-([^ ]+)/)[1]
          };
          e.preventDefault();
      } else {
          delete this.data;
      }
  }
  mouseup(e) {
       delete this.data;
  }
  ngOnDestroy() {
      this.nodes.forEach(n => n.remove());
      window.removeEventListener('mousemove', this.mousemove);
      window.removeEventListener('mouseup', this.mouseup);
  }
}