import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { RespostaServidorVO, ConsultarDetalhesRemessaQRY } from 'src/app/global/tiposPrevimil';
import { GerenciarRemessasSerasaService } from 'src/app/integracoes/serasa/servicos/gerenciar-remessas-serasa.service';
import { ActivatedRoute } from '@angular/router';
import { JanelaFlutuanteLateralComponent } from 'src/app/frameworks/templates-tela/janela-flutuante-lateral/janela-flutuante-lateral.component';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'prv-janela-serasa',
  templateUrl: './janela-serasa.component.html',
  styleUrls: ['./janela-serasa.component.scss']
})
export class JanelaSerasaComponent implements OnInit, OnDestroy {

  @ViewChild("template", { static: false }) template: JanelaFlutuanteLateralComponent;
  @ViewChild("iconeNegativados", { static: false }) iconeNegativados: ElementRef;
  @ViewChild("iconePositivados", { static: false }) iconePositivados: ElementRef;
  @ViewChild("iconeCriticas", { static: false }) iconeCriticas: ElementRef;
  @ViewChild("iconeTodos", { static: false }) iconeTodos: ElementRef;

  debounce = new Subject;
  filtro: string;

  idArquivo: string;
  nomeArquivo: string;

  positivacaoNegativacao: ConsultarDetalhesRemessaQRY [] = [];
  positivacao: ConsultarDetalhesRemessaQRY [] = [];
  negativacao: ConsultarDetalhesRemessaQRY [] = [];
  totaisRemessa: any;  

  exibeTodos: boolean = true;
  exibePositivados: boolean = false;
  exibeNegativados: boolean = false;
  exibeCriticas: boolean = false;

  buscaConcluida: boolean = false;

  constructor(
    private renderer: Renderer2,
    private activedRoute: ActivatedRoute,
    private GerenciarRemessasSerasaService: GerenciarRemessasSerasaService) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idArquivo = params.get("idArquivo");
        this.nomeArquivo = params.get("nomeArquivo");
      }
    );
    this.carregarConteudoArquivo();
    this.ativarTodos();
    this.debounce.pipe(debounceTime(500)).subscribe(texto => {
      this.filtro = texto.toString().toLowerCase();
    })
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  agruparContratos(): void{
    this.positivacaoNegativacao.forEach(contrato => {
      if (contrato.tpOperacao == 1){
        this.negativacao.push(contrato);
      }else{
        this.positivacao.push(contrato);
      }
    });
  }

  carregarConteudoArquivo() {
    this.GerenciarRemessasSerasaService.consultarConteudoArquivo(this.idArquivo).subscribe(
      (resposta) => {
        this.totaisRemessa = (resposta as RespostaServidorVO<ConsultarDetalhesRemessaQRY[]>).sucesso["totaisRemessa"]
        this.positivacaoNegativacao = (resposta as RespostaServidorVO<ConsultarDetalhesRemessaQRY[]>).sucesso["dados"];
        this.agruparContratos();
        this.buscaConcluida = true;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
        this.buscaConcluida = true;
      }
    );
  }

  desativarBotoes(){
    this.renderer.removeStyle(this.iconeNegativados.nativeElement, "background-color");
    this.renderer.removeStyle(this.iconePositivados.nativeElement, "background-color");
    this.renderer.removeStyle(this.iconeTodos.nativeElement, "background-color");
    this.renderer.removeStyle(this.iconeNegativados.nativeElement, "border");
    this.renderer.removeStyle(this.iconePositivados.nativeElement, "border");
    this.renderer.removeStyle(this.iconeTodos.nativeElement, "border");

    this.exibeNegativados = false;
    this.exibePositivados = false;
    this.exibeTodos = false;
  }

  desativarCritica(){
    
  }

  ativarPositivados(){
    this.desativarBotoes();
    this.exibePositivados = true;
    this.renderer.setStyle(this.iconePositivados.nativeElement, "background-color", "rgb(96, 184, 255)");
    this.renderer.setStyle(this.iconePositivados.nativeElement, "border", "1px solid rgb(9, 91, 90)");
  }

  ativarNegativados(){
    this.desativarBotoes();
    this.exibeNegativados = true;
    this.renderer.setStyle(this.iconeNegativados.nativeElement, "background-color", "rgb(96, 184, 255)");
    this.renderer.setStyle(this.iconeNegativados.nativeElement, "border", "1px solid rgb(9, 91, 90)");
  }

  ativarTodos(){
    this.desativarBotoes();
    this.exibeTodos = true;
    this.renderer.setStyle(this.iconeTodos.nativeElement, "background-color", "rgb(96, 184, 255)");
    this.renderer.setStyle(this.iconeTodos.nativeElement, "border", "1px solid rgb(9, 91, 90)");
  }

  ativarDesativarCriticas(){
    if (this.exibeCriticas){
      this.exibeCriticas = false;
      this.renderer.removeStyle(this.iconeCriticas.nativeElement, "background-color");
      this.renderer.removeStyle(this.iconeCriticas.nativeElement, "border")
    }else{
      this.exibeCriticas = true;
      this.renderer.setStyle(this.iconeCriticas.nativeElement, "background-color", "rgb(96, 184, 255)");
      this.renderer.setStyle(this.iconeCriticas.nativeElement, "border", "1px solid rgb(9, 91, 90)");
    }
  }

}
