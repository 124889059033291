import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prv-quadro-operacao-individual',
  templateUrl: './quadro-operacao-individual.component.html',
  styleUrls: ['./quadro-operacao-individual.component.scss']
})
export class QuadroOperacaoIndividualComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
