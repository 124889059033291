import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { TipoDialogo } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-quadro-dialogo-base',
  templateUrl: './quadro-dialogo-base.component.html',
  styleUrls: ['./quadro-dialogo-base.component.scss']
})
export class QuadroDialogoBaseComponent implements OnInit {

  @Input() tipoDialogo: TipoDialogo;
  @Input() titulo: string;
  @Input() descricao: string = "";
  @Input() botaoTexto: string;
  @Input() carregando: boolean;
  @Input() mensagemSucesso: string;
  @Input() mensagemErro: string;
  @Input() desabilitado: boolean = false;
  @Input() exibeBotoes: boolean = true;

  @Output() concluido = new EventEmitter();
  @Output() fechado = new EventEmitter();
  @Output() finalizado = new EventEmitter();

  @ViewChild('gancho', { static: false }) gancho: ElementRef;
  
  constructor() { }

  ngOnInit() {
  }

  verificarComandosHabilitados(){
    return !this.mensagemSucesso 
      && !this.mensagemErro 
      && this.tipoDialogo!='Erro_Servidor' 
      && this.tipoDialogo!='Erro'
      && this.tipoDialogo!='Erro_Requisicao'
  }

  estilizarDialogo(){
    switch (this.tipoDialogo){
      case 'Erro_Servidor': return 'quadro_dialogo_erro_servidor';
      case 'Erro_Requisicao': return 'quadro_dialogo_erro_requisicao';
      default: return 'quadro_dialogo';
    }
  }
  
  estilizarTitulo(){
    switch (this.tipoDialogo){
      case 'Erro_Servidor': return 'quadro_titulo_erro_servidor';
      case 'Erro_Requisicao': return 'quadro_titulo_erro_requisicao';
      default: return 'quadro_titulo';
    }
  }

}
