import { Component, OnInit } from '@angular/core';
import { TelaFuncionalidadeComponent } from '../tela-funcionalidade/tela-funcionalidade.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-tela-processo',
  templateUrl: './tela-processo.component.html',
  styleUrls: ['./tela-processo.component.scss']
})
export class TelaProcessoComponent extends TelaFuncionalidadeComponent implements OnInit {

  constructor(router: Router,
    activatedRoute: ActivatedRoute,
    protected gerenciadorErroRequisicao: GerenciadorErroRequisicaoService) { 
    super(router, activatedRoute, gerenciadorErroRequisicao);
  }

  ngOnInit() {
  }

}
