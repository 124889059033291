import { Component, OnInit, Input } from '@angular/core';
import { CriticaMensagem } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-mensagem-erro',
  templateUrl: './mensagem-erro.component.html',
  styleUrls: ['./mensagem-erro.component.scss']
})
export class MensagemErroComponent implements OnInit {

  @Input() criticaMensagem: CriticaMensagem

  constructor() { }

  ngOnInit() {
  }

}
