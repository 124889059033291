import { Component, OnInit, HostListener, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { FiltroOpcoes } from 'src/app/global/tipos/tiposGenericos';

@Component({
  selector: 'prv-dropdown-checkbox',
  templateUrl: './dropdown-checkbox.component.html',
  styleUrls: ['./dropdown-checkbox.component.scss']
})

export class DropdownCheckboxComponent implements OnInit {
  
  @ViewChild("botaoDropdown", { static: false }) botaoDropdown: ElementRef;
  @ViewChild("menuDropdown", { static: false }) menuDropdown: ElementRef;
  @ViewChildren("itemLista") filtrosAvancadosInput: QueryList<ElementRef>;

  @Input() itensLista: FiltroOpcoes[] = [];
  @Input() placeholder: string;

  @Output() itemMarcadoDesmarcado = new EventEmitter<Event>();

  params: any = null;

  constructor(private renderer: Renderer2) { }

  ngOnInit(){
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.botaoDropdown.nativeElement.contains(event.target)){
      if(this.menuDropdown.nativeElement.classList.contains("visivel")){
        this.renderer.removeClass(this.menuDropdown.nativeElement, "visivel");
      }else{
        this.renderer.addClass(this.menuDropdown.nativeElement, "visivel")
      }
    }else{
      if (!this.menuDropdown.nativeElement.contains(event.target)){
        this.renderer.removeClass(this.menuDropdown.nativeElement, "visivel");
      }
    }
  }

  marcarDesmarcarItem(event){
    this.itemMarcadoDesmarcado.emit(event);
  }

}