import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prv-selecao-coletiva',
  templateUrl: './selecao-coletiva.component.html',
  styleUrls: ['./selecao-coletiva.component.scss']
})
export class SelecaoColetivaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
