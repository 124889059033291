import { Component, OnInit } from '@angular/core';
import { GerenciarArquivosSusepService } from 'src/app/integracoes/susep/servicos/gerenciar-arquivos-susep.service';
import { ActivatedRoute } from '@angular/router';
import { RespostaServidorVO, TransacaoSusepQRY } from 'src/app/global/tiposPrevimil';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'prv-janela-susep',
  templateUrl: './janela-susep.component.html',
  styleUrls: ['./janela-susep.component.scss']
})
export class JanelaSusepComponent implements OnInit {

  buscaConcluida: boolean = false;
  debounce = new Subject;
  filtro: string;

  idArquivo: string;
  nomeArquivo: string;

  resultadoConsultaArquivosSusep: TransacaoSusepQRY [] = []; 

  constructor(private gerenciarIntegracaoSusepService: GerenciarArquivosSusepService,
    private activedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.activedRoute.paramMap.subscribe(
      params => {
        this.idArquivo = params.get("idArquivo");
        this.nomeArquivo = params.get("nomeArquivo");
      }
    );
    this.carregarConteudoArquivo();
    this.debounce.pipe(debounceTime(500)).subscribe(texto => {
      this.filtro = texto.toString().toLowerCase();
    });
  }

  carregarConteudoArquivo() {
    this.gerenciarIntegracaoSusepService.consultarConteudoArquivoSusep(this.idArquivo).subscribe(
      (resposta) => {
        this.resultadoConsultaArquivosSusep = (resposta as RespostaServidorVO<TransacaoSusepQRY[]>).sucesso;
        this.buscaConcluida = true;
      },
      (falha) => {
        console.log(JSON.stringify(falha));
        this.buscaConcluida = true;
      }
    );
  }

}
