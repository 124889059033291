import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit, TemplateRef } from '@angular/core';
import { HashMapParametros, Filtro, TipoParametro, HashMapInicializadorFiltros, HashMapPredicados, FiltroPesquisa } from 'src/app/global/tipos/tiposGenericos';
import { ParametrosUrlService } from 'src/app/frameworks/pesquisa/parametros-url.service';
import { FiltrosAvancadosComponent } from '../filtros-avancados/filtros-avancados.component';
import { entradaDados, entradaDadosSimples, entradaDadosIntervalo } from '../../elementos-visuais-globais/componentes/entrada/entrada';
import { ComponentePesquisa } from '../../templates-tela/componente-pesquisa';

@Component({
  selector: 'prv-parametros-pesquisa',
  templateUrl: './parametros-pesquisa.component.html',
  styleUrls: ['./parametros-pesquisa.component.scss']
})
export class ParametrosPesquisaComponent implements OnInit, OnChanges, ComponentePesquisa {

  @ViewChildren("filtroInput") filtroInput: QueryList<any>;
  @ViewChild("filtrosAvancadosContainer", { static: true }) filtrosAvancadosContainer: TemplateRef<FiltrosAvancadosComponent>;
  @ViewChild("filtrosAvancadosInput", { static: true }) filtrosAvancadosInput: FiltrosAvancadosComponent;
  
  @Input() modoDialogo: boolean = false;
  @Input() filtros: Filtro[] = [];
  @Input() valoresIniciais: HashMapInicializadorFiltros = {};
  @Input() tipoParametro: TipoParametro = "FILTRO";
  @Output() pesquisar = new EventEmitter<boolean>();
  @Output() validacaoCampos = new EventEmitter<boolean>();

  filtrosAtivos: Filtro[] = []
  filtrosAvancados: Filtro[] = [];
  temCampoInvalido: number = 0;
  params: any = null;

  constructor(private parametrosUrl: ParametrosUrlService) { }

  ngOnInit() {
    this.filtrosAtivos = this.filtros;
    this.categorizarFiltros();
    this.params = this.parametrosUrl.getPesquisa();
    if(this.params){
      this.exibirFiltrosComValor();
    }
  }

/*   ngAfterViewInit(){
    if (!this.params){
      this.iniciarFiltrosComValor();
    }
  } */

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtros){
      this.ngOnInit();
    }
  }

  categorizarFiltros(){
    this.filtrosAvancados = [];
    for (let i = this.filtrosAtivos.length-1; i>=0; i--){
      if(this.filtrosAtivos[i].filtroAvancado){
        this.filtrosAvancados.unshift(this.filtrosAtivos[i]);
        this.filtrosAtivos=this.filtrosAtivos.filter((filtro, indice)=>{
          return indice != i;
        })
      }
    };
  }

  exibirFiltrosComValor(){
    this.filtrosAvancados.forEach(element => {
      if (element.campo in this.params.filtros){
        this.filtrosAtivos.push(element);
      }
    });
  }

  /* iniciarFiltrosComValor(){
    for (let configuracaoInicial of (this.valoresIniciaisFiltros)){
			if (configuracaoInicial){
				if (configuracaoInicial.valorAte){
					this.setValorIntervalo(
						configuracaoInicial.campo, 
						configuracaoInicial.valor, 
						configuracaoInicial.valorAte
					);
				}else{
					this.setValor(
						configuracaoInicial.campo, 
						configuracaoInicial.valor, 
						configuracaoInicial.condicao
					);
				}
			}
    }
	}
 */
  adicionarRemoverFiltro(event){
    if (event.target.checked){
      this.filtrosAvancados.forEach(filtro => {
        if (filtro.campo == event.target.value){
          this.filtrosAtivos.push(filtro);
        }
      });
    }else{
      for (let i=0; i<this.filtrosAtivos.length; i++) {
        if (this.filtrosAtivos[i].campo == event.target.value){
          this.filtrosAtivos.splice(i,1);
        }
      }
    }
  }

  /* ativarFiltroComValor(filtro:string, valor:string){
    this.filtrosAtivos.forEach(filtroAtivado => {
      if (filtroAtivado.campo == filtro){
        filtroAtivado = Object.defineProperty(filtroAtivado, "valorInicial", {value: valor});
      }
    });
    this.filtrosAvancados.forEach(filtroAvancado => {
      if (filtroAvancado.campo == filtro){
        filtroAvancado = Object.defineProperty(filtroAvancado, "valorInicial", {value: valor});
        this.filtrosAtivos.push(filtroAvancado);
      }
    })
  } */

  setValor(filtroCampo: string, valor: any, condicao?: string){
    let filtroInput = this.filtroInput as QueryList<entradaDadosSimples>;
    filtroInput.forEach(element => {
      if(element.filtro.campo == filtroCampo){
        element.setValor(valor);
        condicao?element.setCondicao(condicao):0;
      }      
    });
  }

  setValorIntervalo(filtroCampo: string, valorDe: any, valorAte: any){
    let filtroInput = this.filtroInput as QueryList<entradaDadosIntervalo>;
    filtroInput.forEach(element => {
      if(element.filtro.campo == filtroCampo){
        element.setValor(valorDe, valorAte);
      }      
    });
  }

  getValorFiltro(filtroTitulo: string){
    let valor: any;
    let filtroInput = this.filtroInput as QueryList<entradaDadosSimples>;
    filtroInput.forEach(element => {
      if(element.filtro.titulo == filtroTitulo){
        valor = element.getValor();
      }
    });
    return valor;
  }

  verificarValidadeCampos(validacao: number){
    this.temCampoInvalido = this.temCampoInvalido + validacao;
    if (this.temCampoInvalido > 0){
      this.validacaoCampos.emit(true);
    }else{
      this.validacaoCampos.emit(false);
    }
  }

  montarPesquisa(){
    switch (this.tipoParametro) {
      case "PARAMETRO":
        return this.montarParametros();
      case "FILTRO":
        return this.montarFiltro();
    }
  }

  montarFiltro(): FiltroPesquisa | HashMapParametros {
    let filtros: HashMapPredicados = {};
    let filtroInput = this.filtroInput as QueryList<entradaDados>;
    filtroInput.forEach(element => {
      if (element.condicaoInformada()) {
        filtros[element.nomeCampo()] = element.obterCondicaoValor();
      }
    });
   
    return {
      "nomePesquisa": "PADRAO",
      "filtros": filtros
    };    
  }

  montarParametros() {
    let parametros: HashMapParametros = {};

    this.filtroInput.forEach(element => {
      if (element.condicaoInformada()) {
        if (element.filtro.campoAte){
          parametros[element.nomeCampo()] = element.getValorDe();
          parametros[element.nomeCampoAte()] = element.getValorAte();
        }else{
          parametros[element.nomeCampo()] = element.getValor();
        }
      }
    });

    return parametros
  }

  limparFiltros() {
    this.filtroInput.forEach(element => {
      element.limpar();
    });
  }
  
  pesquisaTeclado(pressedEnter: boolean) {
    this.pesquisar.emit(pressedEnter);
  }

  removerFiltroAvancado(filtroAvancado){
    for (let i=0; i<this.filtrosAtivos.length; i++) {
      if (this.filtrosAtivos[i].campo == filtroAvancado.campo){
        this.filtrosAtivos.splice(i,1);
      }
    }
    this.filtrosAvancadosInput.desmarcarFiltro(filtroAvancado)
  }

}
