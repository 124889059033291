import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TelaAutenticacaoComponent } from './telas/tela.autenticacao/tela.autenticacao.component';
import { GuardaUsuarioNaoAutenticadoService } from './guardas/guarda-usuario-nao-autenticado.service';
import { TelaPerfilUsuarioComponent } from './telas/tela-perfil-usuario/tela-perfil-usuario.component';
import { GuardaUsuarioAutenticadoService } from './guardas/guarda-usuario-autenticado.service';
import { TelaPrincipalSegurancaComponent } from './telas/tela-principal-seguranca/tela-principal-seguranca.component';
import { GuardaAcessoDashboardGuard } from 'src/app/global/guardas/guarda-acesso-dashboard.guard';
import { TelaMigrarUsuariosComponent } from './telas/tela-migrar-usuarios/tela-migrar-usuarios.component';
import { GuardaAcessoSeguranca } from './guardas/guarda-acesso-seguranca.guard';

const routes: Routes = [
  {path:"", redirectTo: "principal", pathMatch:"full"},
  {path:"perfil", component: TelaPerfilUsuarioComponent, canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoSeguranca] },
  {path:"principal", component: TelaPrincipalSegurancaComponent  , canActivate: [GuardaUsuarioAutenticadoService] },
  {path:"migrarUsuario", component:TelaMigrarUsuariosComponent, canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoSeguranca]},  
];


//TelaPerfilUsuarioComponent


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegurancaRoutingModule { }
