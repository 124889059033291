import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { GerenciadorTokenService } from '../servicos/gerenciador-token.service';

@Injectable({
  providedIn: 'root'
})
export class GuardaAcessoModuloService implements CanActivate {

  constructor(
    private router:Router, 
    private gerenciadorTokenService:GerenciadorTokenService) { }

  canActivate(
      route: import("@angular/router").ActivatedRouteSnapshot, 
      state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    console.log('## verificar acesso ao módulo '+route.url);

    return true;
  }

}
