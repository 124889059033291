import { Injectable } from '@angular/core';

const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

@Injectable({
  providedIn: 'root'
})
export class DataHoraService {

  constructor() { }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  } 

  formatarData(anomesdia:number):string {
    //Formata anomesdia para exibição. Ex: 20211231 --> 31/12/2021
    if (anomesdia==null) return null;

    //console.log("FormatarDataPipe: "+anomesdia);
    let aaaa = Math.trunc(anomesdia/10000);
    let mesdia = anomesdia - (aaaa*10000);
    //console.log("FormatarDataPipe: "+mesdia);
    let mm = Math.trunc(mesdia/100);
    let dd = mesdia - (mm*100);
    //console.log("FormatarDataPipe: "+dd);
    let dataSaida = 
      this.pad(dd,2)+"/"+
      this.pad(mm,2)+"/"+
      this.pad(aaaa,4);

    return dataSaida;
  }

  formatarDiaMesAnoHora(dataHora: string): string{
    let valor: string [] = dataHora.split(" ");
    
    let data = this.removerNaoNumericos(valor[0]);
    let dia = data.substr(0, 2);
    let mes = data.substr(2, 2);
    let ano = data.substr(4, 4);
    let anomesdia = `${ano}${mes}${dia}`;
  
    return this.formatarData(Number(anomesdia)) + " " + valor[1];
  }

  dataParaInput(anomesdia):string {
    //Formata anomesdia para input. Ex: 20211231 --> 2021-12-31
    if (anomesdia==null) return null;
    if (anomesdia.toString().includes("-")) return anomesdia;

    let data = anomesdia.toString();
    let ano = data.substr(0, 4);
    let mes = data.substr(4, 2);
    let dia = data.substr(6, 2);
    let dataSaida = `${ano}-${mes}-${dia}`;
    return dataSaida;
  }

  anoMesParaMesAnoFormatado(anomes){
    //Formata anomes para mes/ano. Ex: 202112 --> 12/2021
    if (anomes==null) return null;

    let anomesEntrada = anomes.toString();
    let ano = anomesEntrada.substr(0,4);
    let mes = anomesEntrada.substr(4,2);
    let mesano = `${mes}/${ano}`;
    return mesano;
  }
  
  anoMesDiaParaDiaMesAnoFormatado(anomesdia){
    //Formata anomesdia para dia/mes/ano. Ex: 20211231 --> 31/12/2021
    if (anomesdia==null) return null;

    let anomesEntrada = anomesdia.toString();
    let ano = anomesEntrada.substr(0,4);
    let mes = anomesEntrada.substr(4,2);
    let dia = anomesEntrada.substr(6,2);
    let mesano = `${dia}/${mes}/${ano}`;
    return mesano;
  }

  removerNaoNumericos(data) {
    if (data==null) return null;
    
    return data.toString().split("-").join("").split("/").join("");
  }

  anoMesParaAnoNomeMes(anomes){
    //Formata anomes para nomeMes/ano. Ex: 202112 --> Dezembro/2021
    return this.getNomeMesDeAnoMes(anomes) + "/" + this.getAnoDeAnoMes(anomes);  
  }

  getNomeMesDeAnoMes(anomes){
    //Separa e retorna o nome do mes de anomes. Ex: 202112 --> Dezembro  
    let anomesNovo = anomes.toString();
    if (anomesNovo.toString().includes("-")){
      return meses[Number(anomesNovo.split("-")[1])-1]; 
    }
    return meses[Number(anomes.toString().substr(4, 2))-1];
  }
  
  getAnoDeAnoMes(anomes){
    //Separa e retorna o ano de anomes. Ex: 202112 --> 2021  
    return anomes.toString().substr(0, 4);
  }

  getAnoDeMesAno(mesano){
    //Separa e retorna o ano de mesano. Ex: 12/2021 --> 2021  
    return mesano.toString().substr(3, 4);
  }

  obterAnoMesAtualParaPesquisa() {
    let data = new Date();
    return this.dateParaStringAnoMesPesquisa(data);
  }

  obterMesAnoAtualParaInput() {
    let data = new Date();
    return this.dateParaStringMesAnoInput(data);
  }

  mesAnoInputParaAnoMesPesquisa(mesano){
      //Formata mes/ano para anomes. Ex: 12/2021 --> 202112
      if (mesano==null) return null;
  
      let anomesEntrada = this.removerNaoNumericos(mesano);
      let mes = anomesEntrada.substr(0,2);
      let ano = anomesEntrada.substr(2,4);
      let anomes = `${ano}${mes}`;
      return anomes;
  }

  ObterDataAtualParaPesquisa(){
    var today = new Date();
    return this.dateParaStringPesquisa(today);
  }

  obterDataAtualParaInput(){
    var today = new Date();
    return this.dateParaStringInput(today);
  }

  obterPrimeiroDiaMes(data){
    return data.slice(0, -2) + "01";
  }

  obterUltimoDiaMes(ano, mes){
    return new Date(ano, Number(mes) + 2, 0).getDate();
  }

  mesAnoInputParaDataIntevaloPesquisa(mesano){
    //Formata mes/ano para intervalo data. Ex: 12/2021 --> { dataInicio: 20211201, dataFim: 20211231 }
    let mes = mesano.split("/")[0];
    let ano = mesano.split("/")[1];
    let dia = this.obterUltimoDiaMes(ano, mes);
    return {
        "dataInicio": ano + mes + "01",
        "dataFim": ano + mes + dia
    }
  }

  retrocederMesesMesAno(meses, mesano){
    //Subtrai n meses do formato mesano. Ex: 122021 --> 102021 (n=2)
    let data = this.removerNaoNumericos(mesano).toString();
    let ano = data.substr(2, 4);
    let mes = data.substr(0, 2);
    let dataSaida = new Date(ano, Number(mes) - meses -1);
    let anomesSaida = this.dateParaStringAnoMesPesquisa(dataSaida)
    if (mesano.includes("/")){
      return this.anoMesParaMesAnoFormatado(anomesSaida);
    };
    return anomesSaida;
  }

  retrocederDiasData(dias: number, anomesdia){
    //Subtrai n dias do formato anomesdia. Ex: 20211231 --> 20211029 (n=2)
    let data = this.removerNaoNumericos(anomesdia).toString();
    let ano = data.substr(0, 4);
    let mes = data.substr(4, 2);
    let dia = data.substr(6, 2);
    let dataSaida = new Date(ano, Number(mes)-1, Number(dia) - dias);
    let anomesdiaSaida = this.dateParaStringPesquisa(dataSaida)
    if (anomesdia.includes("-")){
      return this.dataParaInput(anomesdiaSaida);
    };
    return anomesdiaSaida;
  }

  retrocederMesesData(meses, anomesdia){
    //Subtrai n meses do formato anomesdia. Ex: 20211201 --> 20211001 (n=2)
    let data = this.removerNaoNumericos(anomesdia).toString();
    let ano = data.substr(0, 4);
    let mes = data.substr(4, 2);
    let dia = data.substr(6, 2);
    let dataSaida = new Date(ano, Number(mes) - meses -1, dia);
    let anomesdiaSaida = this.dateParaStringPesquisa(dataSaida)
    if (anomesdia.includes("-")){
      return this.dataParaInput(anomesdiaSaida); 
    }
    return anomesdiaSaida;
  }

  dateParaStringMesAnoInput(data: Date){
    var mm = String(data.getMonth() + 1).padStart(2, '0');
    var yyyy = data.getFullYear();
  
    return mm + '/' + yyyy;
  }

  dateParaStringAnoMesPesquisa(data: Date){
    var mm = String(data.getMonth() + 1).padStart(2, '0');
    var yyyy = data.getFullYear();
  
    return yyyy + mm;
  }

  dateParaStringInput(data: Date){
    var dd = String(data.getDate()).padStart(2, '0');
    var mm = String(data.getMonth() + 1).padStart(2, '0');
    var yyyy = data.getFullYear();
  
    return yyyy + '-' + mm + '-' + dd;
  }

  dateParaStringPesquisa(data: Date){
    var dd = String(data.getDate()).padStart(2, '0');
    var mm = String(data.getMonth() + 1).padStart(2, '0');
    var yyyy = data.getFullYear();
  
    return yyyy + mm + dd;
  }

  segundosParaData(segundos: number){
    var data = new Date(1970, 0, 1);
    data.setSeconds(segundos);
    return data;
  }

}
