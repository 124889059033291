import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelaBasicaComponent } from './tela-basica/tela-basica.component';
import { TelaFuncionalidadeComponent } from './tela-funcionalidade/tela-funcionalidade.component';
import { TelaPrincipalComponent } from './tela-principal/tela-principal.component';
import { TelaProcessoComponent } from './tela-processo/tela-processo.component';
import { JanelaFlutuanteComponent } from './janela-flutuante/janela-flutuante.component';
import { JanelaFlutuanteLateralComponent } from './janela-flutuante-lateral/janela-flutuante-lateral.component';
import { ElementosVisuaisGlobaisModule } from '../elementos-visuais-globais/elementos-visuais-globais.module';
import { DialogosModule } from '../dialogos/dialogos.module';
import { RouterModule } from '@angular/router';
import { TelaPesquisaComponent } from './tela-pesquisa/tela-pesquisa.component';

@NgModule({
  declarations: [
    TelaBasicaComponent,
    TelaFuncionalidadeComponent,
    TelaPrincipalComponent,
    TelaProcessoComponent,
    TelaPesquisaComponent,
    JanelaFlutuanteComponent,
    JanelaFlutuanteLateralComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ElementosVisuaisGlobaisModule,
    DialogosModule,
    
  ],
  exports: [
    TelaBasicaComponent,
    TelaFuncionalidadeComponent,
    TelaPrincipalComponent,
    TelaProcessoComponent,
    TelaPesquisaComponent,
    JanelaFlutuanteComponent,
    JanelaFlutuanteLateralComponent
  ]
})
export class TemplatesTelaModule { }
