import { Component, OnInit } from '@angular/core';
import { DescricaoSistemaService } from 'src/app/global/servicos/descricao-sistema.service';

@Component({
  selector: 'prv-cabecalho-sistema',
  templateUrl: './cabecalho-sistema.component.html',
  styleUrls: ['./cabecalho-sistema.component.scss']
})
export class CabecalhoSistemaComponent implements OnInit {

  constructor(private descricaoModulo:DescricaoSistemaService) { }

  ngOnInit() {
    
  }

}
