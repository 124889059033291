import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComponentePesquisa } from '../../templates-tela/componente-pesquisa';
import { HashMapParametros } from 'src/app/global/tipos/tiposGenericos';
import { ParametrosUrlService } from '../parametros-url.service';

@Component({
  selector: 'prv-pesquisa-generica',
  templateUrl: './pesquisa-generica.component.html',
  styleUrls: ['./pesquisa-generica.component.scss']
})
export class PesquisaGenericaComponent implements OnInit, ComponentePesquisa {
  
  @Input() valorInicial: any = "";
  @Input() tipoInput: string = "text";

  @Output() pesquisar = new EventEmitter<any>();

  pesquisaGenericaControle: FormControl;

  constructor(private parametrosUrlService: ParametrosUrlService) { }

  ngOnInit() {
    let params = this.parametrosUrlService.getPesquisa();
    if (params && params.nomePesquisa=="GENERICA"){
      this.valorInicial=params.filtros.generica.listaValores[0];
    }
    this.pesquisaGenericaControle = new FormControl(this.valorInicial);
  }

  limparFiltros() {
    this.pesquisaGenericaControle.reset();
  }
  
  montarPesquisa() {
    let filtros: HashMapParametros = {
      generica: {
        tipoDado: this.tipoInput,
        listaValores: [this.pesquisaGenericaControle.value]
      }
    }
    let pesquisa = {
      nomePesquisa: "GENERICA",
      filtros
    }
    return pesquisa;
  }
}
