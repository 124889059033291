import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-quadro-parametro-resultado',
  templateUrl: './quadro-parametro-resultado.component.html',
  styleUrls: ['./quadro-parametro-resultado.component.scss']
})
export class QuadroParametroResultadoComponent implements OnInit {

  @Input() possuiAgrupamento:boolean=true;
  @Input() possuiOrdenacao:boolean=true;

  constructor() { }

  ngOnInit() {
  }

  definirQuadro(){
    if (this.possuiAgrupamento || this.possuiOrdenacao){
      return ["quadro_completo"]
    }else{
      return ["quadro_filtros"]
    }
  }

}
