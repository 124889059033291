import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-complementar-item',
  templateUrl: './complementar-item.component.html',
  styleUrls: ['./complementar-item.component.scss']
})
export class ComplementarItemComponent implements OnInit {

  @Input() visivel:boolean=false;

  @Input() icone:string = "fa fa-puzzle-piece";

  @Input() cor:string = "";//rgb(152, 236, 127)";

  @Input() tipoComplemento: "ALTERACAO" | "FINALIZACAO" = "ALTERACAO";


  constructor() { }

  ngOnInit() {
  }

  getIcone() {
    return this.icone;
  }

  getColor() {
    if (this.cor != "") return {
      "background-color": this.cor
    };

    if (this.tipoComplemento=='FINALIZACAO') {
      return {
        "background-color": "rgb(152, 236, 127)"
      };
    } else {
      return {
        "background-color": "rgb(126, 218, 236)"
      };
    }

  }

  //"#77ccd9"

  fecharJanela() {
    this.visivel = false;
  }

}
