import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { ParametrosUrlService } from '../parametros-url.service';
import { QuadroSalvarComponent } from '../../elementos-visuais-globais/componentes/layout/quadro-salvar/quadro-salvar.component';
import { ComandosListaComponent } from '../../utilitarios/componentes/comandos-lista/comandos-lista.component';


@Component({
  selector: 'prv-comandos-pesquisa',
  templateUrl: './comandos-pesquisa.component.html',
  styleUrls: ['./comandos-pesquisa.component.scss']
})
export class ComandosPesquisaComponent extends ComandosListaComponent implements OnInit {

  @ViewChild('mensagem', { static: false }) mensagem: ElementRef;
  @ViewChild("formulario", { static: false }) formulario: QuadroSalvarComponent;

  @Input() imagem: HTMLDivElement;
  @Input() exportarAtivo: boolean;
  @Input() exibindoResultado: boolean = false;
  @Output() exportar = new EventEmitter();
  @Output() atualizar = new EventEmitter<boolean>(); 

  comando: Function;

  constructor(protected parametrosUrl: ParametrosUrlService,
    protected renderer: Renderer2) {
      super(parametrosUrl, renderer);
     }
    

  ngOnInit() {
  }

}
