import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GerenciadorTokenService } from '../servicos/gerenciador-token.service';

@Injectable({
  providedIn: 'root'
})
export class GuardaUsuarioNaoAutenticadoService implements CanActivate {

  constructor(
    private router:Router,
    private gerenciadorTokenService:GerenciadorTokenService) { }

  canActivate(
      route: import("@angular/router").ActivatedRouteSnapshot, 
      state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    console.log('## Verifica se Usuário NÃO está autenticado.');

    let acessoAutorizado = this.gerenciadorTokenService.possuiToken();
    if (acessoAutorizado) {
      this.router.navigate(['/principal']);
    }
    return !acessoAutorizado;
  }

}
